import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponse } from './login/LoginResponse';
import { MfaTypes } from './enums/dbo.MfaType';
import { Permissions } from './enums/dbo.Permission';
import { Roles } from './roles';


@Injectable({
  providedIn: 'root'
})
export class AuthenticatedUserService {
  isSetPasswordMenuDisplay$ = new BehaviorSubject<boolean>(true);

  constructor(private httpClient: HttpClient) { }

  async login(username: string, password: string) {
    return await firstValueFrom(
      this.httpClient.post<LoginResponse>(
        'api/session',
        { username, password },
        { headers: { Accept: 'application/json' } }
      ));
  }

  async loginWithMfa(username: string, password: string, oneTimePassword: string) {
    return await firstValueFrom(
      this.httpClient.post<LoginResponse>(
        'api/session',
        { username, password, oneTimePassword },
        { headers: { Accept: 'application/json' } }
      ));
  }

  async createTemporaryMfa(username: string, password: string, temporaryMfaType: MfaTypes) {
    return await firstValueFrom(
      this.httpClient.post<LoginResponse>(
        'api/session',
        { username, password, temporaryMfaType },
        { headers: { Accept: 'application/json' } }
      ));
  }

  // eslint-disable-next-line max-params
  async verifyMfaSelection(username: string, password: string, temporaryMfaType: MfaTypes, oneTimePassword: string, temporaryOneTimePassword: string, temporaryAuthenticatorSecretKey?: string) {
    return await firstValueFrom(
      this.httpClient.post<LoginResponse>(
        'api/session',
        { username, password, temporaryMfaType, oneTimePassword, temporaryOneTimePassword, temporaryAuthenticatorSecretKey },
        { headers: { Accept: 'application/json' } }
      ));
  }

  async authenticate(data: Session) {
    this.userId = data.userId;
    this.ownerId = data.ownerId;
    this.username = data.username;
    this.role = data.role;

    if (this.isAuthenticated) await this.getPermissionIds();

    this.nextAuthenticated();
  }

  username: string | null;
  userId: number | null;
  ownerId: number | null;
  permissionIds: Permissions[] | null = null;
  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  private nextAuthenticated() {
    this.isAuthenticated$.next(this.isAuthenticated);
  }

  async refresh() {
    const data = await firstValueFrom(this.httpClient.get<Session | null>('api/session'));

    if (data) {
      this.userId = data.userId;
      this.ownerId = data.ownerId;
      this.username = data.username;
      this.role = data.role;

      await this.getPermissionIds();
    }
    else {
      this.setAnonymous();
    }

    this.nextAuthenticated();
  }

  role = Roles.None;

  private setAnonymous() {
    this.userId = null;
    this.ownerId = null;
    this.username = null;
    this.role = Roles.None;

    this.permissionIds = null;
  }

  get isAuthenticated(): boolean {
    return this.role !== Roles.None;
  }

  async logOut() {
    this.username = null;

    await firstValueFrom(this.httpClient.delete('api/session'));

    this.role = Roles.None;

    this.nextAuthenticated();
  }

  get isAdviser(): boolean {
    return this.role === Roles.Adviser;
  }

  get isClient(): boolean {
    return this.role === Roles.Client;
  }

  get isMidwinter(): boolean {
    return this.username === 'midwinter';
  }

  async getPermissionIds(): Promise<void> {
    if(this.isClient) {
      //Permissions are not supported/implemented for clients.
      this.permissionIds = [];

      return;
    }

    this.permissionIds = await firstValueFrom(this.httpClient.get<Permissions[]>('/api/permissionIds'));
  }

  hasOwnerPermission(permissionId: Permissions): boolean {
    if (!this.permissionIds?.length) return false;

    return this.permissionIds.includes(permissionId);
  }

  get isSetPasswordMenuDisplay() {
    return this.isSetPasswordMenuDisplay$.value;
  }

  set isSetPasswordMenuDisplay(value: boolean) {
    this.isSetPasswordMenuDisplay$.next(value);
  }
}

interface Session {
  username: string;
  userId: number;
  ownerId: number;
  role: Roles;
}
