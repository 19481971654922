import { CommonModule } from '@angular/common';
import { MwToastComponent } from './mw-toast.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    MwToastComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MwToastComponent
  ],
})
export class MwToastModule { }
