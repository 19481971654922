import { ActivatedRoute, Router } from "@angular/router";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { Form } from './Form';
import { IdleUserService } from './../idle-user.service';
import { LoginResponse } from './LoginResponse';
import { LoginResult } from './LoginResult';
import { MfaTypes } from '../enums/dbo.MfaType';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


// eslint-disable-next-line @angular-eslint/prefer-standalone, @angular-eslint/prefer-standalone-component
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  // eslint-disable-next-line max-params
  constructor(
    public authenticatedUserService: AuthenticatedUserService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private idleUserService: IdleUserService
  ) {}

  @ViewChild('loginForm') loginForm: NgForm;
  error = '';
  sessionTimedOut = false;

  async ngOnInit(): Promise<void> {
    this.activeForm = Form.LoginForm;
    const isTimedOut = this.route.snapshot.queryParamMap.get('timedOut');
    if (isTimedOut !== null && isTimedOut === "true") this.sessionTimedOut = true;

    if (!this.authenticatedUserService.isAuthenticated) return;
    
    //The isAuthenticated property above is cached on the client.
    //If the server returned an Unauthrorized response to an Ajax request (because the authentication has expired) then this would not be reliable.
    //Force the AuthenticatedUserService to refresh itself to account for that.
    await this.authenticatedUserService.refresh();

    //Use a type assertion to indicate that the value might have changed since the previous call to isAuthenticated due to side effects in refresh.
    //https://github.com/microsoft/TypeScript/issues/9998
    //Otherwise ESLint reports a violation of no-unnecessary-condition in the condition below.
    const isAuthenticated = this.authenticatedUserService.isAuthenticated as boolean;

    if (!isAuthenticated) return;

    //Log the user out if they have navigated to this page but are still authenticated.
    await this.authenticatedUserService.logOut();
  }

  MfaTypes = MfaTypes;
  selectedMfaType: MfaTypes;
  Form = Form;
  activeForm: Form;

  oneTimePassword: string;
  mfaMedium: string;
  temporaryOneTimePasswordFromServer: string;
  label: string;
  mfaSelectionError: string;

  userName: string;
  password: string;
  showHeader: false;
  isIncorrectCredentials = false;
  isAccountLocked = false;
  isIncorrectOTP = false;
  isMfaLockout = false;
  isMfaVerificationFailed = false;

  showPassword = false;
  togglePWvisibility() {
    this.showPassword = !this.showPassword;
  }

  async logIn() {
    this.isIncorrectCredentials = false;
    this.isAccountLocked = false;

    if (this.loginForm.invalid) {

      Object.keys(this.loginForm.controls).forEach(key => {
        this.loginForm.controls[key].markAsTouched();
      });
      
      return;
    }

    const data = await this.authenticatedUserService.login(this.userName, this.password);

    switch (data.loginResult) {
      case LoginResult.LoginSuccess:
        await this.login(data);
        break;
      case LoginResult.AccountLockout:
        this.isAccountLocked = true;
        this.changeDetectorRef.detectChanges();
        break;
      case LoginResult.IncorrectCredentials:
        this.isIncorrectCredentials = true;
        this.changeDetectorRef.detectChanges();
        break;
      case LoginResult.RegisterMfa:
        this.activeForm = Form.MfaRegistryFormThreeOptions;
        this.changeDetectorRef.detectChanges();
        break;
      case LoginResult.OneTimePasswordSent:
        this.activeForm = Form.MfaLoginForm;
        this.mfaMedium = data.mfaResponse.label;
        this.changeDetectorRef.detectChanges();
        break;
      default:
        break;  
    }
  }

  async chooseMfaOption(selectedMfa: MfaTypes) {
    await this.spinner.show();
    
    const data = await this.authenticatedUserService.createTemporaryMfa(this.userName, this.password, selectedMfa);

    this.temporaryOneTimePasswordFromServer = data.mfaResponse.oneTimePassword;
    this.label = data.mfaResponse.label;
    this.mfaSelectionError = data.mfaResponse.error;

    this.selectedMfaType = selectedMfa;
    this.activeForm = Form.MfaRegistryOptionSelected;
    this.changeDetectorRef.detectChanges();

    await this.spinner.hide();
  }

  async verifyMfaSelection() {
    this.isMfaVerificationFailed = false;
    const data = await this.authenticatedUserService.verifyMfaSelection(this.userName, this.password, this.selectedMfaType, this.oneTimePassword, this.temporaryOneTimePasswordFromServer);

    switch (data.loginResult) {
      case LoginResult.LoginSuccess:
        await this.login(data);
        break;

      case LoginResult.MfaLoginFailure:
        this.isMfaVerificationFailed = true;
        this.changeDetectorRef.detectChanges();
        break;

      default:
        break;
    }
  }

  async login(data: LoginResponse) {
    await this.authenticatedUserService.authenticate({ ownerId: data.ownerId, role: data.role, userId: data.userId, username: this.userName });
    void this.idleUserService.setRecurredRemainingSessionDurationFollower();
    await this.router.navigate(['/']);
  }

  async loginWithMfa() {
    this.isIncorrectOTP = false;
    this.isMfaLockout = false;

    const data = await this.authenticatedUserService.loginWithMfa(this.userName, this.password, this.oneTimePassword);

    switch (data.loginResult) {
      case LoginResult.LoginSuccess:
        await this.login(data);

        break;

      case LoginResult.MfaLoginFailure:
        this.isIncorrectOTP = true;
        this.changeDetectorRef.detectChanges();

        break;

      case LoginResult.MfaLockout:
        this.isMfaLockout = true;
        this.changeDetectorRef.detectChanges();

        break;

      default:
        break;
    }
  }
}
