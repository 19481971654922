<ng-container [ngSwitch]="isReadonly">
  <input *ngSwitchCase="true"
         type="text"
         class="form-input-text"
         [ngModel]="getStringDate()"
         [readonly]="true" />

  <div *ngSwitchDefault>
    <div>
      <label>Day</label>
      <input #dayElement
             type="number"
             [(ngModel)]="day"
             [ngModelOptions]="{ updateOn: 'blur' }"
             min="1"
             [max]="maximumDay"
             [readonly]="isReadonly" />
    </div>
    <div>
      <label>Month</label>
      <input #monthElement type="number" [(ngModel)]="month" [ngModelOptions]="{ updateOn: 'blur' }" [min]="1" [max]="12" [readonly]="isReadonly" />
    </div>
    <div class="year-container">
      <label>Year</label>
      <input #yearElement type="number" [(ngModel)]="year" [ngModelOptions]="{ updateOn: 'blur' }" min="1" [readonly]="isReadonly" />
    </div>
    <div *ngIf="!shouldDisablePicker" class="picker">
      <svg (click)="calenderOnClick()"
           width="35"
           height="35"
           viewBox="0 0 35 35"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8998 6.30005H24.1498M7.3498 6.30005H6.7998C4.59066 6.30005 2.7998 8.09091 2.7998 10.3V28.2001C2.7998 30.4092 4.59066 32.2001 6.7998 32.2001H28.1998C30.4089 32.2001 32.1998 30.4092 32.1998 28.2001V10.3C32.1998 8.09091 30.3839 6.30005 28.1748 6.30005V6.30005"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M3.1499 14H31.8499" stroke-width="1.5" />
        <rect x="7.7002" y="2.80005" width="3.5" height="6.3" rx="1" stroke-width="1.5" />
        <rect x="24.5" y="2.80005" width="3.5" height="6.3" rx="1" stroke-width="1.5" />
        <rect x="8.3999" y="25.2" width="2.8" height="2.8" rx="1" stroke-width="1.5" />
        <rect x="16.1001" y="25.2" width="2.8" height="2.8" rx="1" stroke-width="1.5" />
        <rect x="23.7998" y="25.2" width="2.8" height="2.8" rx="1" stroke-width="1.5" />
        <rect x="16.1001" y="18.2" width="2.8" height="2.8" rx="1" stroke-width="1.5" />
        <rect x="23.8003" y="18.2" width="2.8" height="2.8" rx="1" stroke-width="1.5" />
      </svg>
      <input #dateInput type="date" [valueAsDate]="value" (change)="setDateFromEvent($event)" />
    </div>
  </div>
</ng-container>
