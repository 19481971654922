<label for="name"></label>
<input id="name" type="text" #name />
<br />
<sub>
  <i>(leave blank to adopt the name from the file that you are uploading.)</i>
</sub>
<br />
<br />
<input type="file" (change)="upload($event, name.value); name.value = ''" [disabled]="isReadonly"/>
<ul>
  <li *ngFor="let file of value">
    <input
      #fileName="ngModel"
      type="text"
      [ngModel]="file.name"
      (ngModelChange)="setFileName($event, file, fileName.control)"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [readonly]="isReadonly"
    />
    <button (click)="downloadFile(file)" [disabled]="isReadonly">Download</button>
    <button (click)="remove(file)" style="background-color: red" [disabled]="isReadonly">X</button>
    <a *ngIf="getDownloadUrl(file)" [href]="getDownloadUrl(file)" [download]="file.name" target="_blank">Download</a>
  </li>
</ul>
