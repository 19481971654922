<div class="top">
  <h3 class="caption group-label">
    <span *ngIf="group.label">{{ group.label }}</span>
  </h3>

  <div
    class="kebabmenu kendo-pdf-ignore"
    [class.clicked]="menuType === menuTypes.show"
    [class.cross]="menuType === menuTypes.cross"
  >
    <svg
      width="4"
      height="20"
      viewBox="0 0 4 20"
      xmlns="http://www.w3.org/2000/svg"
      (click)="menuOnClick()"
      *ngIf="menuType === menuTypes.hide"
    >
      <circle cx="2" cy="2" r="2" />
      <circle cx="2" cy="10" r="2" />
      <circle cx="2" cy="18" r="2" />
    </svg>

    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 34 35"
      style="enable-background: new 0 0 34 35"
      xml:space="preserve"
      *ngIf="menuType !== menuTypes.hide"
      (click)="menuOnClick()"
    >
      <g>
        <path class="st0" d="M9.7,10.1l13.9,14.6" />
        <path class="st0" d="M9.7,24.7l13.9-14.6" />
      </g>
    </svg>

    <div class="items" *ngIf="menuType === menuTypes.show">
      <div class="info" (click)="showInfo()" *ngIf="group.info">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 18 18"
            xml:space="preserve"
          >
            <path
              d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M9.9,14c-0.2,0.2-0.5,0.3-0.8,0.3S8.5,14.2,8.3,14
                            C8.1,13.8,8,13.6,8,13.3c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7
                            C10.2,13.6,10.1,13.8,9.9,14z M12.2,7.4c-0.2,0.3-0.4,0.7-0.7,0.9C11.3,8.7,11,9,10.7,9.3c-0.3,0.2-0.5,0.5-0.6,0.8s-0.1,0.6-0.2,1
                            H8.2c0-0.4,0-0.8,0.1-1.1C8.4,9.6,8.5,9.3,8.7,9C8.8,8.8,9,8.5,9.3,8.3C9.6,8,9.8,7.9,9.9,7.7c0.2-0.2,0.3-0.4,0.4-0.6
                            c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.4-0.5C9.8,5.1,9.5,5,9.3,5C9,5,8.8,5,8.6,5.1S8.3,5.4,8.1,5.6
                            C8,5.8,8,6,7.9,6.3H6c0-0.6,0.2-1.2,0.5-1.6c0.3-0.4,0.7-0.7,1.2-1c0.5-0.2,1-0.3,1.6-0.3c0.7,0,1.2,0.1,1.7,0.3
                            c0.5,0.2,0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5C12.5,6.7,12.4,7.1,12.2,7.4z"
            />
          </svg>
        </div>
        <div>Help info</div>
      </div>
      <div class="note" (click)="showNote()">
        <div>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_919_6876)">
              <path
                d="M13.1194 7.21147L7.46219 14.252C7.2464 14.5205 6.9649 14.7288 6.64497 14.8566L3.21884 16.2247C2.49599 16.5134 1.73506 15.9017 1.86139 15.1336L2.46017 11.4927C2.51608 11.1527 2.65886 10.8329 2.87465 10.5644L8.5319 3.52386M13.1194 7.21147L8.5319 3.52386M13.1194 7.21147L13.6637 6.53418L13.9972 6.11913C15.0152 4.85213 14.8136 2.99952 13.5468 1.98122V1.98122C12.28 0.962911 10.4277 1.16452 9.40963 2.43152L9.07612 2.84657L8.5319 3.52386"
                stroke-width="1.5"
              />
              <path d="M10.5498 16.5932L15.3756 16.6205" stroke-width="1.5" stroke-linecap="round" />
            </g>
            <defs>
              <clipPath id="clip0_919_6876">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>Note</div>
      </div>
      <div class="chat" (click)="showChat()">
        <div>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 5.40005C1 3.41183 2.61178 1.80005 4.6 1.80005H13.4C15.3882 1.80005 17 3.41182 17 5.40005V9.97016C17 11.9584 15.3882 13.5702 13.4 13.5702H8.80952C8.80952 13.5702 8.80952 13.5702 8.80952 13.5702C8.80952 13.5702 7.47619 13.5702 6.52381 14.6736C6.01759 15.2601 5.56519 16.1064 5.25241 16.7706C5.10331 17.0873 4.61034 17.0553 4.51991 16.7172L3.72755 13.7546C3.69845 13.6458 3.59988 13.5702 3.48727 13.5702V13.5702C2.11359 13.5702 1 12.4566 1 11.0829V5.40005Z"
              stroke-width="1.5"
            />
            <path d="M5.04004 6H12.96" stroke-width="1.5" stroke-linecap="round" />
            <path d="M5.04004 9.43994H8.28004" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </div>
        <div>Chat</div>
      </div>
    </div>
  </div>
  <i class="fa fa-comments" style="color: red" *ngIf="doesGroupHaveUnreadChats(group)"></i>
</div>

<div class="bottom" [ngSwitch]="itemType">
  <div class="info" *ngSwitchCase="itemTypes.info">
    {{ group.info }}
  </div>

  <div class="note" *ngSwitchCase="itemTypes.note">
    <div class="label">Note</div>
    <textarea
      (ngModelChange)="noteService.set($event, group)"
      [ngModel]="noteService.get(group)"
      [ngModelOptions]="{ updateOn: 'blur' }"
    ></textarea>
    <div class="label">Maximum 150 characters</div>
  </div>

  <div class="chat" *ngSwitchCase="itemTypes.chat">
    <ng-container *ngFor="let chat of chatService.chats">
      <div class="user" *ngIf="userId === chat.userId">
        {{ chat.value }}
        <div class="arrow"></div>
      </div>
      <div class="other" *ngIf="userId !== chat.userId">
        {{ chat.value }}
        <div class="arrow"></div>
      </div>
      <div class="clear"></div>
    </ng-container>

    <div class="label" *ngIf="!chatService.chats || chatService.chats.length === 0">Ask a question</div>
    <div class="input">
      <div>
        <input #newChat type="text" />
      </div>
      <div>
        <svg
          (click)="addChat(newChat.value, group); newChat.value = ''"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.15192 9.32455L5.30771 9L5.15192 8.67545L1.61604 1.30904C1.51296 1.09428 1.74016 0.870714 1.95323 0.97725L17.5515 8.77639C17.7358 8.86852 17.7358 9.13148 17.5515 9.22361L1.95323 17.0227C1.74016 17.1293 1.51296 16.9057 1.61605 16.691L5.15192 9.32455Z"
            fill="#FFFEFE"
            stroke="#1A315A"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
    <div class="label">Maximum 150 characters</div>
  </div>
</div>
