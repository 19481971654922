<div class="toast-box">
  <div
    class="toast"
    *ngFor="let toast of toasts"
    [ngClass]="toast.type"
    [ngStyle]="{ '--toast-time-out': TOAST_TIME_OUT + 'ms' }"
  >
    <div class="close" (click)="removeToast(toast)">
      <a>
        <i class="fa-solid fa-xmark"></i>
      </a>
    </div>
    <div class="toast-content">
      {{ toast.message }}
    </div>
  </div>
</div>

