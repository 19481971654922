import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mw-prompt',
  templateUrl: './mw-prompt.component.html',
  styleUrl: './mw-prompt.component.scss'
})
export class MwPromptComponent {
  @Input() data: {
    text: string
  };
}
