<table class="mw-table">
  <thead>
    <tr>
      <th *ngFor="let column of columns">
        <div [ngClass]="{'mw-table-message': column.icon, '': !column.icon}">
          <div *ngIf="column.icon" title="Messages Icon Inactive" alt="Messages Icon Inactive" class="messages-icon-inactive mw-table__icon">
          </div>
          {{ column.title }}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data" (click)="selectRow(item.id)" [class.selected]="item.id === selectedRowIndex">
      <td *ngFor="let column of columns" [ngStyle]="getStyle(column)">
        {{ getDisplayValue(item, column) }}
      </td>
    </tr>
  </tbody>
</table>