import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss'
})
export class InfoComponent {
  @Input()
  info: string;

  isDisplay = false;

  constructor(private eRef: ElementRef) {}

  onClick = () => {
    this.isDisplay = !this.isDisplay;
  };

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!(this.eRef.nativeElement as HTMLElement).contains(event.target as HTMLElement)) {
      this.isDisplay = false;
    }
  }
}
