<div class="account" [class.clicked]="isAccountMenuItemShowed" *ngIf="authenticatedUserService.isAuthenticated">
  <div (click)="isAccountMenuItemShowed = !isAccountMenuItemShowed">
    <i class="{{ !isAccountMenuItemShowed ? '' : 'fa-solid fa-xmark' }}"></i>
    <div class="{{ !isAccountMenuItemShowed ? 'ff-user' : '' }}"></div>
  </div>

  <div class="items" *ngIf="isAccountMenuItemShowed">
    <div *ngIf="authenticatedUserService.isSetPasswordMenuDisplay" (click)="passwordOnClick()">
      <div>
        <i class="fa-solid fa-lock"></i>
      </div>
      <div>Set password</div>
    </div>
    <div (click)="generatePDF()" *ngIf="showGeneratePdf()">
      <div>
        <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M46.0002 46H4.00024V18.5248H18.7339V3.99995H46.0002V46Z"
                stroke="#1A315A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M18.7339 3.99995L4.00024 18.5"
                stroke="#1A315A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M15.5275 34.353H12.4957V32.8695H15.5275C16.0233 32.8695 16.4249 32.8008 16.7321 32.6635C17.0447 32.5215 17.2738 32.3292 17.4193 32.0865C17.5648 31.8393 17.6376 31.5577 17.6376 31.2418C17.6376 30.935 17.5648 30.6488 17.4193 30.3832C17.2738 30.1177 17.0447 29.9025 16.7321 29.7376C16.4249 29.5728 16.0233 29.4904 15.5275 29.4904H13.2233V38H11V28H15.5275C16.4437 28 17.2253 28.1397 17.872 28.419C18.5242 28.6937 19.0201 29.076 19.3596 29.5659C19.7046 30.0513 19.8771 30.6053 19.8771 31.228C19.8771 31.8736 19.7046 32.4299 19.3596 32.897C19.0201 33.364 18.5242 33.7234 17.872 33.9753C17.2253 34.2271 16.4437 34.353 15.5275 34.353Z"
                fill="#1A315A" />
          <path d="M25.1402 38H22.6016L22.6178 36.5165H25.1402C25.8247 36.5165 26.3988 36.3883 26.8623 36.1319C27.3258 35.8709 27.6761 35.4977 27.9133 35.0124C28.1505 34.5224 28.269 33.9364 28.269 33.2541V32.739C28.269 32.2125 28.2017 31.7477 28.0669 31.3448C27.9322 30.9418 27.7327 30.603 27.4686 30.3283C27.2099 30.0536 26.8892 29.8452 26.5066 29.7033C26.1239 29.5614 25.6846 29.4904 25.1887 29.4904H22.5531V28H25.1887C25.9757 28 26.6925 28.1122 27.3393 28.3365C27.9915 28.5609 28.5547 28.8837 29.029 29.3049C29.5087 29.7216 29.8752 30.2207 30.1285 30.8022C30.3872 31.3837 30.5166 32.0339 30.5166 32.7527V33.2541C30.5166 33.9684 30.3872 34.6186 30.1285 35.2047C29.8752 35.7862 29.5087 36.2853 29.029 36.7019C28.5547 37.1186 27.9888 37.4391 27.3312 37.6635C26.6736 37.8878 25.9433 38 25.1402 38ZM23.8467 28V38H21.6234V28H23.8467Z"
                fill="#1A315A" />
          <path d="M34.6317 28V38H32.4084V28H34.6317ZM39.3775 32.3201V33.8104H34.0496V32.3201H39.3775ZM40 28V29.4904H34.0496V28H40Z"
                fill="#1A315A" />
        </svg>
      </div>
      <div>Generate PDF</div>
    </div>
    <div (click)="logoutOnClick()">
      <div>
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </div>
      <div>Log out</div>
    </div>
    <!--<div class="button">
    <a href="https://localhost:7197/aia/logout">Link</a>
  </div>-->
  </div>
</div>
<div class="enterdetailnav" *ngIf="appService.isEnterDetail">
  <i class="fa-solid fa-bars" (click)="enterDetailMenuOnClick()"></i>
</div>
