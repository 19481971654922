import { ErrorHandler, Injectable } from '@angular/core';
import { MwToastService } from './shared/mw-toast/mw-toast.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler extends ErrorHandler {
  constructor(private toastService: MwToastService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override handleError(error: any): void {   
    // eslint-disable-next-line no-console
    console.error(error);

    this.toastService.show({
      type: 'error',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: environment.production ? "An application error has occurred." : error.error ?? error 
    });
  }
}
