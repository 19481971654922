export enum Permissions {
    /** Permissions.TestingFeatures = 1 */
    TestingFeatures = 1,

    /** Permissions.ManageTemplates = 2 */
    ManageTemplates = 2,

    /** Permissions.CreateUser = 3 */
    CreateUser = 3,

    /** Permissions.GenerateAuditReport = 4 */
    GenerateAuditReport = 4,

    /** Permissions.ManageOwners = 5 */
    ManageOwners = 5,

}
