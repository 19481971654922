<p>These are your templates...</p>

<table class="template-table" *ngIf="owners">
  <tr>
    <th style="width: 1rem"></th>
    <th>Name</th>
    <th style="width: 7rem">Date Modified</th>
    <th>Owner</th>
    <th style="width: 4rem">Available</th>
    <th *ngIf="authenticatedUserService.isMidwinter" style="width: 5rem">Default</th>
  </tr>
  <tr *ngFor="let templateItem of templates" [ngClass]="{ selected: template.id === templateItem.id }">
    <td (click)="setTemplate(templateItem.id)">
      <input type="radio" [ngModel]="template.id" [value]="templateItem.id" name="template" style="display: inline" />
    </td>
    <td (click)="setTemplate(templateItem.id)">{{ templateItem.name }}</td>

    <!-- TODO: Add Date modified -->
    <td (click)="setTemplate(templateItem.id)">{{ templateItem.version | date: 'dd/MM/yyyy' }}</td>

    <td (click)="setTemplate(templateItem.id)">{{ getOwnerById(templateItem.ownerId).name }}</td>

    <!-- TODO: Add available -->
    <td>
      <mw-boolean
        [value]="getTemplateStatusBooleanValue(templateItem)"
        (valueChange)="setTemplateStatusBooleanValue(templateItem, $event)"
        [hasLabel]="false"
      ></mw-boolean>
    </td>

    <td *ngIf="authenticatedUserService.isMidwinter">
      <mw-boolean
        *ngIf="templateItem && templateItem.ownerId !== 1"
        [disabled]="!owner || owner.isReadOnly || !isValid"
        [value]="templateItem.isDefault"
        (valueChange)="setTemplateDefault(templateItem, $event)"
        [hasLabel]="false"
        [group]="templateItem.ownerId"
      ></mw-boolean>
    </td>
  </tr>
</table>

<br />
<p *ngIf="template">
  Selected Template: <strong>{{ template.name }}</strong> ({{ template.id }})
</p>
<button class="ff-button" (click)="loadTemplate()" *ngIf="template">Load Template</button>

<h2>Save Template</h2>

<br />
<div>
  <label class="form-input-label">Name</label>
  <input class="form-input-text" type="text" [(ngModel)]="newTemplateName" />
</div>

<br />
<div>
  <label class="form-input-label">Body</label>
  <textarea [(ngModel)]="newTemplateBody"></textarea>
</div>

<br />
<div>
  <label class="form-input-label">Owner</label>
  <div class="ff-select">
    <select [(ngModel)]="owner">
      <option *ngFor="let owner of owners" [ngValue]="owner">{{ owner.name }} ({{ owner.type }})</option>
    </select>
  </div>
</div>
<br />

<button class="ff-button" (click)="saveTemplate()" [disabled]="!owner || owner.isReadOnly || !isValid">
  Create Template
</button>
<br />
<br />
<button
  class="ff-button"
  (click)="saveTemplate(true)"
  *ngIf="template && template.ownerId !== 1"
  [disabled]="!owner || owner.isReadOnly || !isValid"
>
  Update Template ({{ template.name }})
</button>
<br />
<br />

<h2>Templates</h2>
<a href="schemas/Template.V1.schema.jsonc" download>Schema</a>
<br />
<a href="schemas/Midwinter.Template.V1.jsonc" download>Midwinter</a>
