/* eslint-disable max-statements */
/* eslint-disable complexity */
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: `
    [mwValidation],
    input[type=text][required],
    input:not([type])[required],
    input[type=text][minlength],
    input:not([type])[minlength],
    input[type=number][required],
    mw-number,
    mw-date,
    kendo-timepicker,
    select[required],
    mw-checkbox-group,
    textarea[required],
    mw-autocomplete
    `,
  providers: [{ provide: NG_VALIDATORS, useExisting: MwValidationDirective, multi: true }]
})
export class MwValidationDirective implements OnInit, Validator, OnDestroy {
  //https://github.com/angular/angular-cli/issues/2034
  @Input('mwValidation')
  public validator: (value: unknown, control: AbstractControl) => string;

  @Input() valPosition: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
    if (
      !(this.elementRef.nativeElement instanceof HTMLInputElement) &&
      !(this.elementRef.nativeElement instanceof HTMLSelectElement) &&
      !(this.elementRef.nativeElement instanceof HTMLTextAreaElement) &&
      this.elementRef.nativeElement.nodeName !== 'MW-CHECKBOX-GROUP' &&
      this.elementRef.nativeElement.nodeName !== 'KENDO-NUMERICTEXTBOX' &&
      this.elementRef.nativeElement.nodeName !== 'KENDO-TIMEPICKER' &&
      this.elementRef.nativeElement.nodeName !== 'KENDO-COMBOBOX' &&
      this.elementRef.nativeElement.nodeName !== 'MW-NUMBER' &&
      this.elementRef.nativeElement.nodeName !== 'MW-DATE' &&
      this.elementRef.nativeElement.nodeName !== 'MW-AUTOCOMPLETE'
    ) {
      throw Error(`${elementRef.nativeElement.nodeName} elements are not supported by the mwValidation directive.`);
    }

    this.tooltipMessage = this.renderer.createElement('div') as HTMLSpanElement;
  }

  ngOnInit(): void {
    const newParent = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(newParent, 'mw-tooltip');
    this.wrap(this.elementRef.nativeElement, newParent);

    if (this.valPosition) {
      this.renderer.addClass(this.tooltipMessage, `tooltip-${this.valPosition}`);
    } else {
      this.renderer.addClass(this.tooltipMessage, 'tooltip-bottom');
    }

    this.renderer.appendChild(newParent, this.tooltipMessage);
  }

  ngOnDestroy(): void {
    const elem = this.elementRef.nativeElement.parentElement;

    if (elem?.classList.contains('mw-tooltip')) {
      this.renderer.removeChild(elem.parentElement, this.elementRef.nativeElement.parentElement);
    }

    if (!this.statusChangesSubscription) return;

    this.statusChangesSubscription.unsubscribe();
    this.statusChangesSubscription = new Subscription();
  }

  get inputType(): string {
    return (this.elementRef.nativeElement as HTMLInputElement).type;
  }
  get isRequired(): boolean {
    return (this.elementRef.nativeElement as Element).hasAttribute('required');
  }
  get minimum(): number {
    return Number((this.elementRef.nativeElement as HTMLInputElement).min);
  }
  get maximum(): number {
    return Number((this.elementRef.nativeElement as HTMLInputElement).max);
  }
  get minLength(): number {
    return Number((this.elementRef.nativeElement as HTMLInputElement).minLength);
  }
  get maxLength(): number {
    return Number((this.elementRef.nativeElement as HTMLInputElement).maxLength);
  }

  private wrap(el: HTMLElement, wrapper: HTMLElement): void {
    this.renderer.insertBefore(el.parentNode, wrapper, el);
    this.renderer.appendChild(wrapper, el);
  }

  private readonly tooltipMessage: HTMLElement;

  private set message(value: string) {
    if (this.tooltipMessage.childNodes.length) {
      this.tooltipMessage.childNodes.forEach((node) => {
        this.renderer.removeChild(this.tooltipMessage, node);
      });
    }

    let display: string;

    if (value) {
      display = 'block';

      const errorMessageElement = this.renderer.createElement('p') as HTMLParagraphElement;
      this.renderer.addClass(errorMessageElement, 'create-ff-error-message');
      errorMessageElement.style.display = 'flex';

      const warningMessageElement = this.renderer.createElement('span') as HTMLSpanElement;
      this.renderer.addClass(warningMessageElement, 'warning-message');

      const text = this.renderer.createText(value) as unknown;

      this.renderer.appendChild(errorMessageElement, warningMessageElement);
      this.renderer.appendChild(errorMessageElement, text);
      this.renderer.appendChild(this.tooltipMessage, errorMessageElement);

      this.renderer.addClass(this.elementRef.nativeElement, 'invalid-input');
    } else {
      display = 'none';

      if (this.elementRef.nativeElement.classList.contains('invalid-input')) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'invalid-input');
      }
    }

    this.renderer.setStyle(this.tooltipMessage, 'display', display);
  }

  //static getErrorMessageWithName(control: AbstractControl): string {
  //  const name = control.parent ? Object.keys(control.parent.controls).find(x => control.parent.controls[x] === control) + ": " : "";

  //  const message = MwValidationDirective.getErrorMessage(control);

  //  if (!message) return "";

  //  return name + ": " + message;
  //}

  static getErrorMessage(control: AbstractControl): string {
    if (!control.errors) {
      return '';
    }

    if (control.errors['customValidation']) {
      return (control.errors['customValidation'] as { message: string }).message;
    }

    if (control.errors['required']) {
      return 'A value is required.';
    }

    if (control.errors['minlength']) {
      return `At least ${
        (control.errors['minlength'] as { requiredLength: string }).requiredLength
      } characters are required`;
    }

    if (control.errors['maxlength']) {
      return `No more than ${
        (control.errors['maxlength'] as { requiredLength: string }).requiredLength
      } characters are allowed`;
    }

    if (control.errors['maxError']) {
      return `The value ${(
        control.errors['maxError'] as { value: number }
      ).value.toLocaleString()} is greater than the allowed maximum of ${(
        control.errors['maxError'] as { maxValue: number }
      ).maxValue.toLocaleString()}`;
    }

    if (control.errors['minError']) {
      return `The value ${(
        control.errors['minError'] as { value: number }
      ).value.toLocaleString()} is less than the allowed minimum of ${(
        control.errors['minError'] as { minValue: number }
      ).minValue.toLocaleString()}.`;
    }

    return (control.errors['customMessage'] as string | null | undefined) ?? 'The value is invalid';
  }

  private statusChangesSubscription: Subscription | null = null;

  validate(control: AbstractControl): Record<string, unknown> | null {
    //if (control.pristine == true) {
    //  return null;
    //}

    if (!this.statusChangesSubscription) {
      this.statusChangesSubscription = control.statusChanges.subscribe(
        () => (this.message = MwValidationDirective.getErrorMessage(control))
      );
    }

    let message: string;

    if (this.inputType === 'number' && control.value && isNaN(control.value as number)) {
      message = 'Value is not a number';
    }

    if (this.inputType === 'number' && !isNaN(this.minimum) && this.minimum && control.value < this.minimum) {
      message = `Value should be greater than ${this.minimum}`;
    } else if (this.inputType === 'number' && !isNaN(this.maximum) && this.maximum && control.value > this.maximum) {
      message = `Value should be less than ${this.maximum}`;
    } else if (
      this.inputType === 'text' &&
      !isNaN(this.minLength) &&
      this.minLength &&
      control.value &&
      (control.value as string).length < this.minLength
    ) {
      message = `Value should have at least ${this.minLength} characters`;
    } else if (
      this.inputType === 'text' &&
      !isNaN(this.maxLength) &&
      this.maxLength &&
      control.value &&
      (control.value as string).length > this.maxLength
    ) {
      message = `Value cannot exceed ${this.maxLength} characters`;
    } else if (this.isRequired && !control.value) {
      message = 'Value cannot be blank';
    } else if (typeof this.validator === 'string') {
      message = this.validator;
    } else {
      message = this.validator(control.value, control);
    }

    return { customValidation: { message, value: control.value as unknown } };
  }
}
