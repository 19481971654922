<div class="form-section">
    <h2>{{ title }}</h2>
    <div>
      <select [(ngModel)]="selectedValue" (change)="onSelectChange($event)">
        <option [ngValue]="null">Select {{ title }}</option>
        <option *ngFor="let option of options" [value]="option.id">{{ option.name }}</option>
      </select>
      <button *ngIf="!selectedValue" type="button" (click)="toggleInput()">
        {{ showInput ? '-' : '+' }}
      </button>
      <button *ngIf="selectedValue" type="button" (click)="toggleEdit()">
        Edit
      </button>
      <input *ngIf="showInput" maxlength="50" [(ngModel)]="ownerNameInput" type="text" [placeholder]="placeholder">
      <button *ngIf="showInput" (click)="toggleAdd()">Add</button>      
    </div>
  </div>