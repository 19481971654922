<mw-navigationmenu [ngClass]="'nav-mobile'"
                  class="nav-mobile nav-desktop"
                   *ngIf="appService.isEnterDetailMenuDisplay && templateService.template && areAnswersLoaded"
                   [@inOutAnimation]
                   [selectedSection]="selectedSection"
                   (sectionOnChange)="selectedSection = $event"
                   [selectedPage]="selectedPage"
                   (pageOnChange)="pageOnChange($event)"
                   [answers]="answers"
                   (onClose)="isNavigationVisible = false"></mw-navigationmenu>
                           
<div *ngIf="templateService.template && areAnswersLoaded" class="fact-find" [class.fact-find-desktop]="isDesktopSize()">
  <a href="#" (click)="showAllPages()" *ngIf="authenticatedUserService.isMidwinter">Show All Pages</a>
  <div class="section" *ngFor="let section of sections">
    <ng-container *ngIf="templateService.shouldDisplay(section, answers)">
      <div class="section-heading">
        <div class="section-title">{{ section.label }}</div>
        <mw-info *ngIf="section.info" [info]="section.info"></mw-info>
      </div>
      <div class="prompt">
        <h4 [innerHTML]="section.prompt"
            *ngIf="section.isPromptHtml === undefined || section.isPromptHtml; else sectionPromptNotHtml"></h4>
        <ng-template #sectionPromptNotHtml>{{ section.prompt }}</ng-template>
      </div>
      <ng-container *ngFor="let page of selectedPage ? [selectedPage] : section.pages">
        <div class="page" *ngIf="templateService.shouldDisplay(page, answers)">
          <div class="page-heading">
            <h1 class="caption">{{ page.label }}</h1>
            <mw-info *ngIf="page.info" [info]="page.info"></mw-info>
          </div>
          <div class="description">
            <div class="page-prompt">
              <span
                [innerHTML]="page.prompt"
                *ngIf="page.isPromptHtml === undefined || page.isPromptHtml; else pagePromptNotHtml"
              ></span>
              <ng-template #pagePromptNotHtml>{{ page.prompt }}</ng-template>
            </div>
          </div>
          <ng-container *ngIf="page.groups">
            <ng-container *ngFor="let group of page.groups">
              <ng-container *ngIf="templateService.shouldDisplay(group, answers)">
                <div class="group" *ngIf="!group.isList">
                  <div class="desciption group-prompt-menu" *ngIf="group.prompt">
                    <mw-groupmenu [group]="group"></mw-groupmenu>
                  </div>
                  <div class="group-prompt-text">
                    <span
                      [innerHTML]="group.prompt"
                      *ngIf="group.isPromptHtml === undefined || group.isPromptHtml; else groupPromptNotHtml"
                    ></span>
                    <ng-template #groupPromptNotHtml>
                      <h4 class="group-prompt">FFF {{ group.prompt }}</h4>
                    </ng-template>
                  </div>
                  <mw-boolean
                    *ngIf="group.display"
                    [class.spacetop]="group.prompt"
                    [name]="group.name"
                    [value]="getAnswer(group.name)"
                    (valueChange)="setAnswer($event, group.name)"
                    [label]="group.display.prompt"
                    [isLabelHtml]="group.display.isPromptHtml"
                  ></mw-boolean>
                  <div *ngIf="!group.display || getAnswer(group.name)" class="fields">
                    <ng-container *ngFor="let field of group.fields">
                      <ng-container *ngIf="templateService.shouldDisplay(field, answers)">
                        <div class="field kendo-pdf-keep-together" [class.danger]="field.validationErrors && field.validationErrors.length > 0">
                          <div
                            class="prompt"
                            [class.promptinfo]="field.info"
                            *ngIf="field.prompt && field.dataType !== 'boolean'"
                          >
                            <div
                              *ngIf="field.isPromptHtml === undefined || field.isPromptHtml; else fieldPromptNotHtml"
                            >
                              <span [innerHTML]="field.prompt"></span>
                            </div>
                            <ng-template #fieldPromptNotHtml>{{ field.prompt }}</ng-template>
                            <mw-info *ngIf="field.info" [info]="field.info"></mw-info>
                          </div>
                          <!-- <div class="label" *ngIf="field.label">{{ field.label }}</div> -->
                          <label class="form-input-label" *ngIf="field.label">{{ field.label }}</label>
                          <div class="input">
                            <ng-container [ngSwitch]="field.dataType">
                              <mw-boolean [name]="field.name"
                                          [value]="getAnswer(field.name)"
                                          (valueChange)="setAnswer($event, field.name)"
                                          [label]="field.prompt"
                                          [isLabelHtml]="field.isPromptHtml"
                                          *ngSwitchCase="'boolean'"
                                          [isReadonly]="!canUpdate(field)"></mw-boolean>
                              <ng-container *ngSwitchCase="'choice'">
                                <div class="ff-select">
                                  <select [ngModel]="getAnswer(field)"
                                          (ngModelChange)="setAnswer($event, field)"
                                          *ngIf="field.type === 'single'"
                                          [disabled]="!canUpdate(field)">
                                    <option *ngFor="let item of field.items" [ngValue]="item">{{ item }}</option>
                                  </select>
                                </div>
                                <ng-container *ngIf="field.type === 'multiple'">
                                  <mw-multi-choice [name]="field.name"
                                                   [items]="field.items"
                                                   [values]="getAnswer(field) ?? []"
                                                   (valuesChange)="setAnswer($event, field)"
                                                   [isReadonly]="!canUpdate(field)"></mw-multi-choice>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngSwitchCase="'text'">
                                <input *ngIf="(field.maxLength ?? 0) <= textAreaCutoffLength"
                                       type="text"
                                       class="form-input-text"
                                       (ngModelChange)="setAnswer($event, field)"
                                       [ngModel]="getAnswer(field)"
                                       [ngModelOptions]="{ updateOn: 'blur' }"
                                       [mwValidation]="setValidator"
                                       [minLength]="field.minLength ?? 0"
                                       [maxLength]="field.maxLength ?? 9999"
                                       [required]="field.isRequired"
                                       [readonly]="!canUpdate(field)" />
                                <textarea *ngIf="(field.maxLength ?? 0) > textAreaCutoffLength"
                                          (ngModelChange)="setAnswer($event, field)"
                                          [ngModel]="getAnswer(field)"
                                          [ngModelOptions]="{ updateOn: 'blur' }"
                                          [mwValidation]="setValidator"
                                          [minLength]="field.minLength ?? 0"
                                          [maxLength]="field.maxLength ?? 9999"
                                          [required]="field.isRequired"
                                          [readonly]="!canUpdate(field)"></textarea>
                              </ng-container>
                              <mw-date *ngSwitchCase="'date'"
                                       [value]="getAnswer(field)"
                                       (valueChange)="setAnswer($event, field)"
                                       [default]="getDefault(field)"
                                       [shouldDisablePicker]="field.shouldDisablePicker"
                                       [isReadonly]="!canUpdate(field)">
                              </mw-date>
                              <ng-container *ngSwitchCase="'file'">
                                <mw-file [element]="field.name"
                                         [factFindId]="factFind!.id"
                                         [value]="getAnswer(field.name)"
                                         (valueChange)="setAnswer($event, field.name)"
                                         [isReadonly]="!canUpdate(field)"></mw-file>
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                <div [class.currency-percent-field]="field.type === 'currencyOrPercentage'">
                                  <div [ngClass]="{ 'input-with-prefix' : getPrefix(field) , 'input-with-suffix' : getSuffix(field) }"
                                       [class.currency-percent-input]="field.type === 'currencyOrPercentage'">
                                    <div class="prefix" *ngIf="getPrefix(field)">{{ getPrefix(field) }}</div>
                                    <input [type]="getInputType(field)"
                                           (ngModelChange)="setAnswer($event, field)"
                                           [ngModel]="getAnswer(field)"
                                           [ngModelOptions]="{ updateOn: 'blur' }"
                                           [mwValidation]="setValidator"
                                           [required]="field.isRequired"
                                           [readonly]="!canUpdate(field)"
                                           [min]="field.minimum"
                                           [max]="field.maximum" />
                                    <div class="suffix" *ngIf="getSuffix(field)">{{ getSuffix(field) }}</div>
                                  </div>
                                  <select class="currency-percent-select" [ngModel]="getAnswer(field.name + 'IsPercentage')"
                                          (ngModelChange)="setAnswer($event, field.name + 'IsPercentage'); setAnswer(0, field)"
                                          *ngIf="field.type === 'currencyOrPercentage'" [disabled]="!canUpdate(field)">
                                    <option [ngValue]="false">Dollar</option>
                                    <option [ngValue]="true">Percent</option>
                                  </select>
                                </div>
                              </ng-container>
                            </ng-container>
                            <!-- Adviser field (not in template) -->
                              <div *ngIf="finalPage(field.name) && this.shouldShowAdviserToggle()">
                                <div class="adviser">Current Adviser</div>
                                <div>{{getCurrentAdviser()}}</div>

                                <mw-boolean
                                            [value]="canUpdateAdviser"
                                            [label]="'Assign to new adviser'"
                                            (valueChange)="toggleAdviser()"
                                            [isReadonly]="!canUpdate(field)"></mw-boolean>
                              </div>

                              <div *ngIf="finalPage(field.name) && canUpdateAdviser">
                                <div class="adviser">Adviser</div>
                                <div class="ff-select">
                                  <select [(ngModel)]="selectedAdviser" [disabled]="!canUpdate(field)">
                                    <option value="null" disabled [selected]>Select Adviser</option>
                                    <option *ngFor="let adviser of advisers" [value]="adviser.node">{{adviser.firstName}} {{ adviser.lastName }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="description" *ngIf="field.dataType !== 'boolean'">{{field.description}}</div> -->
                            <!-- <a (click)="loadHistory(field)">Get History</a>
                                <ol>
                                  <li *ngFor="let history of getHistory(field)">{{history}}</li>
                                </ol> -->
                          </div>
                        <div class="errorMessage" *ngIf="field.validationErrors && field.validationErrors.length > 0">
                          <ul>
                            <li *ngFor="let validationError of field.validationErrors">{{ validationError }}</li>
                          </ul>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <div class="list" *ngIf="group.isList">
                  <div *ngIf="group.display">
                    <mw-boolean
                      class="bool"
                      [class.spacetop]="group.prompt"
                      [name]="group.name"
                      [value]="getAnswer(group.name, group.display.default)"
                      (valueChange)="setAnswer($event, group.name)"
                      [label]="group.display.prompt"
                      [isLabelHtml]="group.display.isPromptHtml"
                      [isReadonly]="isReadOnlyFactFind">
                    </mw-boolean>
                    <mw-list
                      *ngIf="getAnswer(group.name)"
                      [group]="group"
                      [answers]="answers"
                      (updateAnswer)="setAnswer($event.value, $event.field, $event.isDictionary)"
                      [isReadonly]="isReadOnlyFactFind"
                    ></mw-list>
                  </div>
                  <div *ngIf="!group.display">
                    <mw-list
                      [group]="group"
                      [answers]="answers"
                      (updateAnswer)="setAnswer($event.value, $event.field, $event.isDictionary)"
                      [isReadonly]="isReadOnlyFactFind"
                    ></mw-list>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

            <div class="pageBtn kendo-pdf-ignore">
              <button class="btnNext ff-button" (click)="submit()" *ngIf="this.canSubmit">Submit</button>
              <button class="btnNext ff-button" (click)="publish()" *ngIf="this.canPublish">Publish</button>
              <button class="btnNext ff-button" (click)="nextPage()" *ngIf="!this.isLastPage">Next</button>
              <button class="btnBack ff-button" (click)="previousPage()" *ngIf="!this.isFirstPage">Back</button>
            </div>
          </div>
      </ng-container>
    </ng-container>
  </div>
</div>
