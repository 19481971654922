<div class="list-item" *ngFor="let rowId of this.getRowIds(group); index as i">
  <div class="list-heading">
    <h4>{{ group.label }} {{ i + 1 }}</h4>
    <button class="delete-row" (click)="this.removeRow(rowId, group)" *ngIf="!isReadonly">
      <span>Delete</span>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon/Bin">
          <path
            id="Rectangle 56"
            d="M1 7.33333C1 5.49238 2.49238 4 4.33333 4H17.6667C19.5076 4 21 5.49238 21 7.33333V7.33333C21 7.70152 20.7015 8 20.3333 8H1.66667C1.29848 8 1 7.70152 1 7.33333V7.33333Z"
            stroke="#0E0E1A"
            stroke-width="1.5"
          />
          <path
            id="Rectangle 57"
            d="M9 3C9 1.89543 9.89543 1 11 1V1C12.1046 1 13 1.89543 13 3V4H9V3Z"
            stroke="#0E0E1A"
            stroke-width="1.5"
          />
          <path
            id="Rectangle 58"
            d="M3 8H19L17.2609 19.3041C17.1108 20.2798 16.2713 21 15.2842 21H6.71584C5.7287 21 4.8892 20.2798 4.73909 19.3041L3 8Z"
            stroke="#0E0E1A"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path id="Rectangle 59" d="M9 12V17" stroke="#0E0E1A" stroke-width="1.5" stroke-linecap="round" />
          <path id="Rectangle 60" d="M13 12V17" stroke="#0E0E1A" stroke-width="1.5" stroke-linecap="round" />
        </g>
      </svg>
    </button>
  </div>
  <ng-container *ngFor="let field of group.fields">
    <ng-container *ngIf="templateService.shouldDisplay(field, answers)">
      <div *ngIf="field.label">
        <ng-container [ngSwitch]="field.dataType">
          <ng-container *ngSwitchCase="'date'">
            <h5>{{ field.label }}</h5>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="label">{{ field.label }}</div>
          </ng-container>
        </ng-container>
      </div>
      <div class="input">
        <ng-container [ngSwitch]="field.dataType">
          <!-- text -->
          <ng-container *ngSwitchCase="'text'">
            <input *ngIf="(field.maxLength ?? 0) <= textAreaCutoffLength"
                   type="text"
                   (ngModelChange)="setRowAnswer($event, rowId, field)"
                   [ngModel]="getRowAnswer(rowId, field)"
                   [ngModelOptions]="{ updateOn: 'blur' }"
                   [mwValidation]="setValidator"
                   [minLength]="field.minLength ?? 0"
                   [maxLength]="field.maxLength ?? 9999"
                   [required]="field.isRequired"
                   [readonly]="!canUpdate(field)" />
            <textarea *ngIf="(field.maxLength ?? 0) > textAreaCutoffLength"
                      (ngModelChange)="setRowAnswer($event, rowId, field)"
                      [ngModel]="getRowAnswer(rowId, field)"
                      [ngModelOptions]="{ updateOn: 'blur' }"
                      [mwValidation]="setValidator"
                      [minLength]="field.minLength ?? 0"
                      [maxLength]="field.maxLength ?? 9999"
                      [required]="field.isRequired"
                      [readonly]="!canUpdate(field)">
            </textarea>
          </ng-container>
          <!-- choice single -->
          <ng-container *ngSwitchCase="'choice'">
            <div class="ff-select">
              <select
                [ngModel]="getRowAnswer(rowId, field)"
                (ngModelChange)="setRowAnswer($event, rowId, field)"
                *ngIf="field.type === 'single'"
                [disabled]="!canUpdate(field)"
              >
                <option value="" disabled selected hidden>Select option</option>
                <ng-container *ngIf="isArray(field.items)">
                  <option *ngFor="let item of field.items" [ngValue]="item">{{ item }}</option>
                </ng-container>
                <ng-container *ngIf="isDictionary(field.items)">
                  <option *ngFor="let item of field.items | keyvalue" [ngValue]="item.key">{{ item.value }}</option>
                </ng-container>
              </select>
            </div>
            <!-- choice multiple -->
            <ng-container *ngIf="field.type === 'multiple'">
              <mw-multi-choice
                [name]="field.name"
                [items]="field.items"
                [values]="getRowChoice(rowId, field)"
                (valuesChange)="setRowAnswer($event, rowId, field)"
                [isReadonly]="!canUpdate(field)"
              ></mw-multi-choice>
            </ng-container>
          </ng-container>
          <!-- date -->
          <ng-container *ngSwitchCase="'date'">
            <mw-date [value]="getRowDate(rowId, field)"
                     (valueChange)="setRowAnswer($event, rowId, field)"
                     [default]="getDefaultDate(field)"
                     [shouldDisablePicker]="true"
                     class="list-date"
                     [isReadonly]="isReadonly"></mw-date>
          </ng-container>
          <!-- number-->
          <ng-container *ngSwitchCase="'number'">
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="'number'">
                <input
                  type="number"
                  (ngModelChange)="setRowAnswer($event, rowId, field)"
                  [ngModel]="getRowAnswer(rowId, field)"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  class="number"
                  [mwValidation]="setValidator"
                  [required]="field.isRequired"
                  [min]="field.minimum"
                  [max]="field.maximum"
                  [readonly]="!canUpdate(field)"
                />
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                <div *ngIf="getPrefix(field)" class="input-with-prefix">
                  <div class="prefix">{{ getPrefix(field) }}</div>
                  <input
                    [type]="getInputType(field)"
                    (ngModelChange)="setRowAnswer($event, rowId, field)"
                    [ngModel]="getRowAnswer(rowId, field)"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    [mwValidation]="setValidator"
                    [required]="field.isRequired"
                    [min]="field.minimum"
                    [max]="field.maximum"
                    [readonly]="!canUpdate(field)"
                  />
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'percentage'">
                <div *ngIf="getSuffix(field)" class="input-with-suffix">
                  <input
                    [type]="getInputType(field)"
                    (ngModelChange)="setRowAnswer($event, rowId, field)"
                    [ngModel]="getRowAnswer(rowId, field)"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    [mwValidation]="setValidator"
                    [required]="field.isRequired"
                    [min]="field.minimum"
                    [max]="field.maximum"
                    [readonly]="!canUpdate(field)"
                  />
                  <div class="suffix">{{ getSuffix(field) }}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'currencyOrPercentage'">
                <label
                  >Is this a currency value?
                  <input
                    type="checkbox"
                    [ngModel]="getRowAnswer(rowId, field)"
                    (ngModelChange)="setRowAnswer($event, rowId, field); setRowAnswer(0, rowId, field)"
                  />
                </label>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- boolean -->
          <ng-container *ngSwitchCase="'boolean'">
            <mw-boolean
              [name]="field.name"
              [value]="getRowBoolean(rowId, field)"
              (valueChange)="setRowAnswer($event, rowId, field)"
              [label]="field.prompt"
              [isLabelHtml]="field.isPromptHtml"
              class="bool"
              [isReadonly]="!canUpdate(field)"
            ></mw-boolean>
          </ng-container>
          <!-- file -->
          <!-- <ng-container *ngSwitchCase="'file'">
                <mw-file [element]="field.name" [factFindId]="factFind!.id" [value]="getRowAnswer(rowId, field)" (valueChange)="setRowAnswer($event, rowId, field)"></mw-file>
              </ng-container> -->
          <!-- default -->
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
<button class="add-row" (click)="this.addRow(group)" [disabled]="isReadonly">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Plus" clip-path="url(#clip0_1346_53639)">
      <path id="Vector 7" d="M10 1.59998V18.4" stroke="var(--primary-color)" stroke-width="2" stroke-linecap="round" />
      <path id="Vector 6" d="M1.6001 10L18.4001 10" stroke="var(--primary-color)" stroke-width="2" stroke-linecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_1346_53639">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <span>Add {{ group.label }}</span>
</button>
