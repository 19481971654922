import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { MwToastService } from "./mw-toast.service";
import { Toast } from "./toast";


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mw-toast',
  templateUrl: './mw-toast.component.html',
  styleUrl: './mw-toast.component.scss',
})
export class MwToastComponent implements OnDestroy {
  toasts: Toast[] = [];
  private destroy$ = new Subject<void>();
  TOAST_TIME_OUT = 10000;
  @ViewChild("toast") toast: ElementRef<HTMLDivElement>;

  constructor(private toastService: MwToastService, private cdr: ChangeDetectorRef) {
    this.toastService.toast$
      .pipe(takeUntil(this.destroy$))
      .subscribe((toast) => {
        if (toast) {
          this.toasts.push(toast);
          this.cdr.detectChanges();

          setTimeout(() => {
            this.removeToast(toast);
          }, this.TOAST_TIME_OUT);
        }
      });
  }

  removeToast(toast: Toast) {
    this.toasts = this.toasts.filter(x => x.id !== toast.id);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
