import { BehaviorSubject } from "rxjs";
import { Injectable } from '@angular/core';
import { Toast } from "./toast";
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: "root"
})
export class MwToastService {
  private toastSubject = new BehaviorSubject<Toast | null>(null);
  toast$ = this.toastSubject.asObservable();

  show(toast: Toast) {
    this.toastSubject.next({
      ...toast,
      id: uuid()
    });
  }
}

