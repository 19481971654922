/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticatedUserService } from 'src/app/authenticated-user.service';
import { ChatService } from '../chat.services';
import { Group } from '../Group';
import { ItemTypes } from './enums/ItemTypes';
import { MenuTypes } from './enums/MenuTypes';
import { NoteService } from '../note.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-groupmenu',
  templateUrl: './groupmenu.component.html',
  styleUrl: './groupmenu.component.scss'
})
export class GroupMenuComponent implements OnInit {
  @Input()
  group: Group;

  @ViewChild('newChat')
  private newChat: ElementRef | null = null;

  menuType = MenuTypes.hide;
  menuTypes = MenuTypes;
  itemTypes = ItemTypes;
  itemType = ItemTypes.none;
  isUserClient = false;
  userId: number | null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public noteService: NoteService,
    private renderer: Renderer2,
    public chatService: ChatService,
    public authenticatedService: AuthenticatedUserService
  ) {}

  ngOnInit() {
    this.userId = this.authenticatedService.userId;
  }

  menuOnClick() {
    switch (this.menuType) {
      case MenuTypes.hide:
        this.menuType = MenuTypes.show;
        break;
      case MenuTypes.show:
        this.menuType = MenuTypes.hide;
        break;
      case MenuTypes.cross:
        if (this.itemType === ItemTypes.chat) {
          this.chatService.reset();
        }

        this.menuType = MenuTypes.hide;
        this.itemType = ItemTypes.none;

        break;
        default:
          throw new Error(`The menue type is not supported.`)
    }
  }

  showInfo() {
    this.itemType = ItemTypes.info;
    this.menuType = MenuTypes.cross;
  }

  showNote() {
    this.itemType = ItemTypes.note;
    this.menuType = MenuTypes.cross;
  }

  async showChat() {
    await this.chatService.load(this.group);

    this.chatService.elementsWithUnreadChats = this.chatService.elementsWithUnreadChats.filter(
      (x) => x !== this.group.name
    );

    this.itemType = ItemTypes.chat;
    this.menuType = MenuTypes.cross;

    this.changeDetectorRef.detectChanges();

    if (!this.newChat) throw Error('New chat element could not be found.');

    (this.renderer.selectRootElement(this.newChat.nativeElement) as HTMLElement).focus();
  }

  async addChat(value: string, group: Group) {
    if (!value) return;

    await this.chatService.add(value, group);

    this.changeDetectorRef.detectChanges();
  }

  doesGroupHaveUnreadChats(group: Group) {
    return this.chatService.elementsWithUnreadChats.includes(group.name);
  }
}
