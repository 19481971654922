import { BooleanComponent } from './boolean.component';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BooleanGroupService {
  private groups: Record<string, BooleanComponent[]> = {};

  register(group: string | number, component: BooleanComponent) {
    this.groups[group] ??= [];
    this.groups[group].push(component);
  }

  unregister(group: string | number, component: BooleanComponent) {
    this.groups[group] = this.groups[group].filter(comp => comp !== component);
    
  }

  updateGroup(group: string | number, activeComponent: BooleanComponent) {
    this.groups[group].forEach(component => {
      if (component !== activeComponent) {
        component.value = false;
        component.valueChange.emit(false);
      }
    });
  }
}