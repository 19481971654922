<div class="login-container">
  <div class="login-content">
    <h1>
      Register
    </h1>

    <form class="login-form">
      <div class="ff-login-pair">
        <label for="Email">Email</label>
        <input id="Email" class="form-input-text" type="email" placeholder="Email" autocomplete="off" required/>
      </div>

      <div class="ff-login-pair">
          <label for="Username">Username</label>
          <input id="Username" class="form-input-text" type="text" placeholder="Username" required/>
      </div>

      <div class="ff-login-pair">
        <label for="password">Password</label>
        <input id="password" required class="form-input-text" type="password" autocomplete="off" placeholder="Password"/>
        <span>Choose a password of at least eight characters, one uppercase letter and one number</span>
      </div>

      <hr />
      <div>
        <button class="ff-button ff-button--primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
</div>
