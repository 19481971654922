<h2>Password Security</h2>
We take the security of your account seriously. To ensure the protection of your personal information, please adhere to the following password guidelines:
<ol>
  <li>
    <h3>1. Password Length Requirement</h3>
    For your account's safety, we require that your password be at least 8 characters long. A longer password provides an additional layer of security, making it more resistant to unauthorized access.
  </li>

  <li>
    <h3>2. Avoid Common Passwords</h3>
    To enhance the security of your account, please refrain from using common passwords, easily guessable phrases, or widely known information. A strong password is crucial to protect your account from unauthorized access.
  </li>

  <li>
    <h3>3. Prohibited Passwords</h3>
    We actively monitor and restrict the use of passwords that have been compromised in data breaches. To safeguard your account, we do not allow the use of passwords that have been identified as compromised in any known security incident.
  </li>

  <li>
    <h3>4. Creating a Strong Password</h3>
    Consider the following tips when creating a strong and secure password:

    <ul>
      <li>Use a combination of uppercase and lowercase letters.</li>
      <li>Include numbers and special characters.</li>
      <li>Avoid easily guessable information such as your name, birthdate, or common words.</li>
    </ul>
  </li>

  <li>
    <h3>5. Regular Password Updates</h3>
    For added security, it is recommended to update your password periodically. This practice reduces the risk of unauthorized access and helps maintain the integrity of your account.

  </li>
</ol>

<br />
Thank you for your commitment to account security. If you have any questions or concerns, please don't hesitate to contact our support team.
