export enum FactFindStatuses {
  /** FactFindStatuses.Created = 1 */
  Created = 1,

  /** FactFindStatuses.Submitted = 2 */
  Submitted = 2,

  /** FactFindStatuses.Published = 3 */
  Published = 3,

  /** FactFindStatuses.Archived = 4 */
  Archived = 4,

  /** FactFindStatuses.Deleted = 5 */
  Deleted = 5
}
