export enum TemplateStatuses {
    /** TemplateStatuses.Unavailable = 1 */
    Unavailable = 1,

    /** TemplateStatuses.Available = 2 */
    Available = 2,

    /** TemplateStatuses.Deleted = 3 */
    Deleted = 3,

}
