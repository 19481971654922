/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileInfo } from '../../types/file-info.type';
import { FormControl } from '@angular/forms';
import { MwToastService } from "src/app/shared/mw-toast/mw-toast.service";
import { firstValueFrom } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush, selector: 'mw-file',
  templateUrl: './file.component.html'
})
export class FileComponent {
  constructor(private httpClient: HttpClient, private mwToastService: MwToastService) { }
  @Input()
  element: string;

  private _value: FileInfo[];

  @Input()
  get value(): FileInfo[] {
    return this._value;
  }

  set value(value: FileInfo[] | null) {
    this._value = value ?? [];
  }

  @Input()
  factFindId: number;

  @Input()
  isReadonly: boolean | undefined = false;

  @Output()
  readonly valueChange = new EventEmitter<FileInfo[]>();

  allowedFileExtensions: string[] | null = null;

  // eslint-disable-next-line max-statements, complexity
  async upload(event: Event, name: string) {
    if (this.isReadonly) return;

    const inputElement = event.target as HTMLInputElement | null;

    if (!inputElement) return;

    if (!inputElement.files) return;

    const count = inputElement.files.length;

    if (!count) return;

    // eslint-disable-next-line no-magic-numbers
    if (count > 1) throw Error('Multiple files are not supported.');

    const file = inputElement.files[0];

    inputElement.value = '';

    const fileName = name || file.name;

    const extension = this.getExtension(fileName);

    if (!extension) return;

    if (this.value.find((x) => x.name === fileName)) {
      this.mwToastService.show({
        type: "error",
        message: "A file with that name already exists."
      });

      return;
    }

    if (! this.allowedFileExtensions?.length) {
      this.allowedFileExtensions = await firstValueFrom(
        this.httpClient.get<string[]>(`api/files/getAllowedFileExtensions`)
      );
    }
      
    if (!this.allowedFileExtensions.some(e => e === extension)) {
      this.mwToastService.show({
        type: "error",
        message: "File type is not allowed."
      });

      return;
    }

    const response = await firstValueFrom(
      this.httpClient.get<UploadUrl>(`api/fact-finds/${this.factFindId}/${this.element}/upload-urls/${fileName}`)
    );

    const url = response.url;
    const key = response.key;
    const headers = new HttpHeaders(response.headers);

    await firstValueFrom(this.httpClient.put(url, file, { headers }));

    this.value.push({ name: fileName, key: `${key}.${extension}` });

    this.valueChange.emit(this.value);
  }

  /* eslint-disable no-magic-numbers */
  private getExtension(fileName: string) {
    const nameElements = fileName.split('.');

    if (nameElements.length < 2) {
      this.mwToastService.show({
        type: "error",
        message: 'The file does not have an extension.'
      });

      return null;
    }

    return nameElements.at(-1);
  }
  /* eslint-enable no-magic-numbers */

  remove(fileInfo: FileInfo) {
    this.value = this.value.filter((x) => fileInfo !== x);

    this.valueChange.emit(this.value);
  }

  setFileName(value: string, file: FileInfo, formControl: FormControl) {
    if (!this.getExtension(value)) {
      formControl.reset(file.name);

      return;
    }

    if (this.value.filter((x) => x !== file).some((x) => x.name === value)) {
      this.mwToastService.show({
        type: "error",
        message: "There is already another file with that name."
      });

      formControl.reset(file.name);

      return;
    }

    file.name = value;

    this.valueChange.emit(this.value);
  }

  async downloadFile(file: FileInfo) {
    const url = await firstValueFrom(
      this.httpClient.get<string>(`api/fact-finds/${this.factFindId}/download-urls/${file.key}`)
    );

    window.open(url, '_blank');
  }

  downloadUrls = new Map<FileInfo, string>();

  getDownloadUrl(file: FileInfo) {
    return this.downloadUrls.get(file);
  }
}

interface UploadUrl {
  key: string;
  url: string;
  headers:
  | string
  | Record<string, string | number | (string | number)[]>;
}
