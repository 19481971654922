import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { FactFindService } from '../enter-details/factFind.service';

@Component({
  selector: 'thank-you',
  templateUrl: './thank-you.component.html',
  styleUrl: './thank-you.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThankYouComponent implements OnInit {
  constructor(
    public authenticatedUserService: AuthenticatedUserService,
    public factFindService: FactFindService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.factFindService.factFind$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }
}
