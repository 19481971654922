import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-multi-choice',
  templateUrl: './multi-choice.component.html',
  styleUrl: './multi-choice.component.scss'
})
export class MultiChoiceComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  items: string[];

  @Input()
  values: string[];

  @Input()
  isReadonly: boolean | undefined = false;

  @Output()
  readonly valuesChange = new EventEmitter();

  checkboxItems: CheckboxItem[] = [];

  ngOnInit(): void {
    this.items.forEach((f) => {
      this.checkboxItems.push({ label: f, isCheck: this.values.some((v) => v === f) });
    });
  }

  onClick = (checkboxItem: CheckboxItem) => {
    if (this.isReadonly) return;

    checkboxItem.isCheck = !checkboxItem.isCheck;

    const updatedValue = this.checkboxItems.filter((f) => f.isCheck).map((f) => f.label);
    this.valuesChange.emit(updatedValue);
  };
}

interface CheckboxItem {
  label: string;
  isCheck: boolean;
}
