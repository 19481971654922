<div class="create-fact-find-container">
  <div class="create-fact-find-left">
    <ng-container *ngIf="isCreateOrSearch">
      <div (click)="backToDashboardOnClick()"
           class="ff-back-to-dashboard">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 18L2 10L10 2" stroke="var(--primary-color)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>
        <div>Back</div>
      </div>
      <div class="create-fact-find-welcome">
        <h1 class="mobile-heading">Create Fact Find</h1>
        <p class="create-fact-find-welcome__text">
          Search for a customer by last name or email address. Alternatively you can create a new account.
        </p>
      </div>

      <div class="create-customer-button">
        <button class="button ff-button" (click)="onCreateCustomerButton()">
          <span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.40015 1.59998C5.74248 1.59998 4.40015 2.94231 4.40015 4.59998C4.40015 6.25765 5.74248 7.59998 7.40015 7.59998C9.05782 7.59998 10.4001 6.25765 10.4001 4.59998C10.4001 2.94231 9.05782 1.59998 7.40015 1.59998Z"
                    stroke="#1A315A"
                    stroke-width="1.5" />
              <path d="M11.8001 10.6C11.3226 10.2578 9.79551 9.20001 7.33554 9.20001C4.16827 9.20001 1.6001 11.2142 1.6001 13.534C1.6001 15.5232 4.16827 16.4 7.33554 16.4C8.4091 16.4 9.41383 16.2993 10.2731 16.0886"
                    stroke="#1A315A"
                    stroke-width="1.5" />
              <path d="M14 10C11.7898 10 10 11.7898 10 14C10 16.2102 11.7898 18 14 18C16.2102 18 18 16.2102 18 14C18 11.7898 16.2102 10 14 10Z"
                    stroke="#1A315A"
                    stroke-width="1.5" />
              <path d="M14 12.4V15.6" stroke="#1A315A" stroke-width="1.5" stroke-linecap="round" />
              <path d="M12.3999 14L15.5999 14" stroke="#1A315A" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </span>
          <span>Create Customer</span>
        </button>
      </div>

      <div class="customer-search-container">
        <div class="customer-search">
          <input
          #query
          class="create-ff-form__input customer-search__search-bar"
            type="text"
            name=""
            id="customer-search-icon"
            placeholder="Search for existing customer"
            (keyup.enter)="onSearchQueryInput(query.value)"
             />
             <button class="customer-search-icon" (click)="onSearchQueryInput(query.value)">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.25" y="0.75" width="14.5" height="14.5" rx="7.25" stroke="#FFFEFE" stroke-width="1.5" />
              <path d="M14 13L17 16" stroke="#FFFEFE" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </button>
        </div>
      </div>

      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-pulse-sync"
        [disableAnimation]="false">
          <p style="font-size: 1.25rem; color: white">Loading...</p>
      </ngx-spinner>

      <div class="result-container">
        <div *ngIf="searchResults !== null && searchResults?.length" class="ff-search-group">
          <div class="ff-search-result-count">
            {{ searchResults.length }} result{{ searchResults.length ? 's' : '' }} found
          </div>
          <div class="ff-search-item" [class.view-all]="isViewAll" *ngFor="let search of searchResults; let i = index">
            <div class="ff-search-item-left">
              <div class="ff-search-item-name" (click)="onSearchViewButton(i)">{{ search.firstName }} {{ search.lastName }}</div>
              <div class="ff-search-item-email">{{ search.email }}</div>
            </div>
            <button (click)="onSearchViewButton(i)" class="ff-search-item-action">View</button>
          </div>
          <button class="ff-button ff-button--primary view-all-link" *ngIf="!isViewAll && searchResults.length > 4"
            (click)="isViewAll = true">
            View All
          </button>
        </div>

        <div *ngIf="!searchResults?.length" class="ff-search-group">
          <p>No results found, please try again or create a new client.</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!clientUserId && !isCreateOrSearch">
      <div (click)="backToCreateOrSearch()"
           class="ff-back-to-dashboard">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 18L2 10L10 2" stroke="var(--primary-color)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>
        <div>Back</div>
      </div>
      <div class="create-fact-find-welcome">
        <h1 class="mobile-heading">New Customer</h1>
        <p class="create-fact-find-welcome__text">
          Create a new customer by filling out the form below. The Fact Find will be created at the same time.
        </p>
      </div>

      <div *ngIf="searchResults?.length"
           (click)="isCreateOrSearch = true"
           class="ff-back-to-search">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 18L2 10L10 2" stroke="var(--primary-color)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>
        <div>Back to search results</div>
      </div>

      <div *ngIf="hasTestingFeaturesPermission">
        <p>
          Please enter the following details.<br />
          Alternatively, generate a unique set of details.<br />
          <button class="generate-button" (click)="generateUniqueDetails()">Generate</button>
        </p>
      </div>

      <div class="create-ff-form-group">
        <div class="ff-form-responsive">
          <div class="create-ff__pair">
            <label for="first_name" class="create-ff-form__label">First Name</label>
            <input id="first_name"
                   class="create-ff-form__input"
                   type="text"
                   [(ngModel)]="firstName"
                   autocomplete="off" />
            <button *ngIf="hasTestingFeaturesPermission" class="generate-button" (click)="generateFirstName()">Generate</button>
          </div>
          <div class="create-ff-pair">
            <label for="last_name" class="create-ff-form__label">Last Name</label>
            <input id="last_name"
                   class="create-ff-form__input"
                   type="text"
                   [(ngModel)]="lastName"
                   autocomplete="off" />
            <button *ngIf="hasTestingFeaturesPermission" class="generate-button" (click)="generateLastName()">Generate</button>
          </div>
          <div class="create-ff-pair">
            <label for="email_address" class="create-ff-form__label">Email Address</label>
            <input id="email_address"
                   class="create-ff-form__input"
                   #email="ngModel"
                   type="email"
                   [(ngModel)]="emailAddress"
                   autocomplete="off"
                   pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" />
            <p class="create-ff-error-message">
              <span class="warning-message"></span>{{ emailAddress }} is not a valid email address
            </p>
            <button *ngIf="hasTestingFeaturesPermission" class="generate-button" (click)="generateEmailAddress()">Generate</button>
          </div>
          <div class="create-ff-pair">
            <label for="mobile_number" class="create-ff-form__label">Mobile Number</label>
            <input id="mobile_number"
                   class="create-ff-form__input"
                   type="tel"
                   [(ngModel)]="mobileNumber"
                   autocomplete="off"
                   inputmode="numeric" />
            <button *ngIf="hasTestingFeaturesPermission" class="generate-button" (click)="generateMobileNumber()">Generate</button>
          </div>

          <ng-container *ngIf="selectedSearchResult !== null">
            <div class="create-ff-pair">
              <label class="create-ff-form__label">Date of Birth</label>
              <div>{{ selectedSearchResult.birthdate | date: 'dd/MM/y' }}</div>
            </div>

            <div class="create-ff-pair">
              <label class="create-ff-form__label">Address</label>
              <div>{{ selectedSearchResult.suburb }}, {{ selectedSearchResult.state }}</div>
            </div>
          </ng-container>

          <div class="create-ff-pair">
            <label for="owner" class="create-ff-form__label">Owner</label>
            <div class="ff-select">
              <select id="owner" [(ngModel)]="owner" class="create-ff-form__input">
                <option *ngFor="let owner of owners" [ngValue]="owner">{{ owner.name }} ({{ owner.type }})</option>
              </select>
            </div>
          </div>
          <div class="create-ff-pair">
            <label for="select_template" class="create-ff-form__label">Select Template</label>
            <div id="select_template" class="ff-select">
              <select [(ngModel)]="template" class="create-ff-form__input">
                <ng-container *ngFor="let template of templates">
                  <option *ngIf="template.statusId === 2" [ngValue]="template">{{ template.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="ff-action-buttons">
        <button class="button button--primary ff-button" (click)="create()">Create New Fact Find</button>
        <button class="button ff-button" (click)="backToCreateOrSearch()">Cancel</button>
      </div>

    </ng-container>

    <ng-container *ngIf="clientUserId && !isCreateOrSearch && hasTestingFeaturesPermission">
      <p>The fact find has been created. Click here to log out and authenticate as the client.</p>
      <button (click)="logOutAndAuthenticateAsClient()">Log Out and Authenticate as Client</button>
      <br />
      <p>
        Alternatively, you can
        <a routerLink="/enter-details" [queryParams]="{ factFindId: factFindId }">edit the new Fact Find</a> in the adviser
        role.
      </p>
    </ng-container>

  </div>

  <div class="desktop-aside">

  </div>


</div>
