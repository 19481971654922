<ng-container *ngIf="hasLabel">
  <div *ngIf="isLabelHtml === false; else isHtml">{{ label }}</div>
  <ng-template #isHtml>
    <div [innerHtml]="label"></div>
  </ng-template>
</ng-container>
<div class="toggle" [class.yes]="value" (click)="onClick()" [class.isReadonly]="isReadonly">
  <div class="ball"></div>
  <div class="label">{{ value ? 'Yes' : 'No' }}</div>
</div>
