<h1>Owner Management</h1>
<owner-form
    [title]="'Group'"
    [options]="groups"
    [placeholder]="'Enter group name'"
    [parentId]="1"
    [typeId]="OwnerTypes.Group"
    [selectedValue]="selectedGroupId"
    (selectionChange)="onGroupChange($event)"
    (add)="onOwnerCreation($event)"
    (save)="updateOwner($event)">
</owner-form>

<owner-form
    *ngIf="selectedGroupId"
    [title]="'Licensee'"
    [options]="licensees"
    [placeholder]="'Enter licensee name'"
    [parentId]="selectedGroupId"
    [typeId]="OwnerTypes.Licensee"
    [selectedValue]="selectedLicenseeId"
    (selectionChange)="onLicenseeChange($event)"
    (add)="onOwnerCreation($event)"
    (save)="updateOwner($event)">
</owner-form>

<owner-form
    *ngIf="selectedLicenseeId"
    [title]="'Practice'"
    [options]="practices"
    [placeholder]="'Enter practice name'"
    [parentId]="selectedLicenseeId"
    [typeId]="OwnerTypes.Practice"
    [selectedValue]="selectedPracticeId"
    (selectionChange)="onPracticeChange($event)"
    (add)="onOwnerCreation($event)"
    (save)="updateOwner($event)">
</owner-form>

<owner-form
    *ngIf="selectedPracticeId"
    [title]="'Office'"
    [options]="offices"
    [placeholder]="'Enter office name'"
    [parentId]="selectedPracticeId"
    [typeId]="OwnerTypes.Office"
    [selectedValue]="selectedOfficeId"
    (selectionChange)="onOfficeChange($event)"
    (add)="onOwnerCreation($event)"
    (save)="updateOwner($event)">
</owner-form>