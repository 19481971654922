import { BooleanComponent } from './boolean.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [BooleanComponent],
  imports: [
    CommonModule
  ],
  exports: [BooleanComponent]
})
export class BooleanModule { }
