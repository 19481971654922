import { CommonModule, } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from "@angular/forms";
import { MwDialogComponent } from './mw-dialog/mw-dialog.component';
import { MwPromptComponent } from './mw-prompt/mw-prompt.component';
import { NgModule } from '@angular/core';


@NgModule({
  declarations: [
    MwDialogComponent,
    MwPromptComponent
  ],
  imports: [
    CommonModule, DialogModule, DragDropModule, FormsModule
  ]
})
export class MwDialogModule { }
