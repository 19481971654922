<ng-container *ngIf="!isIconName">
  <small> Icon [{{ name }}] does not exists </small>
</ng-container>

<ng-container *ngIf="name === 'email'">
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 66 66"
    style="enable-background: new 0 0 66 66"
    xml:space="preserve"
    [style.width]="size"
  >
    <style type="text/css">
      .st0 {
        fill: none;
        stroke: #1a315a;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    </style>
    <path [style.stroke]="colorValue" class="st0" d="M60.7,60.7V30.4L32.3,44.5L5.3,30.4v30.4H60.7z" />
    <path [style.stroke]="colorValue" class="st0" d="M52.8,33l0-27.7H13.2V33" />
    <path [style.stroke]="colorValue" class="st0" d="M60.7,30.4l-7.9-8.6 M5.3,30.4l7.9-8.6" />
    <path [style.stroke]="colorValue" class="st0" d="M60.7,59.4L37,42.2 M5.3,59.4l22.4-17.2" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'bin'">
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" [style.width]="size">
    <g id="Icon/Bin">
      <path
        id="Rectangle 56"
        d="M1 7.33333C1 5.49238 2.49238 4 4.33333 4H17.6667C19.5076 4 21 5.49238 21 7.33333V7.33333C21 7.70152 20.7015 8 20.3333 8H1.66667C1.29848 8 1 7.70152 1 7.33333V7.33333Z"
        [style.stroke]="colorValue"
        stroke-width="1.5"
      />
      <path
        id="Rectangle 57"
        d="M9 3C9 1.89543 9.89543 1 11 1V1C12.1046 1 13 1.89543 13 3V4H9V3Z"
        [style.stroke]="colorValue"
        stroke-width="1.5"
      />
      <path
        id="Rectangle 58"
        d="M3 8H19L17.2609 19.3041C17.1108 20.2798 16.2713 21 15.2842 21H6.71584C5.7287 21 4.8892 20.2798 4.73909 19.3041L3 8Z"
        [style.stroke]="colorValue"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path id="Rectangle 59" d="M9 12V17" [style.stroke]="colorValue" stroke-width="1.5" stroke-linecap="round" />
      <path id="Rectangle 60" d="M13 12V17" [style.stroke]="colorValue" stroke-width="1.5" stroke-linecap="round" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'user'">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" [style.width]="size">
    <g>
      <path
        d="M10.0548 1.59998C7.85853 1.59998 6.07666 3.39164 6.07666 5.59998C6.07666 7.80831 7.85853 9.59998 10.0548 9.59998C12.251 9.59998 14.0329 7.80831 14.0329 5.59998C14.0329 3.39164 12.251 1.59998 10.0548 1.59998Z"
        [style.stroke]="colorValue"
        stroke-width="2"
      />
      <path
        d="M10.0547 11.2C14.8313 11.2 18.4088 13.2964 18.4088 15.4988C18.4088 17.68 15.48 18.4 10.0547 18.4C4.62952 18.4 1.70068 17.68 1.70068 15.4988C1.70068 12.358 7.2952 11.2 10.0547 11.2Z"
        [style.stroke]="colorValue"
        stroke-width="2"
      />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'money'">
  <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" [style.width]="size">
    <path
      d="M21 46C30.3888 46 38 38.3888 38 29C38 19.6112 30.3888 12 21 12C11.6112 12 4 19.6112 4 29C4 38.3888 11.6112 46 21 46Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 19L18 21"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.6406 36.8306L26.6406 38.8306"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.7847 34.3739C18.3632 36.6632 20.9778 38.0099 23.6156 37.359C26.2534 36.7081 27.9657 34.5759 27.3641 32.2866C26.7625 29.9973 24.4486 28.9649 21.4869 29.0771C18.4558 29.2118 16.7435 27.8876 16.2113 26.0022C15.5866 23.7578 17.0443 21.4461 19.3813 20.885C21.7183 20.3239 24.0784 21.693 24.6569 23.9598"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5 12C21 10.5 22.1946 8.88554 22.9308 8.11727C26.9794 3.89244 33.6026 2.69683 39.0661 5.57334C45.5231 8.97293 47.8854 16.8181 44.3757 23.0725C43.065 25.3824 41.1357 27.1644 38.8857 28.3031"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M37 9L36 11" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M36.9101 15.5309C37.8356 13.9149 37.2109 11.895 35.5449 10.9972C33.8789 10.0994 31.7502 10.683 30.8709 12.3214C30.4288 13.1389 30.3414 14.0684 30.7368 15"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'piggy-bank'">
  <svg [style.width]="size" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6996 10.02C19.7099 10.02 21.3396 8.39031 21.3396 6.37999C21.3396 4.36967 19.7099 2.73999 17.6996 2.73999C15.6893 2.73999 14.0596 4.36967 14.0596 6.37999C14.0596 8.39031 15.6893 10.02 17.6996 10.02Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M25.7605 18.9329L23.5205 17.8129"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M13.7458 23.8997C13.0267 23.8997 12.3209 23.8478 11.6551 23.7441V25.166C11.6551 25.9052 11.0292 26.26 10.2702 26.26H9.17821C8.41916 26.26 7.79328 25.9052 7.79328 25.166V22.4602C6.87443 21.9544 6.08875 21.3319 5.46286 20.6316C5.36965 20.5278 5.18321 20.3203 5.18321 20.3203H3.64023C2.77465 20.3203 2.24023 19.5033 2.24023 18.4787V16.3519C2.24023 15.3403 2.58822 14.5103 3.64023 14.5103H4.65055C4.65055 14.5103 4.74376 14.3417 4.78371 14.2768C5.5694 12.98 6.88774 11.8906 8.56564 11.1384L7.50031 8.16853C10.1503 7.10509 11.3488 8.37603 12.3476 10.1528C12.4275 10.2695 12.5074 10.3862 12.6006 10.4899C13.9056 12.1889 15.983 13.2912 18.3267 13.2912C19.4054 13.2912 20.4308 13.0578 21.3496 12.6428C22.5082 13.6543 23.2672 14.8993 23.467 16.2611C23.5069 16.4945 23.5202 16.7409 23.5202 16.9744C23.5202 17.2078 23.5069 17.4672 23.467 17.7006C23.4004 18.1545 23.2672 18.5825 23.0941 19.0105C22.5748 20.2036 21.616 21.2541 20.3509 22.0711V25.14C20.3509 25.8793 19.725 26.2341 18.9659 26.2341H17.874C17.1149 26.2341 16.489 25.8793 16.489 25.14V23.6014C15.8768 23.796 14.452 23.8997 13.7458 23.8997Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <ellipse
      cx="8.21234"
      cy="15.3621"
      rx="0.56"
      ry="0.571569"
      stroke-linejoin="round"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'earnings'">
  <svg [style.width]="size" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.52083 12.593H7.34339V22.9207C7.34339 23.4401 6.9266 23.8532 6.40263 23.8532H3.52083C2.99687 23.8532 2.58008 23.4401 2.58008 22.9207V13.5255C2.58008 13.0061 2.99687 12.593 3.52083 12.593Z"
      stroke-miterlimit="10"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M7.34277 12.593H8.18826C9.72443 12.593 11.2368 12.9117 12.6539 13.5137L17.179 15.4612C17.7506 15.709 18.215 16.1575 18.5008 16.7241L19.251 18.2231"
      stroke-miterlimit="10"
      stroke-linecap="round"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M7.34277 21.3509L13.023 23.7116C15.1903 24.6086 17.5839 24.6558 19.7869 23.8414L23.6809 22.4014C24.3359 22.1536 24.6217 21.3391 24.2644 20.7018L24.0382 20.3005C23.5261 19.4034 22.5973 18.8487 21.5851 18.8487H14.7974L11.3559 17.4913"
      stroke-miterlimit="10"
      stroke-linecap="round"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M19.5348 10.38C21.6887 10.38 23.4348 8.63387 23.4348 6.47996C23.4348 4.32605 21.6887 2.57996 19.5348 2.57996C17.3809 2.57996 15.6348 4.32605 15.6348 6.47996C15.6348 8.63387 17.3809 10.38 19.5348 10.38Z"
      stroke-miterlimit="10"
      stroke-linecap="round"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'management'">
  <svg [style.height]="size" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.62031 7.26001C8.89902 7.26001 8.32031 7.83872 8.32031 8.56001C8.32031 9.2813 8.89902 9.86001 9.62031 9.86001C10.3416 9.86001 10.9203 9.2813 10.9203 8.56001C10.9203 7.83872 10.3416 7.26001 9.62031 7.26001Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M3.38008 11.9399C2.65879 11.9399 2.08008 12.5187 2.08008 13.2399C2.08008 13.9612 2.65879 14.5399 3.38008 14.5399C4.10137 14.5399 4.68008 13.9612 4.68008 13.2399C4.68008 12.5187 4.10137 11.9399 3.38008 11.9399Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M14.8205 2.57996C14.0992 2.57996 13.5205 3.15867 13.5205 3.87996C13.5205 4.60125 14.0992 5.17996 14.8205 5.17996C15.5418 5.17996 16.1205 4.60125 16.1205 3.87996C16.1205 3.15867 15.5418 2.57996 14.8205 2.57996Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M22.6203 3.32666C21.899 3.32666 21.3203 3.90537 21.3203 4.62666C21.3203 5.34795 21.899 5.92666 22.6203 5.92666C23.3416 5.92666 23.9203 5.34795 23.9203 4.62666C23.9203 3.90537 23.3416 3.32666 22.6203 3.32666Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <rect x="2.08008" y="19.22" width="3.12" height="5.2" [style.stroke]="colorValue" stroke-width="2" />
    <rect x="14.5605" y="11.4199" width="3.12" height="13" [style.stroke]="colorValue" stroke-width="2" />
    <rect x="8.32031" y="14.5399" width="3.12" height="9.88" [style.stroke]="colorValue" stroke-width="2" />
    <rect x="20.7998" y="9.85999" width="3.12" height="14.56" [style.stroke]="colorValue" stroke-width="2" />
    <path
      d="M4.41992 12.72L8.83992 9.34001M13.7799 4.66001L10.6599 7.78001M21.3199 4.66001L16.1199 4.14001"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'chevron-down'">
  <svg [style.width]="size" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5L6 6.5L11 1.5" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'life'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 48 48" fill="none">
    <path
      d="M39.6998 33.2131C33.7064 37.2486 28.1254 35.6657 25.0742 31.1379C22.0229 26.6101 22.6795 20.8273 28.6459 16.8101C34.6122 12.7928 46.7157 16.5662 46.7157 16.5662C46.7157 16.5662 45.6661 29.1959 39.6998 33.2131Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M46.4961 16.9019L23.7299 25.7842"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M22.7852 5.79171C28.9264 11.6925 25.2888 15.6677 22.7852 22.6247C18.1447 27.0837 12.0869 27.8196 5.97357 21.9455C-0.139761 16.0714 1.52751 1.51963 1.52751 1.51963C1.52751 1.51963 16.6719 -0.082402 22.7852 5.79171Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M22.9199 22.9201L2.3999 2.40015"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M23.1836 46.6802L22.9199 22.9202"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'heart'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 48 48" fill="none">
    <path
      d="M36.8251 1.69326C29.9651 0.0821014 25.7398 3.95895 23.9751 8.46516C22.2104 3.93378 17.985 0.0821014 11.125 1.69326C2.97259 3.60651 -0.109429 12.4679 1.92868 21.7572C3.54426 29.1585 6.84997 33.6647 12.7903 38.9261C15.6238 41.4436 22.9063 46.6798 23.9999 46.6798C25.0935 46.6798 32.5003 41.5443 35.2095 38.9261C42.2435 32.129 44.4556 29.1585 46.0712 21.7572C48.1093 12.4427 45.0273 3.58134 36.85 1.66809L36.8251 1.69326Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'lifestyle'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 48 48" fill="none">
    <path
      d="M23.9998 46.6798L1.31982 21.0316L8.99462 1.31982H39.2264L46.6798 21.3836L23.9998 46.6798Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.31982 21.0317H45.9665"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.99463 1.31982L23.6555 19.9516L39.2264 1.31982"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'tick'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 32 32" fill="none">
    <path
      d="M2.56006 17.1687L10.2401 24.96L29.4401 7.04004"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'adjustments'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 32 32" fill="none">
    <path d="M2.56006 7.68018L12.8001 7.68018" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" />
    <path d="M19.2002 24.3198L29.4402 24.3198" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" />
    <path d="M24.3198 29.4399L24.3198 19.1999" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" />
    <rect
      x="19.2002"
      y="2.56006"
      width="10.24"
      height="10.24"
      rx="5.12"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect
      x="2.56006"
      y="19.2002"
      width="10.24"
      height="10.24"
      rx="5.12"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'chevron-right'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 21 20" fill="none">
    <path
      d="M11 2L19 10L11 18"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'help'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_3586_1913)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.199219 9.00019C0.199219 4.11131 4.11033 0.200195 8.99922 0.200195C13.8881 0.200195 17.7992 4.11131 17.7992 9.00019C17.7992 13.8891 13.8881 17.8002 8.99922 17.8002C4.11033 17.8002 0.199219 13.8891 0.199219 9.00019ZM8.99922 4.80019C8.00511 4.80019 7.19922 5.60608 7.19922 6.60019C7.19922 7.15248 6.7515 7.60019 6.19922 7.60019C5.64693 7.60019 5.19922 7.15248 5.19922 6.60019C5.19922 4.50151 6.90054 2.80019 8.99922 2.80019C10.926 2.80019 12.7992 4.0904 12.7992 6.20019C12.7992 7.89119 11.7883 8.85528 11.0925 9.51885L11.0894 9.52186C10.3292 10.2468 9.99922 10.5951 9.99922 11.2002C9.99922 11.7525 9.5515 12.2002 8.99922 12.2002C8.44693 12.2002 7.99922 11.7525 7.99922 11.2002C7.99922 9.6918 8.95754 8.78551 9.60263 8.17546L9.60291 8.1752C9.63937 8.14072 9.67483 8.10718 9.70906 8.07453C10.4132 7.40302 10.7992 6.96696 10.7992 6.20019C10.7992 5.50999 10.1653 4.80019 8.99922 4.80019ZM9.87924 14.2891C9.68368 14.4846 9.39035 14.5824 9.09702 14.5824C8.80368 14.5824 8.51035 14.4846 8.31479 14.2891C8.11924 14.0935 8.02146 13.898 8.02146 13.6046C8.02146 13.3113 8.11924 13.1158 8.31479 12.9202C8.51035 12.7246 8.80368 12.6269 9.09702 12.6269C9.39035 12.6269 9.68368 12.7246 9.87924 12.9202C10.0748 13.1158 10.1726 13.3113 10.1726 13.6046C10.1726 13.898 10.0748 14.0935 9.87924 14.2891Z"
        [style.fill]="colorValue"
      />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'house-1'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" viewBox="0 0 51 51" fill="none">
    <path
      d="M8.18917 26.5C7.5711 26.5 6.94484 26.5 6.30959 26.5C4.50719 26.5 3.62444 24.3031 4.92575 23.056L23.9482 4.82615C24.7218 4.08479 25.9422 4.08479 26.7158 4.82615L45.7383 23.056C47.0396 24.3031 46.1569 26.5 44.3545 26.5C43.7192 26.5 43.093 26.5 42.4749 26.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M30.332 46.5V35.5C30.332 34.3954 29.4366 33.5 28.332 33.5H22.332C21.2275 33.5 20.332 34.3954 20.332 35.5V46.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8.33203 26.5V44.5C8.33203 45.6046 9.22746 46.5 10.332 46.5H40.332C41.4366 46.5 42.332 45.6046 42.332 44.5V26.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'house-2'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 64 45" fill="none">
    <path
      d="M6.85714 23.5C6.23907 23.5 5.61281 23.5 4.97756 23.5C3.17516 23.5 2.29241 21.3031 3.59371 20.056L22.6162 1.82615C23.3898 1.08479 24.6102 1.08479 25.3838 1.82615L44.4063 20.056C45.7076 21.3031 44.8248 23.5 43.0224 23.5C42.3872 23.5 41.7609 23.5 41.1429 23.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M29 43.5V32.5C29 31.3954 28.1046 30.5 27 30.5H21C19.8954 30.5 19 31.3954 19 32.5V43.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M7 23.5V41.5C7 42.6046 7.89543 43.5 9 43.5H41V23.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M59.408 23.4998V23.4998C61.0776 23.4998 61.8992 21.4683 60.699 20.3077L49.1814 9.17018C48.8085 8.80952 48.31 8.60791 47.7912 8.60791L33 8.60791"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M46 31.5C46 29.2909 47.7909 27.5 50 27.5V27.5C52.2091 27.5 54 29.2909 54 31.5V31.5C54 33.7091 52.2091 35.5 50 35.5V35.5C47.7909 35.5 46 33.7091 46 31.5V31.5Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M59 23.5V41.5C59 42.6046 58.1046 43.5 57 43.5H37"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'house-3'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 84 51" fill="none">
    <path
      d="M24.0212 26.5C23.4031 26.5 22.7769 26.5 22.1416 26.5C20.3392 26.5 19.4565 24.3031 20.7578 23.056L39.7803 4.82615C40.5539 4.08479 41.7743 4.08479 42.5479 4.82615L61.5703 23.056C62.8717 24.3031 61.9889 26.5 60.1865 26.5C59.5513 26.5 58.925 26.5 58.3069 26.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M6.75606 26.4998V26.4998C5.0865 26.4998 4.2649 24.4683 5.4651 23.3077L16.9826 12.1702C17.3556 11.8095 17.8541 11.6079 18.3729 11.6079L32.1641 11.6079"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M20.1641 34.5C20.1641 32.2909 18.3732 30.5 16.1641 30.5V30.5C13.9549 30.5 12.1641 32.2909 12.1641 34.5V34.5C12.1641 36.7091 13.9549 38.5 16.1641 38.5V38.5C18.3732 38.5 20.1641 36.7091 20.1641 34.5V34.5Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M7.16406 26.5V44.5C7.16406 45.6046 8.05949 46.5 9.16406 46.5H35.1641"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M46.1641 46.5V35.5C46.1641 34.3954 45.2686 33.5 44.1641 33.5H38.1641C37.0595 33.5 36.1641 34.3954 36.1641 35.5V46.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path d="M24.1641 26.5V46.5H58.1641V26.5" [style.stroke]="colorValue" stroke-width="2" stroke-linecap="round" />
    <path
      d="M76.5721 26.4998V26.4998C78.2416 26.4998 79.0632 24.4683 77.863 23.3077L66.3455 12.1702C65.9726 11.8095 65.474 11.6079 64.9552 11.6079L50.1641 11.6079"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M63.1641 34.5C63.1641 32.2909 64.9549 30.5 67.1641 30.5V30.5C69.3732 30.5 71.1641 32.2909 71.1641 34.5V34.5C71.1641 36.7091 69.3732 38.5 67.1641 38.5V38.5C64.9549 38.5 63.1641 36.7091 63.1641 34.5V34.5Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M36.1641 21.5C36.1641 18.7386 38.4026 16.5 41.1641 16.5V16.5C43.9255 16.5 46.1641 18.7386 46.1641 21.5V26.5H36.1641V21.5Z"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M76.1641 26.5V44.5C76.1641 45.6046 75.2686 46.5 74.1641 46.5H54.1641"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'food-1'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 51 50" fill="none">
    <path
      d="M16.332 15.9361V7C16.332 5.89543 17.2275 5 18.332 5C19.4366 5 20.332 5.89543 20.332 7V14C20.332 14.8284 21.0036 15.5 21.832 15.5C22.6605 15.5 23.332 14.8284 23.332 14V6C23.332 4.89543 24.2275 4 25.332 4C26.4366 4 27.332 4.89543 27.332 6V14C27.332 14.8284 28.0036 15.5 28.832 15.5C29.6605 15.5 30.332 14.8284 30.332 14V7C30.332 5.89543 31.2275 5 32.332 5C33.4366 5 34.332 5.89543 34.332 7V15.9361C34.332 16.9416 33.9533 17.9103 33.2712 18.6492L29.5309 22.7012C28.7673 23.5285 28.3876 24.6393 28.4852 25.7609L30.1431 44.8267C30.2447 45.9953 29.3235 47 28.1506 47H22.5135C21.3406 47 20.4194 45.9953 20.521 44.8267L22.1789 25.7609C22.2764 24.6393 21.8968 23.5285 21.1332 22.7012L17.3928 18.6492C16.7108 17.9103 16.332 16.9416 16.332 15.9361Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'food-2'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 40 45" fill="none">
    <path
      d="M1 12.9361V4C1 2.89543 1.89543 2 3 2C4.10457 2 5 2.89543 5 4V11C5 11.8284 5.67157 12.5 6.5 12.5C7.32843 12.5 8 11.8284 8 11V3C8 1.89543 8.89543 1 10 1C11.1046 1 12 1.89543 12 3V11C12 11.8284 12.6716 12.5 13.5 12.5C14.3284 12.5 15 11.8284 15 11V4C15 2.89543 15.8954 2 17 2C18.1046 2 19 2.89543 19 4V12.9361C19 13.9416 18.6213 14.9103 17.9392 15.6492L14.1989 19.7012C13.4352 20.5285 13.0556 21.6393 13.1531 22.7609L14.811 41.8267C14.9126 42.9953 13.9915 44 12.8185 44H7.18146C6.00852 44 5.08737 42.9953 5.18898 41.8267L6.84688 22.7609C6.94441 21.6393 6.56476 20.5285 5.80113 19.7012L2.06079 15.6492C1.37873 14.9103 1 13.9416 1 12.9361Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M23 11.0988V9C23 4.58172 26.5817 1 31 1C35.4183 1 39 4.58172 39 9V11.0988C39 13.3042 38.2709 15.4478 36.9262 17.1959L34.9494 19.7658C34.3388 20.5596 34.0481 21.5535 34.1349 22.5512L35.811 41.8267C35.9126 42.9953 34.9915 44 33.8185 44H28.1815C27.0085 44 26.0874 42.9953 26.189 41.8267L27.8651 22.5512C27.9519 21.5535 27.6612 20.5596 27.0506 19.7658L25.0738 17.1959C23.7291 15.4478 23 13.3042 23 11.0988Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'food-3'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 53 46" fill="none">
    <path
      d="M23.6641 42.5V2.39871C23.6641 2.00076 23.902 1.64122 24.2936 1.57032C25.6672 1.32164 29.0108 1.42355 31.5202 7.27663C33.9643 12.9773 33.7704 21.2623 33.5295 24.9724C33.4504 26.1905 32.7645 27.2702 31.7279 27.9149L30.6736 28.5707C30.3442 28.7756 30.163 29.1524 30.2087 29.5376L31.7174 42.2646C31.8584 43.4541 30.9292 44.5 29.7313 44.5H25.6641C24.5595 44.5 23.6641 43.6046 23.6641 42.5Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M1.66406 13.4361V4.5C1.66406 3.39543 2.55949 2.5 3.66406 2.5C4.76863 2.5 5.66406 3.39543 5.66406 4.5V11.5C5.66406 12.3284 6.33564 13 7.16406 13C7.99249 13 8.66406 12.3284 8.66406 11.5V3.5C8.66406 2.39543 9.55949 1.5 10.6641 1.5C11.7686 1.5 12.6641 2.39543 12.6641 3.5V11.5C12.6641 12.3284 13.3356 13 14.1641 13C14.9925 13 15.6641 12.3284 15.6641 11.5V4.5C15.6641 3.39543 16.5595 2.5 17.6641 2.5C18.7686 2.5 19.6641 3.39543 19.6641 4.5V13.4361C19.6641 14.4416 19.2853 15.4103 18.6033 16.1492L14.8629 20.2012C14.0993 21.0285 13.7197 22.1393 13.8172 23.2609L15.4751 42.3267C15.5767 43.4953 14.6555 44.5 13.4826 44.5H7.84552C6.67258 44.5 5.75143 43.4953 5.85304 42.3267L7.51094 23.2609C7.60847 22.1393 7.22883 21.0285 6.4652 20.2012L2.72485 16.1492C2.0428 15.4103 1.66406 14.4416 1.66406 13.4361Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M35.6641 11.5988V9.5C35.6641 5.08172 39.2458 1.5 43.6641 1.5C48.0823 1.5 51.6641 5.08172 51.6641 9.5V11.5988C51.6641 13.8042 50.935 15.9478 49.5903 17.6959L47.6134 20.2658C47.0028 21.0596 46.7122 22.0535 46.799 23.0512L48.4751 42.3267C48.5767 43.4953 47.6555 44.5 46.4826 44.5H40.8455C39.6726 44.5 38.7514 43.4953 38.853 42.3267L40.5292 23.0512C40.6159 22.0535 40.3253 21.0596 39.7147 20.2658L37.7378 17.6959C36.3931 15.9478 35.6641 13.8042 35.6641 11.5988Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'energy-1'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 34 44" fill="none">
    <path
      d="M23.332 31.5H23.9163C25.1705 31.5 26.1152 32.6411 25.8811 33.8733L25.6911 34.8733C25.5118 35.817 24.6869 36.5 23.7263 36.5H9.93781C8.9772 36.5 8.15227 35.817 7.97296 34.8733L7.78296 33.8733C7.54885 32.6411 8.49357 31.5 9.74781 31.5H10.332"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8.99967 39.0134C8.66252 37.7442 9.61938 36.5 10.9326 36.5H22.7314C24.0447 36.5 25.0015 37.7442 24.6644 39.0134V39.0134C24.4316 39.8899 23.6383 40.5 22.7314 40.5H10.9326C10.0258 40.5 9.23247 39.8899 8.99967 39.0134V39.0134Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M20.332 40.5L20.305 40.7318C20.1874 41.7398 19.3334 42.5 18.3185 42.5H15.3456C14.3307 42.5 13.4767 41.7398 13.3591 40.7318L13.332 40.5"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M1.33203 17C1.33203 8.43959 8.27162 1.5 16.832 1.5C25.3924 1.5 32.332 8.43959 32.332 17C32.332 25.5604 25.3924 32.5 16.832 32.5C8.27162 32.5 1.33203 25.5604 1.33203 17Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'energy-2'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 56 54" fill="none">
    <path
      d="M26.2995 40.0895C28.0091 39.476 29.6368 38.7386 31.0974 37.5063C37.6054 32.016 38.3335 22.402 32.7237 16.0327C27.114 9.66335 17.2907 8.95073 10.7828 14.441C4.27483 19.9312 3.5467 29.5453 9.15645 35.9146C10.8295 37.8142 12.8773 39.2107 15.1003 40.0895"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.4509 43.347C11.2168 42.1148 12.1615 40.9736 13.4158 40.9736H27.5842C28.8385 40.9736 29.7832 42.1148 29.5491 43.347L29.3591 44.347C29.1798 45.2907 28.3548 45.9736 27.3942 45.9736H13.6058C12.6452 45.9736 11.8202 45.2907 11.6409 44.347L11.4509 43.347Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M12.5133 48.3894C12.249 47.1452 13.1978 45.9736 14.4697 45.9736H26.5303C27.8022 45.9736 28.751 47.1452 28.4867 48.3894L28.2742 49.3894C28.0779 50.3131 27.2622 50.9736 26.3178 50.9736H14.6822C13.7378 50.9736 12.9221 50.3131 12.7258 49.3894L12.5133 48.3894Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M24 50.9736L23.973 51.2054C23.8554 52.2135 23.0013 52.9736 21.9864 52.9736H19.0136C17.9987 52.9736 17.1446 52.2135 17.027 51.2054L17 50.9736"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M40.2995 34.0895C42.0091 33.476 43.6368 32.7386 45.0974 31.5063C51.6054 26.016 52.3335 16.402 46.7237 10.0327C41.114 3.66335 31.2907 2.95073 24.7828 8.44099C24.0807 9.0333 23.4458 9.67361 22.879 10.3527"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5 34.9736H41.5842C42.8385 34.9736 43.7832 36.1148 43.5491 37.347L43.3591 38.347C43.1798 39.2907 42.3548 39.9736 41.3942 39.9736H27.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M27 39.9736H40.5303C41.8022 39.9736 42.751 41.1452 42.4867 42.3894L42.2742 43.3894C42.0779 44.3131 41.2622 44.9736 40.3178 44.9736H29.5"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M38 44.9736L37.973 45.2054C37.8554 46.2135 37.0013 46.9736 35.9864 46.9736H33.0136C31.9987 46.9736 31.1446 46.2135 31.027 45.2054L31 44.9736"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'energy-3'">
  <svg xmlns="http://www.w3.org/2000/svg" [style.width]="size" [style.height]="size" viewBox="0 0 70 55" fill="none">
    <path
      d="M40.4635 40.6158C42.1731 40.0023 43.8008 39.2649 45.2615 38.0327C51.7694 32.5424 52.4976 22.9283 46.8878 16.559C41.2781 10.1897 31.4547 9.4771 24.9468 14.9674C18.4389 20.4576 17.7108 30.0717 23.3205 36.441C24.9936 38.3406 27.0414 39.737 29.2643 40.6158"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.615 43.8733C25.3809 42.6411 26.3256 41.5 27.5798 41.5H41.7483C43.0025 41.5 43.9472 42.6411 43.7131 43.8733L43.5231 44.8733C43.3438 45.817 42.5189 46.5 41.5583 46.5H27.7698C26.8092 46.5 25.9843 45.817 25.805 44.8733L25.615 43.8733Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M26.6774 48.9157C26.413 47.6716 27.3618 46.5 28.6337 46.5H40.6944C41.9663 46.5 42.9151 47.6716 42.6507 48.9157L42.4382 49.9157C42.2419 50.8394 41.4263 51.5 40.4819 51.5H28.8462C27.9019 51.5 27.0862 50.8394 26.8899 49.9157L26.6774 48.9157Z"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M38.1641 51.5L38.137 51.7318C38.0194 52.7398 37.1654 53.5 36.1505 53.5H33.1776C32.1627 53.5 31.3087 52.7398 31.1911 51.7318L31.1641 51.5"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M54.4635 34.6158C56.1731 34.0023 57.8008 33.2649 59.2615 32.0327C65.7694 26.5424 66.4976 16.9283 60.8878 10.559C55.2781 4.18971 45.4547 3.4771 38.9468 8.96735C38.2447 9.55967 37.6099 10.2 37.043 10.879"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.6641 35.5H55.7483C57.0025 35.5 57.9472 36.6411 57.7131 37.8733L57.5231 38.8733C57.3438 39.817 56.5189 40.5 55.5583 40.5H41.6641"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M41.1641 40.5H54.6944C55.9663 40.5 56.9151 41.6716 56.6507 42.9157L56.4382 43.9157C56.2419 44.8394 55.4263 45.5 54.4819 45.5H43.6641"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M52.1641 45.5L52.137 45.7318C52.0194 46.7398 51.1654 47.5 50.1505 47.5H47.1776C46.1627 47.5 45.3087 46.7398 45.1911 45.7318L45.1641 45.5"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
    <path
      d="M14.7474 34.6158C13.0378 34.0023 11.4101 33.2649 9.94944 32.0327C3.44151 26.5424 2.71339 16.9283 8.32313 10.559C13.9329 4.18971 23.7562 3.4771 30.2641 8.96735C30.9662 9.55967 31.6011 10.2 32.1679 10.879"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5469 35.5H13.4627C12.2084 35.5 11.2637 36.6411 11.4978 37.8733L11.6878 38.8733C11.8671 39.817 12.692 40.5 13.6527 40.5H27.5469"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M28.0469 40.5H14.5165C13.2446 40.5 12.2958 41.6716 12.5602 42.9157L12.7727 43.9157C12.969 44.8394 13.7847 45.5 14.729 45.5H25.5469"
      [style.stroke]="colorValue"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M17.0469 45.5L17.0739 45.7318C17.1915 46.7398 18.0455 47.5 19.0604 47.5H22.0333C23.0482 47.5 23.9022 46.7398 24.0198 45.7318L24.0469 45.5"
      [style.stroke]="colorValue"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'warning'">
  <svg xmlns="http://www.w3.org/2000/svg" x="20" y="20" viewBox="0 0 50 50" xml:space="preserve" [style.width]="size"><path d="M24.9 3h.3c1.1 0 2.2.7 2.7 1.7l18.7 38.2c.3.7.4 1.5.1 2.2-.4 1.2-1.5 1.9-2.7 1.9H6c-1.2 0-2.3-.7-2.7-1.9-.3-.7-.2-1.5.1-2.2L22.2 4.7c.5-1 1.5-1.7 2.7-1.7M23 18h4v15h-4zm4 21c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2" style="fill-rule:evenodd;clip-rule:evenodd;fill:#1a315a"/></svg>
</ng-container>
