import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { HttpClient } from '@angular/common/http';
import { MwToastService } from "../shared/mw-toast/mw-toast.service";
import { Owner } from './Owner';
import { Template } from '../template';
import { TemplateStatuses } from '../enums/dbo.TemplateStatus';
import { firstValueFrom } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush, selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.scss'
})
export class TemplatesComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private mwToastService: MwToastService,
    public authenticatedUserService: AuthenticatedUserService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.owners = (await firstValueFrom(this.httpClient.get('api/hierarchyOwners', { withCredentials: true }))) as Owner[];

    await this.refresh();

    this.changeDetectorRef.detectChanges();
  }

  templates: Template[];
  template: Template;
  newTemplateName: string | null;
  newTemplateBody: string | null;
  owner: Owner | null;
  owners: Owner[] | undefined;
  isHierarchyOwner = true;

  getOwnerById(id: number): Owner {
    if (!this.owners) {
      throw new Error("No owners are currently loaded");
    }

    const owner = this.owners.find(x => x.id === id);
    if (!owner) {
      throw new Error(`Owner with ID ${id} not found`);
    }

    return owner;
  }

  private async refresh() {
    this.templates = (await firstValueFrom(
      this.httpClient.get('api/templates', { withCredentials: true })
    )) as Template[];
    this.template = this.templates[0];

    this.changeDetectorRef.detectChanges();
  }

  async saveTemplate(shouldUpdate = false) {
    const newTemplateName = this.newTemplateName;
    const newTemplateBody = this.newTemplateBody;
    const newOwnerId = this.owner?.id;

    //Reset these without waiting for the request to complete so that the button is disabled.
    this.newTemplateBody = null;
    this.newTemplateName = null;
    this.owner = null;

    this.changeDetectorRef.detectChanges();

    const body = { name: newTemplateName, body: newTemplateBody, ownerId: newOwnerId };

    if (shouldUpdate) {
      await firstValueFrom(this.httpClient.put(`api/templates/${this.template.id}`, body));
    } else {
      await firstValueFrom(this.httpClient.post(`api/templates`, body));
    }

    await this.refresh();

    this.changeDetectorRef.detectChanges();

    this.mwToastService.show({
      type: "success",
      message: "Your template was successfully uploaded."
    });
  }

  get isValid(): boolean {
    return Boolean(this.newTemplateBody) && Boolean(this.newTemplateName);
  }

  setTemplate(templateId: number) {
    const template = this.templates.find((x) => x.id === templateId);

    if (!template) throw Error(`There is no template with the id '${templateId}'.`);

    this.template = template;
  }

  async loadTemplate() {
    this.newTemplateName = this.template.name;

    this.newTemplateBody = (await firstValueFrom(
      this.httpClient.get(`api/templates/${this.template.id}`, { params: { shouldSkipValidation: true } })
    )) as string;
    this.owner = this.owners?.find((x) => x.id === this.template.ownerId) ?? null;

    this.changeDetectorRef.detectChanges();
  }

  // eslint-disable-next-line class-methods-use-this
  getTemplateStatusBooleanValue(template: Template): boolean {
    return template.statusId === TemplateStatuses.Available;
  }

  async setTemplateStatusBooleanValue(template: Template, isAvailable: boolean) {
    template.statusId = isAvailable ? TemplateStatuses.Available : TemplateStatuses.Unavailable;
    await firstValueFrom(this.httpClient.put(`api/templates/${template.id}/status`, template.statusId));
    await this.refresh();
  }

  async setTemplateDefault(template: Template, isDefault: boolean) {
    await firstValueFrom(this.httpClient.put(`api/templates/${template.id}/default`, isDefault));
  }
}
