<div class="modal">
  <div class="modal-overlay" (click)="close('backdrop')"></div>
  <div class="modal-box" cdkDrag cdkDragBoundary=".modal" [ngStyle]="{ 'max-width': maxWidth }">
    <div class="modal-header" cdkDragHandle>
      <strong [innerHTML]="title" *ngIf="headerVisible"></strong>
    </div>

    <div class="modal-content" *ngIf="component">
      <ng-template *ngComponentOutlet="component; inputs: inputs"></ng-template>
    </div>

    <div class="modal-footer">
      <button class="ff-button" (click)="close('cancel')" *ngIf="cancelButtonVisible">{{ cancelButtonText }}</button>
      <button class="ff-button ff-button--primary" (click)="save()">{{ saveButtonText }}</button>
    </div>
  </div>
</div>
