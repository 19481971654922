import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-standalone, @angular-eslint/prefer-standalone-component
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mw-idletimer',
  templateUrl: './mw-idletimer.component.html',
  styleUrl: './mw-idletimer.component.scss'
})
export class MwIdleTimerComponent {

  constructor(private changeDetector: ChangeDetectorRef) { }

  countdown: number;
  isTimerEnabled = false;

  show(_countdown: number) {
    this.isTimerEnabled = true;
    this.countdown = _countdown;
    this.changeDetector.detectChanges();
  }

  close() {
    this.isTimerEnabled = false;
    this.changeDetector.detectChanges();
  }
}
