import { FactFindService } from './factFind.service';
import { Group } from './Group';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NoteService {
  constructor(
    private factFindService: FactFindService,
    private httpClient: HttpClient
  ) {
    factFindService.factFind$.subscribe(() => {
      void this.refresh();
    });
  }

  private notes: Map<string, string | null>;

  public async refresh() {
    if (!this.factFindService.factFind) return;

    this.notes = new Map(
      (await firstValueFrom(this.httpClient.get(`api/factfinds/${this.factFindService.factFindId}/notes`))) as [
        string,
        string | null
      ][]
    );
  }

  get(group: Group) {
    return this.notes.get(group.name);
  }

  async set(value: string | null, group: Group) {
    if (value === this.get(group)) return;

    this.notes.set(group.name, value);

    await firstValueFrom(
      this.httpClient.put(`api/factfinds/${this.factFindService.factFindId}/notes/${group.name}`, JSON.stringify(value))
    );
  }
}
