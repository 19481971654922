export enum LoginResult {
  LoginSuccess,
  IncorrectCredentials,
  AccountLockout,
  RegisterMfa,
  TemporaryMfaCreationSuccess,
  TemporaryMfaCreationFailure,
  MfaLoginFailure,
  MfaLockout,
  OneTimePasswordSent
}
