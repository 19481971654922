<div class="shield">
  <div class="shield-icon"></div>
</div>

<div class="password-01">
  <div class="welcome-password">
    <h2>Set up password</h2>
    <p>Please create a secure password by including symbols, numbers, uppercase and lowercase characters.</p>
  </div>

  <!-- Current Password -->
  <div class="password-input" *ngIf="userAlreadyHasPassword">
    <input class="set-pw" [type]="showCurrentPassword ? 'text' : 'password'" [(ngModel)]="currentPassword" placeholder="Current Password" />
    <label for="set-pw">
      <div [ngClass]="[showCurrentPassword ? 'show-password' : 'hide-password']"
           (click)="togglePasswordVisibility('0')"
           class="eye"></div>
    </label>
  </div>

  <!-- New Password (1) -->
  <div class="password-input">
    <input class="set-pw" [type]="showNewPassword1 ? 'text' : 'password'" [(ngModel)]="newPassword1" (blur)="validatePassword1()" placeholder="New Password" autocomplete="off"/>
    <label for="set-pw">
      <div [ngClass]="[showNewPassword1 ? 'show-password' : 'hide-password']"
           (click)="togglePasswordVisibility('1')"
           class="eye"></div>
    </label>
  </div>

  <!-- New Password (2) -->
  <div class="password-input">
    <input class="set-pw" [type]="showNewPassword2 ? 'text' : 'password'" [(ngModel)]="newPassword2" (blur)="validatePassword2()" placeholder="New Password (Repeat)" autocomplete="off"/>
    <label for="set-pw">
      <div [ngClass]="[showNewPassword2 ? 'show-password' : 'hide-password']"
           (click)="togglePasswordVisibility('2')"
           class="eye"></div>
    </label>
  </div>
  <div style="margin-top: -40px">
    <p class="input-error-message" *ngIf="validationResult === PasswordValidationResult.InvalidLength">
      <span class="warning-message"></span>New password must be at least {{passwordMinLengthLimit}} characters.
    </p>
    <p class="input-error-message" *ngIf="validationResult === PasswordValidationResult.InvalidPwned">
      <span class="warning-message"></span>
      <span>
        Sorry! This password has been exposed in a public data breach.<br />
        This means that it is no longer safe to use. <br />
        Please choose a different, unique password.<br />
        <a href="password-security" target="_blank">Read more about password security</a>
      </span>
    </p>
    <p class="input-error-message" *ngIf="newPasswordsNotMatch && newPassword2">
      <span class="warning-message"></span>
      <span>
        New passwords do not match.
      </span>
    </p>
  </div>

  <p class="set-up-later" *ngIf="!userAlreadyHasPassword && !wasRedirected">
    <a href="#">Set up later</a>
  </p>
</div>

<button class="button ff-button button--primary" (click)="setPassword()" [disabled]="validationResult !== PasswordValidationResult.Valid || newPasswordsNotMatch || (userAlreadyHasPassword && !currentPassword)">Save and Continue</button>
