import { CommonModule } from '@angular/common';
import { MwSuccessComponent } from './mw-success.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    MwSuccessComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [MwSuccessComponent]
})
export class MwSuccessModule { }
