import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../app.service';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { EnterDetailsComponent } from "../enter-details/enter-details.component";
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrl: './mainmenu.component.scss'
})
export class MainMenuComponent implements OnInit {
  isAccountMenuItemShowed = false;
  @Input() enterDetailsRef: EnterDetailsComponent | null;
  @Output() readonly generatePdfCLicked = new EventEmitter();

  constructor(
    private router: Router,
    public appService: AppService,
    public authenticatedUserService: AuthenticatedUserService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.appService.isEnterDetail$.subscribe(() => { this.changeDetectorRef.detectChanges(); });
    this.authenticatedUserService.isSetPasswordMenuDisplay$.subscribe(() => { this.changeDetectorRef.detectChanges(); });
  }

  enterDetailNavOnClick() {
    this.appService.isEnterDetail = true;
  }

  closeAccountMenuItem() {
    this.isAccountMenuItemShowed = false;
  }

  async passwordOnClick() {
    this.closeAccountMenuItem();
    await this.router.navigate(['set-password']);
  }

  async logoutOnClick() {
    this.closeAccountMenuItem();
    await this.authenticatedUserService.logOut();
    await this.router.navigate(['login']);
  }

  enterDetailMenuOnClick() {
    this.appService.isEnterDetailMenuDisplay = true;
    this.closeAccountMenuItem();
  }

  showGeneratePdf(): boolean {
    return this.appService.isEnterDetail;
  }

  generatePDF() {
    this.generatePdfCLicked.emit();
  }
}
