/* eslint-disable complexity */
/* eslint-disable max-statements */
import { Subject, firstValueFrom } from 'rxjs';
import { AnswerType } from '../types/answer.type';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { DateComponent } from './date/date.component';
import { Element } from './Element';
import { FactFind } from './FactFind';
import { FactFindStatuses } from '../enums/dbo.FactFindStatus';
import { Group } from './Group';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleRestriction } from './RoleRestriction';
import ShadowRealm from 'shadowrealm-api';
import { Template } from './Template';

declare type Primitive = undefined | null | boolean | number | string | symbol | bigint;

//I tried pretty hard to get rid of this violation - but was not able to.
//I suspect that it is related to a circular reference between this service and the EnterDetailsComponent but did not get much further than that.
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class TemplateService {
  constructor(
    private httpClient: HttpClient,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  async initialize() {
    this.evaluateExpressionInShadowRealm = (await this.shadowRealm.importValue(
      '/scripts/evaluate-expression.js',
      'evaluateExpression'
      // eslint-disable-next-line @typescript-eslint/ban-types
    )) as (expression: string) => Function | Primitive;
  }

  template: Template;
  private shadowRealm = new ShadowRealm();

  // eslint-disable-next-line @typescript-eslint/ban-types
  private evaluateExpressionInShadowRealm: (expression: string) => Function | Primitive;

  changes$ = new Subject<void>();

  async loadTemplate(factFind: FactFind) {
    this.template = await firstValueFrom(
      this.httpClient.get<Template>(`api/templates/${factFind.templateId}/${factFind.templateVersion}`)
    );

    for (const field of this.getFields().filter((x) => x.dataType === 'date')) {
      if (!field.default) continue;

      field.default = DateComponent.parseDate(field.default as string);
    }

    this.makeReadonlyForRoleAndStatus(factFind);
  }

  makeReadonlyForRoleAndStatus(factFind: FactFind) {
    if (this.authenticatedUserService.isClient && factFind.statusId === FactFindStatuses.Created) return;

    if (this.authenticatedUserService.isAdviser && factFind.statusId <= FactFindStatuses.Submitted) return;

    const role = this.authenticatedUserService.role;

    for (const field of this.getFields()) {
      field.roleRestrictions ??= [];

      let roleRestriction: RoleRestriction | null = field.roleRestrictions.find((x) => x.role === role) ?? null;

      if (roleRestriction) {
        roleRestriction.access = 'Readonly';
      } else {
        roleRestriction = new RoleRestriction(role, 'Readonly');

        field.roleRestrictions.push(roleRestriction);
      }
    }

    this.changes$.next();
  }

  shouldDisplay(element: Element, answers: Map<string, AnswerType>) {
    const access = this.getAccess(element);

    if (access === 'None') {
      return false;
    }

    if (!element.conditions) return true;

    const context = this.buildExpressionContext(answers);

    for (const expression of element.conditions) {
      if (!this.evaluateExpression<boolean>(answers, expression, context)) return false;
    }

    return true;
  }

  evaluateExpression<T extends Primitive>(answers: Map<string, AnswerType>, expression: string, context?: string): T {
    const localContext = context ?? this.buildExpressionContext(answers);

    const result = this.evaluateExpressionInShadowRealm(`${localContext}return ${expression}`);

    if (result instanceof Function) throw Error('The expression returned a function which is not allowed.');

    return result as T;
  }

  private buildExpressionContext(answers: Map<string, AnswerType>) {
    const fields = this.getFields();

    let result = '';

    for (const field of fields) {
      let value = answers.get(field.name);

      if (field.dataType === 'choice' && field.type === 'multiple' && typeof value === 'undefined') {
        value = [];
      }

      let serializedValue = JSON.stringify(value);

      if (field.dataType === 'date') {
        serializedValue = `new Date(${serializedValue})`;
      }

      result += `const ${field.name} = ${serializedValue};\r\n`;
    }

    return result;
  }

  getDisplayedPages(answers: Map<string, AnswerType>) {
    return this.template.sections
      .filter((x) => this.shouldDisplay(x, answers))
      .flatMap((x) => x.pages.filter((y) => this.shouldDisplay(y, answers)));
  }

  private getPages() {
    return this.template.sections.flatMap((section) => section.pages);
  }

  getGroups(): Group[] {
    return this.getPages()
      .filter((page) => page.groups)
      .flatMap((page) => page.groups) as Group[];
  }

  getFields() {
    return this.getGroups().flatMap((group) => group.fields);
  }

  getAccess(element: Element): 'None' | 'Readonly' | 'Update' {
    const roleRestriction = element.roleRestrictions?.find((x) => x.role === this.authenticatedUserService.role);

    if (!roleRestriction) return 'Update';

    return roleRestriction.access;
  }
}
