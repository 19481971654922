<nav>
  <div class="cross">
    <svg
      (click)="closeOnClick()"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 34 35"
      style="enable-background: new 0 0 34 35"
      xml:space="preserve"
    >
      <g>
        <path class="st0" d="M9.7,10.1l13.9,14.6" />
        <path class="st0" d="M9.7,24.7l13.9-14.6" />
      </g>
    </svg>
  </div>

  <div class="navigationmenu-list">
    <ng-container *ngFor="let section of templateService.template.sections">
      <ng-container *ngIf="templateService.shouldDisplay(section, answers)">
        <div class="section" [class.selected]="this.selectedSection === section" (click)="sectionOnClick(section)">
          <div class="section-icon" [ngClass]="[section.name]"></div>
          <div class="section-label">{{ section.label }}<span class="section-has-unread" *ngIf="this.chatService.doesSectionHaveUnreadChats(section)"></span></div>
          <!-- <div class="section-complete section-completed"></div> -->
        </div>
        <ng-container *ngIf="section === this.selectedSection">
          <div class="pages">
            <ng-container *ngFor="let page of section.pages">
              <ng-container *ngIf="templateService.shouldDisplay(page, answers)">
                <div class="page" [class.selected]="this.selectedPage === page" (click)="pageOnClick(page)">
                  <div class="page-label">{{ page.label }}<span class="page-has-unread"  *ngIf="this.chatService.doesPageHaveUnreadChats(page)"></span></div>
                  <!-- <div class="page-complete page-completed">
                                    </div> -->
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</nav>
