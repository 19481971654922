import { BehaviorSubject } from 'rxjs';

export class AppService {
  isEnterDetail$ = new BehaviorSubject<boolean>(false);
  isEnterDetailMenuDisplay$ = new BehaviorSubject<boolean>(false);

  get isEnterDetail() {
    return this.isEnterDetail$.value;
  }

  set isEnterDetail(value: boolean) {
    this.isEnterDetail$.next(value);
  }

  get isEnterDetailMenuDisplay() {
    return this.isEnterDetailMenuDisplay$.value;
  }

  set isEnterDetailMenuDisplay(value: boolean) {
    this.isEnterDetailMenuDisplay$.next(value);
  }
}
