/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'mw-date',
  templateUrl: './date.component.html',
  styleUrl: './date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateComponent {

  private _value: Date | null = null;

  @Input()
  isReadonly: boolean | undefined = false;

  @Input() set value(value: Date | null) {
    if (!value || isNaN(Number(value))) {
      this._value = this.getDefault();
      return;
    }
    this._value = value;
  }

  get value(): Date | null {
    return this._value;
  }

  @Input() default: Date | null = null;
  @Input() shouldDisablePicker = false;
  @Output() readonly valueChange = new EventEmitter<Date>();
  @ViewChild('dayElement', { static: true, read: NgControl }) dayNgControl: NgControl;
  @ViewChild('monthElement', { static: true, read: NgControl }) monthNgControl: NgControl;
  @ViewChild('yearElement', { static: true, read: NgControl }) yearNgControl: NgControl;

  get day(): number | null {
    if (!this.value) return this.temporaryDay;

    return this.value.getDate();
  }

  set day(value: number | null) {
    if (!value) throw Error("Can't set day to null.");

    this.setDate(this.year, this.month, value);

    //If the value was coerced (the entered value was outside of the valid range) then this is required to get the new value back into the UI.
    if (this.day !== value) {
      this.dayNgControl.control?.setValue(this.day);
    }
  }

  get maximumDay(): number {
    //Month minus one because months are zero based.
    //Month plus one to get the next month.
    //Zero as the day will result in the last day of the previous month.
    return DateComponent.getMaximumDay(this.value);
  }

  private static getMaximumDay(value: Date | null) {
    const maximumDaysInMonth = 31;

    if (!value) return maximumDaysInMonth;

    const lastDayOfPreviousMonth = 0; //Zero as the day will result in the last day of the previous month.

    return new Date(value.getFullYear(), DateComponent.getMonth(value), lastDayOfPreviousMonth).getDate();
  }

  get month(): number | null {
    if (!this.value) return this.temporaryMonth;

    //The month numbers are zero based.
    //https://www.w3schools.com/jsref/jsref_getmonth.asp
    return DateComponent.getMonth(this.value);
  }

  set month(value: number | null) {
    if (!value) throw Error("Can't set month to null.");

    const maximumMonthNumber = 12;

    let truncatedValue = Math.min(maximumMonthNumber, value);

    const minimumMonthNumber = 1;

    truncatedValue = Math.max(minimumMonthNumber, truncatedValue);

    this.setDate(this.year, truncatedValue, this.day);

    //If the value was coerced (the entered value was outside of the valid range) then this is required to get the new value back into the UI.
    if (this.month !== value) {
      this.monthNgControl.control?.setValue(this.month);
    }
  }

  get year(): number | null {
    if (!this.value) return this.temporaryYear;

    return this.value.getFullYear();
  }

  set year(value: number | null) {
    if (!value) throw Error("Can't set year to null.");

    const minimumYearNumber = 1;

    const truncatedValue = Math.max(minimumYearNumber, value);

    this.setDate(truncatedValue, this.month, this.day);

    //If the value was coerced (the entered value was outside of the valid range) then this is required to get the new value back into the UI.
    if (this.year !== value) {
      this.yearNgControl.control?.setValue(this.year);
    }
  }

  private temporaryDay: number | null;
  private temporaryMonth: number | null;
  private temporaryYear: number | null;

  private setDate(year: number | null, month: number | null, day: number | null): void {
    if (!year || !month || !day) {
      this.temporaryDay = day ?? null;
      this.temporaryMonth = month ?? null;
      this.temporaryYear = year ?? null;

      return;
    }

    this.temporaryDay = null;
    this.temporaryMonth = null;
    this.temporaryYear = null;

    const newValue = DateComponent.createDate(year, month, day);

    this.setDateFromDate(newValue);
  }

  private setDateFromDate(newValue: Date) {
    if (!this.value || newValue.getTime() !== this.value.getTime()) {
      this.value = newValue;

      this.valueChange.emit(this.value);
    }
  }

  private getDefault() {
    let defaultValue: Date | null;

    if (this.default) {
      defaultValue = this.default;
    } else {
      defaultValue = null;
    }

    return defaultValue;
  }

  static getToday() {
    const today = new Date();

    return DateComponent.createDate(today.getFullYear(), DateComponent.getMonth(today), today.getDate());
  }

  private static readonly ecmascriptMonthAdjustment = 1;

  private static getMonth(value: Date): number {
    return value.getMonth() + DateComponent.ecmascriptMonthAdjustment;
  }

  static parseDate(value: string) {
    if (value === 'today') return DateComponent.getToday();

    const elements = value.split('-').map((x) => parseInt(x));

    return DateComponent.createDate(elements[0], elements[1], elements[2]);
  }

  static createDate(year: number, month: number, day: number): Date {
    const temporaryDefaultMonthNumber = 1;

    //The month numbers are zero based.
    //https://www.w3schools.com/jsref/jsref_getmonth.asp
    const result = new Date(Date.UTC(year, month - DateComponent.ecmascriptMonthAdjustment, temporaryDefaultMonthNumber));

    //There is undesirable behaviour with two digit dates. Use the setFullYear method to override that.
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#interpretation_of_two-digit_years
    result.setFullYear(year);

    const truncatedDay = this.truncateDay(result, day);

    result.setDate(truncatedDay);

    return result;
  }

  private static truncateDay(result: Date, day: number) {
    let truncatedDay = Math.min(this.getMaximumDay(result), day);

    const minimumMonthNumber = 1;

    truncatedDay = Math.max(truncatedDay, minimumMonthNumber);

    return truncatedDay;
  }

  setDateFromEvent(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) throw Error('Not supported for targets other than HTMLInputElements.');

    const htmlInputElement = event.target ;

    if (htmlInputElement.type !== 'date') throw Error('Not supported for input elements other than date.');

    const result = htmlInputElement.valueAsDate;

    if (!result) throw Error('Date value could not be retrieved.');

    this.setDateFromDate(result);
  }

  @ViewChild('dateInput') private dateInputRef: ElementRef<HTMLInputElement>;

  calenderOnClick() {
    this.dateInputRef.nativeElement.showPicker();
  }

  getStringDate() {
    if (!this.day || !this.month || !this.year) return "";

    return `${this.day}/${this.month}/${this.year}`;
  }
}
