import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mw-success',
  templateUrl: './mw-success.component.html',
  styleUrl: './mw-success.component.scss'
})
export class MwSuccessComponent {
  @Input() size = 1;
}
