/* eslint-disable no-warning-comments */
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthenticatedUserService } from './authenticated-user.service';
import { BrandSetting } from './BrandSetting';
import { Branding } from './Branding';
import { Colors } from './Colors';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  constructor(
    private httpClient: HttpClient,
    authenticatedUserServices: AuthenticatedUserService
  ) {
    //TODO: Temporarily disabling to allow for the build pipeline to be configured to fail on ESLint errors.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    authenticatedUserServices.isAuthenticated$.subscribe(async () => {
      this.brandings = await firstValueFrom(this.httpClient.get<Branding[]>(`/api/brandings`));

      if (!this.brandings.length) throw Error('There are no brandings available.');

      await this.setBranding(this.brandings[0]);
    });
  }

  brandings: Branding[] = [];

  isInitialized = false;

  async refresh(branding: Branding) {
    const data: BrandSetting = await firstValueFrom(this.httpClient.get<BrandSetting>(`/api/branding/${branding.id}`));

    this.colors = data.colors;
    this.borderRadius = data.borderRadius ;

    this.isInitialized = true;

    this.branding$.next(branding);
  }

  public colors: Colors;

  private _borderRadius: string;

  private set borderRadius(value: string) {
    this._borderRadius = value;
  }

  get borderRadius() {
    return this._borderRadius;
  }

  branding$ = new BehaviorSubject<Branding | null>(null);

  get branding() {
    return this.branding$.value;
  }

  get brandingId() {
    return this.branding?.id ?? 'default';
  }

  async setBranding(branding: Branding) {
    if (branding === this.branding) return;

    await this.refresh(branding);
  }

  get favIconUrl() {
    return `./api/branding/${this.brandingId}/images/favicon`;
  }
}