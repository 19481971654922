import { CommonModule } from '@angular/common';
import { MwIconComponent } from './mw-icon.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    MwIconComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [MwIconComponent]
})
export class MwIconModule { }
