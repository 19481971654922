import { DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import { AuthenticatedUserService } from './authenticated-user.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IdleUserService {
  idleTime = 0.1;
  gapInSecondsForNetworkingDelays = 10;
  timerMaxSeconds = 60;
  oneThousand = 1000;

  constructor(public idle: Idle, private httpClient: HttpClient, private authenticatedUserService: AuthenticatedUserService) {
    this.idle.setIdle(this.idleTime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Default interrupts (clicks, scrolls, touches, etc.)

    window.addEventListener('storage', (event) => {
      if (event.key === 'idleState' && event.newValue === 'Started') {
        this.idle.watch();
      }

      if (event.key === 'idleState' && event.newValue === 'Stopped') {
        this.idle.stop();
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.stopWatching();
    });
  }

  async getRemainingSessionDuration(): Promise<number> {
    return await firstValueFrom(this.httpClient.get<number>('api/session/getRemainingSessionDuration'));
  }

  async setRecurredRemainingSessionDurationFollower(): Promise<void> {
    this.stopWatching();
    if (!this.authenticatedUserService.isAuthenticated) return;

    // get the remaining session duration in seconds
    // because of networking delays, we are leaving 10 seconds gap between real expiration time and expiration time shown in UI
    const remainingTimeInSeconds = await this.getRemainingSessionDuration() - this.gapInSecondsForNetworkingDelays;

    // if there is less than 60 seconds time left, start watching and open the dialog
    if (remainingTimeInSeconds <= this.timerMaxSeconds) {
      this.idle.setTimeout(Math.trunc(remainingTimeInSeconds));

      this.startWatching();

      return;
    }
    
    // get remaining session duration again 60 seconds before the expiration of previous duration
    setTimeout(() => {
      void this.setRecurredRemainingSessionDurationFollower();
    }, (remainingTimeInSeconds - this.timerMaxSeconds) * this.oneThousand);
  }

  startWatching() {
    this.idle.watch();
    localStorage.setItem('idleState', 'Started');
  }

  stopWatching() {
    this.idle.stop();
    localStorage.setItem('idleState', 'Stopped');
  }

  async sendDummyRequestForSessionSlidingAndStopWatching() {
    this.stopWatching();
    await firstValueFrom(this.httpClient.get('api/session/sessionSlider'));
  }
}


