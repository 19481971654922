import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AnswerType } from '../../types/answer.type';
import { Element } from '../Element';
import { Field } from '../Field';
import { Group } from '../Group';
import { TemplateService } from '../template.service';
import { v4 as uuid } from 'uuid';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent {
  @Input()
  group: Group;

  @Input()
  answers: Map<string, AnswerType>;

  @Input()
  isReadonly: boolean | undefined = false;

  @Output()
  readonly updateAnswer = new EventEmitter<{ value: AnswerType; field: string | Field, isDictionary: boolean}>();

  constructor(public templateService: TemplateService) {}

  readonly textAreaCutoffLength = 30;

  public getInputType = getInputType;

  addRow(group: Group) {
    if (this.isReadonly) return;
    const rows: string[] = this.getRowIds(group);
    rows.push(uuid());
    this.updateAnswer.emit({ value: rows, field: ListComponent.getRowsName(group), isDictionary: false });
  }

  removeRow(rowId: string, group: Group) {
    if (this.isReadonly) return;
    const rows = (this.getRowIds(group)).filter((x) => x !== rowId);

    this.updateAnswer.emit({ value: rows, field: ListComponent.getRowsName(group), isDictionary: false });
  }

  canUpdate(element: Element) {
    const access = this.templateService.getAccess(element);

    return access === 'Update';
  }

  setRowAnswer(value: AnswerType, rowId: string, field: Field | string) {
    if (typeof field !== 'string' && this.isDictionary(field.items)) {
      this.updateAnswer.emit({ value, field: ListComponent.getRowFieldName(rowId, field), isDictionary: true});
    } else {
      this.updateAnswer.emit({ value, field: ListComponent.getRowFieldName(rowId, field), isDictionary: false });
    }
  }

  getRowChoice(rowId: string, field: Field) {
    if(field.dataType !== "choice") throw new Error("The field is not of type choice.");

    return this.getRowAnswer(rowId, field) as string[] | null ?? [];
  }

  getRowBoolean(rowId: string, field: Field) {
    if(field.dataType !== "boolean") throw new Error("The field is not of type boolean.");

    return this.getRowAnswer(rowId, field) as boolean | null;
  }

  getRowDate(rowId: string, field: Field) {
    if(field.dataType !== "date") throw new Error("The field is not of type date.");

    return this.getRowAnswer(rowId, field) as Date | null;
  }

  getRowAnswer(rowId: string, field: Field): AnswerType {
    const result = this.getAnswer(ListComponent.getRowFieldName(rowId, field));

    if (field.dataType === 'date' && typeof(result) === 'string') return new Date(result);

    return result;
  }

  // eslint-disable-next-line class-methods-use-this -- Angular template bindings require methods to be instance methods
  getDefaultDate(field: Field): Date | null {
    if(field.dataType !== "date") throw new Error("The field is not of type date.");

    return field.default as Date | null;
  }

  getRowIds(group: Group): string[] {
    return (this.getAnswer(ListComponent.getRowsName(group)) as string[] | null) ?? [];
  }

  private static getRowFieldName(rowId: string, field: Field | string): string {
    const fieldName = typeof field === 'string' ? field : field.name;

    return `${fieldName}[${rowId}]`;
  }

  private static getRowsName(group: Group): string {
    return `${group.name  }.rows`;
  }

  getAnswer(field: string): AnswerType {
    return this.answers.get(field) ?? null;
  }

  getPrefix(field: Field): string {
    if (field.dataType !== 'number') return '';

    if (field.type === 'currency') return '$';

    if (field.type === 'currencyOrPercentage' && !this.isPercentage(field)) return '$';

    return '';
  }

  getSuffix(field: Field): string {
    if (field.dataType !== 'number') return '';

    if (field.type === 'percentage') return '%';

    if (field.type === 'currencyOrPercentage' && this.isPercentage(field)) return '%';

    return '';
  }

  // eslint-disable-next-line class-methods-use-this -- Angular template bindings require methods to be instance methods
  setValidator(): string {
    return "";
  }

  private isPercentage(field: Field): boolean {
    if (field.dataType !== 'number' || field.type !== 'currencyOrPercentage')
      {throw Error('Only a currencyOrPercentage number field can be a percentage or currency.');}

    return (this.getAnswer(`${field.name  }IsPercentage`) as boolean | null) ?? false;
  }

  // eslint-disable-next-line class-methods-use-this
  isArray(items: ItemsType): items is string[] {
    return Array.isArray(items);
  }

  // eslint-disable-next-line class-methods-use-this
  isDictionary(items: ItemsType): items is Record<string, string> {
    return items instanceof Object && items.constructor === Object
  }
}

type ItemsType = string[] | Record<string, string>;

export function getInputType(field: Field): string {
  switch (field.dataType) {
    case 'number':
      return 'number';
    case 'boolean':
      throw Error('Checkbox is not supported as binding the input type seemed to break data binding.');
    case 'date':
      return 'date';
    default:
      return 'text';
  }
}
