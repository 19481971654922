import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IconName, IconNames } from './icon-name';
import { DOCUMENT } from '@angular/common';

const themeColors = [
  'primary',
  'secondary',
  'backdrop',
  'detail',
  'hint',
  'light',
  'dark',
  'frame',
  'support',
  'danger',
  'success'
];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mw-icon',
  templateUrl: './mw-icon.component.html',
  styleUrl: './mw-icon.component.scss'
})
export class MwIconComponent {
  @Input() name: IconName;
  @Input() size = '1rem';
  @Input() color = '#1a315a';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  get colorValue(): string {
    if (themeColors.includes(this.color)) {
      const color = window
        .getComputedStyle(this.document.body.parentElement as never)
        .getPropertyValue(`--${this.color}-color`) as never;

      return color;
    }

    return this.color;
  }

  get isIconName(): boolean {
    return IconNames.includes(this.name);
  }
}
