import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MwToastService } from "../shared/mw-toast/mw-toast.service";
import { Router } from '@angular/router';
import { Toast } from '../shared/mw-toast/toast';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetPasswordComponent implements OnInit {
  constructor(private router: Router, private httpClient: HttpClient, private mwToastService: MwToastService, private changeDetectorRef: ChangeDetectorRef) { }

  showCurrentPassword = false;
  showNewPassword1 = false;
  showNewPassword2 = false;

  newPasswordsNotMatch = false;
  userAlreadyHasPassword = false;
  wasRedirected = false;

  currentPassword: string;
  newPassword1: string;
  newPassword2: string;

  async ngOnInit() {
    const res = await firstValueFrom(this.httpClient.get<PasswordStatus>('api/password/passwordStatus'));
    this.userAlreadyHasPassword = res.hasPassword;
    this.wasRedirected = res.wasRedirected;

    this.changeDetectorRef.detectChanges();
  }
  
  togglePasswordVisibility(type: string) {
    if (type === "0") this.showCurrentPassword = !this.showCurrentPassword;
    if (type === "1") this.showNewPassword1 = !this.showNewPassword1;
    if (type === "2") this.showNewPassword2 = !this.showNewPassword2;
  }

  async setPassword() {
    if (this.validationResult !== PasswordValidationResult.Valid) return;
    if (this.newPasswordsNotMatch) return;

    const result = await firstValueFrom(
      this.httpClient.put('api/password', {
        newPassword: this.newPassword1,
        currentPassword: this.currentPassword
      }, { headers: { 'Content-Type': 'application/json' } }));

    const toast: Toast = {} as Toast;

    switch (result) {
      case SetPasswordResponse.Success:
        toast.type = "success";
        toast.message = "Your password was successfully set.";
        
        await this.router.navigate(['/enter-details']);
        break;
      case SetPasswordResponse.IncorrectCurrentPassword:
        toast.type = "error";
        toast.message = "The provided current password is incorrect.";
        break;
      case SetPasswordResponse.AccountLocked:
        toast.type = "error";
        toast.message = "The limit for incorrect current password attempts has been exceeded. Account is now locked.";

        await this.router.navigate(['/login']);
        break;
      default:
        break;
    }

    this.mwToastService.show(toast);
  }

  async validatePassword1() {
    this.newPasswordsNotMatch = this.newPassword1 !== this.newPassword2;

    if (!this.newPassword1) return;

    if (this.newPassword1.length < this.passwordMinLengthLimit) {
      this.validationResult = PasswordValidationResult.InvalidLength;
      return;
    } 

    this.validationResult = await firstValueFrom(this.httpClient.post<PasswordValidationResult>('api/validatePassword', `"${this.newPassword1}"`));
  
    this.changeDetectorRef.detectChanges();
  }

  validatePassword2() {
    this.newPasswordsNotMatch = this.newPassword1 !== this.newPassword2;

    this.changeDetectorRef.detectChanges();
  }

  validationResult: PasswordValidationResult;
  PasswordValidationResult = PasswordValidationResult;

  passwordMinLengthLimit = 8;
}

enum PasswordValidationResult {
  Valid,
  InvalidLength,
  InvalidPwned
}

enum SetPasswordResponse {
  Success,
  IncorrectCurrentPassword,
  AccountLocked
}

interface PasswordStatus {
  hasPassword: boolean;
  wasRedirected: boolean;
}
