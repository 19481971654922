export const IconNames = [
  'email',
  'bin',
  'user',
  'money',
  'piggy-bank',
  'earnings',
  'management',
  'chevron-down',
  'chevron-right',
  'life',
  'heart',
  'lifestyle',
  'tick',
  'adjustments',
  'help',
  'house-1',
  'house-2',
  'house-3',
  'energy-1',
  'energy-2',
  'energy-3',
  'food-1',
  'food-2',
  'food-3',
  'warning'
] as const;

export type IconName = (typeof IconNames)[number];
