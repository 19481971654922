/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";


// eslint-disable-next-line @angular-eslint/prefer-standalone, @angular-eslint/prefer-standalone-component
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mw-table',
    templateUrl: './mw-table.component.html',
    styleUrl: './mw-table.component.scss',
})
export class MwTableComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any[] = [];
    @Input() columns: { key: string[]; title: string, icon?: boolean; composite?: boolean; bold?: boolean}[] = [];

    selectedRowIndex: number;

    constructor(private cdr: ChangeDetectorRef) {
    }

    selectRow(factFindID: number): void {
        this.selectedRowIndex = factFindID;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getDisplayValue(item: any, column: any): string {
        if (column.composite) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return column.key.map((key: string | number) => item[key]).join(' ');
        }

        return item[column.key[0]];
    }

    getStyle(column: { key: string[]; title: string, icon?: boolean; composite?: boolean; bold?: boolean }) {
        return {
            'font-weight': column.bold ? 'bold' : 'normal'
        };
    }
}
