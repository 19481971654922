/* eslint-disable @angular-eslint/component-max-inline-declarations */
/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Owner } from '../manage-owner.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'owner-update-dialog',
  template: `
    <div>
      <label class="form-input-label">External Reference</label>
      <input class="form-input-text" type="text" [(ngModel)]="data.externalReference" />
    </div>
    <div>
      <label class="form-input-label">Assign API Key</label>
      <input class="form-input-text" type="checkbox" [(ngModel)]="data.hasExternalSystem" />
    </div>
  `
})
export class OwnerUpdateDialogComponent {
  @Input() data: Owner;
}

