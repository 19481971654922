<kendo-pdf-export #pdf paperSize="A4" keepTogether=".kendo-pdf-keep-together">
  <div class="app-body" [ngClass]="{ 'bg-cover': isLogin }">
    <div class="app-container">
      <ng-container *ngIf="brandingService.isInitialized">
        <header *ngIf="showHeader || showHeader === null ? true : false">
          <div [class.center]="!authenticatedUserService.isAuthenticated">
            <img src="./api/branding/{{ brandingService.brandingId }}/images/{{ logoPath }}" routerLink="/" />
          </div>
          <mw-mainmenu
            class="kendo-pdf-ignore"
            (generatePdfCLicked)="generatePDF()"
            *ngIf="authenticatedUserService.isAuthenticated"
            [enterDetailsRef]="enterDetailsRef"
          ></mw-mainmenu>
        </header>
        <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate()"></router-outlet>
        <footer (click)="footerClickedHandler()">
          <ng-content select="[copyrightNotice]"></ng-content>
        </footer>
      </ng-container>
    </div>
  </div>
</kendo-pdf-export>
<mw-toast></mw-toast>
<app-mw-idletimer #idleCountdown></app-mw-idletimer>
