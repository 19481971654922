import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AnswerType } from '../../types/answer.type';
import { AppService } from 'src/app/app.service';
import { ChatService } from '../chat.services';
import { Page } from '../Page';
import { Section } from '../Section';
import { TemplateService } from '../template.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mw-navigationmenu',
  templateUrl: './navigationmenu.component.html',
  styleUrl: './navigationmenu.component.scss'
})
export class NavigationMenuComponent {
  constructor(
    public templateService: TemplateService,
    public appService: AppService,
    public chatService: ChatService
  ) {}

  @Input()
  answers: Map<string, AnswerType>;

  @Input()
  selectedSection: Section | null;

  @Output()
  readonly sectionOnChange = new EventEmitter<Section>();

  @Input()
  selectedPage: Page | null = null;

  @Output()
  readonly pageOnChange = new EventEmitter<Page>();

  readonly desktopWindowWidthInPixels = 992;

  isDesktopSize(): boolean {
    return window.innerWidth >= this.desktopWindowWidthInPixels;
  }

  closeOnClick() {
    this.appService.isEnterDetailMenuDisplay = false;
  }

  sectionOnClick(section: Section) {
    if (this.selectedSection === section) return;
    this.selectedSection = section;
    // this.sectionOnChange.emit(section);
  }

  pageOnClick(page: Page) {
    if (this.selectedPage !== page) {
      this.selectedPage = page;
      this.pageOnChange.emit(page);
    }

    if (!this.isDesktopSize()) this.closeOnClick();
  }
}
