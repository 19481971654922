/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { MwDialogService } from 'src/app/shared/mw-dialog/mw-dialog.service';
import { Owner } from '../manage-owner.component';
import { OwnerTypes } from 'src/app/enums/dbo.OwnerType';
import { OwnerUpdateDialogComponent } from './owner-update-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'owner-form',
  templateUrl: './owner-form.component.html',
  styleUrl: './owner-form.component.scss',
})
export class OwnerFormComponent {
  @Input() title: string;
  @Input() options: Owner[] = [];
  @Input() placeholder: string;
  @Input() parentId: number;
  @Input() typeId: OwnerTypes;
  @Output() readonly selectionChange = new EventEmitter();
  @Output() readonly add = new EventEmitter<Owner>();
  @Output() readonly save = new EventEmitter<Owner>();

  constructor(private mwDialogService: MwDialogService,
  ) {}

  @Input() selectedValue: number | null = null;
  ownerNameInput = "";
  showInput = false;

  toggleInput() {
    this.showInput = !this.showInput;
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value;
    const numericValue = Number(value);
    this.selectedValue = isNaN(numericValue) ? null : numericValue;

    this.selectionChange.emit(this.selectedValue);
  }

  toggleEdit() {
    if (!this.selectedValue) throw new Error('Must have a selected owner.');
    
    const selectedOption = this.options.find(x => x.id === this.selectedValue);
  
    if (!selectedOption) throw new Error('Selected owner not found.');
  
    this.openDialog(selectedOption);
  }

  openDialog(selectedOption: Owner) {
    const prevExternal = selectedOption.externalReference;
    const ref = this.mwDialogService.open<Owner>({
      title: `Edit ${selectedOption.name}`,
      component: OwnerUpdateDialogComponent,
      data: selectedOption,
    });

    ref.closed.subscribe((event) => {
      if (event?.type === "save") {
        if (prevExternal !== null && selectedOption.externalReference !== prevExternal && selectedOption.hasExternalSystem) {
          void (async () => {
            const hasConfirmed = await this.mwDialogService.confirm("This owner has an existing api key in use, updating the external reference will override the ownernode value in this API Key. Are you sure you want to proceed?");
            if (hasConfirmed) {
              this.save.emit(selectedOption);
            } else {
              selectedOption.externalReference = prevExternal;
              this.openDialog(selectedOption);
            }
          })();
        } else {
          this.save.emit(selectedOption);
        }
      } else {
        selectedOption.externalReference = prevExternal;
      }
    });
  }

  toggleAdd() {
    if (!this.ownerNameInput || this.ownerNameInput.trim() === '') {
      throw new Error('Owner name is required.');
    }

    const owner: Owner = {
      id: null,
      name: this.ownerNameInput.trim(),
      parentId: this.parentId,
      typeId: this.typeId as number,
      externalReference: null,
      hasExternalSystem: false
    };
    this.showInput = false;
    this.add.emit(owner);
  }
}