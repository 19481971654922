<svg
  (click)="onClick()"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  style="enable-background: new 0 0 18 18"
  xml:space="preserve"
>
  <path
    d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M9.9,14c-0.2,0.2-0.5,0.3-0.8,0.3S8.5,14.2,8.3,14
        C8.1,13.8,8,13.6,8,13.3c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7
        C10.2,13.6,10.1,13.8,9.9,14z M12.2,7.4c-0.2,0.3-0.4,0.7-0.7,0.9C11.3,8.7,11,9,10.7,9.3c-0.3,0.2-0.5,0.5-0.6,0.8s-0.1,0.6-0.2,1
        H8.2c0-0.4,0-0.8,0.1-1.1C8.4,9.6,8.5,9.3,8.7,9C8.8,8.8,9,8.5,9.3,8.3C9.6,8,9.8,7.9,9.9,7.7c0.2-0.2,0.3-0.4,0.4-0.6
        c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.4-0.5C9.8,5.1,9.5,5,9.3,5C9,5,8.8,5,8.6,5.1S8.3,5.4,8.1,5.6
        C8,5.8,8,6,7.9,6.3H6c0-0.6,0.2-1.2,0.5-1.6c0.3-0.4,0.7-0.7,1.2-1c0.5-0.2,1-0.3,1.6-0.3c0.7,0,1.2,0.1,1.7,0.3
        c0.5,0.2,0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5C12.5,6.7,12.4,7.1,12.2,7.4z"
  />
</svg>

<div *ngIf="isDisplay">
  {{ info }}
  <div class="bottom-arrow"></div>
</div>
