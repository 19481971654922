/* eslint-disable @angular-eslint/no-async-lifecycle-method */
/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable max-params */
/* eslint-disable @angular-eslint/prefer-standalone */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom, switchMap } from 'rxjs';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { ClientInfo } from './ClientInfo';
import { ClientSearchResult } from './ClientSearchResult';
import { FactFindService } from '../enter-details/factFind.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { Owner as OwnerAlias } from './Owner';
import { Permissions } from '../enums/dbo.Permission';
import { Response as ResponseAlias } from './Response';
import { Router } from '@angular/router';
import { Template } from '../template';
import { v4 as uuid } from 'uuid';


@Component({
  selector: 'create-fact-find',
  templateUrl: './create-fact-find.component.html',
  styleUrl: './create-fact-find.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFactFindComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private httpClient: HttpClient,
    private authenticatedUserService: AuthenticatedUserService,
    private changeDetectorRef: ChangeDetectorRef,
    private factFindService: FactFindService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.owners = (await firstValueFrom(this.httpClient.get('api/owners', { withCredentials: true }))) as OwnerAlias[];
    this.owner = this.owners.find((x) => x.id === this.authenticatedUserService.ownerId) ?? null;
    this.templates = await firstValueFrom(this.httpClient.get<Template[]>('/api/templates'));
    this.hasTestingFeaturesPermission = this.authenticatedUserService.hasOwnerPermission(Permissions.TestingFeatures);

    this.template = this.templates[0];

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  templates: Template[];
  template: Template;
  clients: ClientInfo[] | null = null;
  isLoadingClientsDisabled = false;
  hasTestingFeaturesPermission = false;

  searchSubject = new Subject<string>();

  private readonly dueTimeInMilliseconds = 300;

  searchSubscription = this.searchSubject
    .pipe(
      distinctUntilChanged(),
      debounceTime(this.dueTimeInMilliseconds),
      switchMap((searchQuery) => this.search(searchQuery))
    )
    .subscribe({
      next: (results) => {
        this.searchResults = results;
        this.changeDetectorRef.detectChanges();
        void this.spinner.hide()
      },
      error: () => {
        void this.spinner.hide();
      },
    });

  searchResults: ClientSearchResult[] | null = null;
  selectedSearchResult: ClientSearchResult | null = null;

  get isValid(): boolean {
    return (
      Boolean(this.firstName) &&
      Boolean(this.lastName) &&
      Boolean(this.emailAddress) &&
      Boolean(this.mobileNumber) &&
      Boolean(this.owner)
    );
  }

  async create() {
    if (!this.isValid) throw Error('The parameters are not valid.');

    const observable = this.httpClient.post<ResponseAlias>('/api/factfinds', {
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      mobileNumber: this.mobileNumber,
      birthdate: this.selectedSearchResult?.birthdate,
      suburb: this.selectedSearchResult?.suburb,
      state: this.selectedSearchResult?.state,
      externalReference: this.selectedSearchResult?.externalReference,
      templateId: this.template.id,
      templateVersion: this.template.version,
      ownerId: this.owner?.id,
      adviserNode: this.selectedSearchResult?.adviserNode
    });

    const response = await firstValueFrom(observable);

    this.clientUserId = response.clientUserId;
    this.factFindId = response.factFindId;

    await this.factFindService.initialize(this.factFindId);

    this.changeDetectorRef.detectChanges();

    if (!this.hasTestingFeaturesPermission) {
      await this.router.navigate(['/enter-details']);
    }
  }

  async logOutAndAuthenticateAsClient() {
    await this.authenticatedUserService.logOut();

    await this.router.navigate(['authenticate'], { queryParams: { userId: this.clientUserId } });
  }

  clientUserId: number | null = null;
  factFindId: number | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  emailAddress?: string | null = null;
  mobileNumber?: string | null = null;
  owner: OwnerAlias | null = null;
  owners: OwnerAlias[];
  isCreateOrSearch = true;
  isViewAll = false;

  generateUniqueDetails() {
    this.generateFirstName();
    this.generateLastName();
    this.generateEmailAddress();
    this.generateMobileNumber();
  }

  generateMobileNumber() {
    const maximumLengthOfMobileNumber = 20;

    const indexOfStart = 0;

    this.mobileNumber = uuid().replace('-', '').substring(indexOfStart, maximumLengthOfMobileNumber);
  }

  generateEmailAddress() {
    this.emailAddress = `${uuid()}@test.com`;
  }

  generateLastName() {
    this.lastName = uuid();
  }

  generateFirstName() {
    this.firstName = uuid();
  }

  onSearchQueryInput(query: string | undefined): void {
    if (!query) return;

    //This is left over from when search was being triggered on each key up.
    //That did not seem to be working well and was confusing for the user, given that there is a button next to the search box.
    //Swapped to enter up and button click until we get final word on how this should all work.
    //If the enter up and button click should stay then we might be able to get rid of searchSubject.
    this.searchSubject.next(query.trim());
    this.isViewAll = false;
  }

  async search(query: string): Promise<ClientSearchResult[]> {
    await this.spinner.show();

    return (await firstValueFrom(
      this.httpClient.get(`api/clients/search?query=${encodeURIComponent(query)}`, { withCredentials: true })
    )) as ClientSearchResult[];
  }

  onSearchViewButton(index: number): void {
    if (this.searchResults === null || this.searchResults.length <= index) {
      return;
    }

    const result = this.searchResults[index];
    this.selectedSearchResult = result;
    this.isCreateOrSearch = false;
    this.firstName = result.firstName;
    this.lastName = result.lastName;
    this.emailAddress = result.email;
    this.mobileNumber = result.mobilePhone;
    this.changeDetectorRef.detectChanges();
  }

  onCreateCustomerButton(): void {
    this.isCreateOrSearch = false;
    this.isViewAll = false;
    this.searchResults = null;
    this.selectedSearchResult = null;

    // Clean up any auto filled details when creating a new customer
    this.firstName = '';
    this.lastName = '';
    this.emailAddress = '';
    this.mobileNumber = '';
    this.changeDetectorRef.detectChanges();
  }

  backToCreateOrSearch(): void {
    this.isCreateOrSearch = true;
  }

  async backToDashboardOnClick() {
    await this.router.navigate(['/']);
  }
}
