import { CommonModule } from '@angular/common';
import { MwTableComponent } from './mw-table.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    MwTableComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MwTableComponent
  ],
})
export class MwTableModule { }
