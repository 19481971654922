import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'grant-access',
  templateUrl: './grant-access.component.html',
  styleUrl: './grant-access.component.scss'
})
export class GrantAccessComponent {
  test = 'Success in Grant Access';
}
