export enum OwnerTypes {
    /** OwnerTypes.Midwinter = 1 */
    Midwinter = 1,

    /** OwnerTypes.Group = 2 */
    Group = 2,

    /** OwnerTypes.Licensee = 3 */
    Licensee = 3,

    /** OwnerTypes.Practice = 4 */
    Practice = 4,

    /** OwnerTypes.Office = 5 */
    Office = 5,

    /** OwnerTypes.User = 6 */
    User = 6,

}
