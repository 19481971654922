<div class="login-form-container">
  <div class="logo">
      <div class="login-logo"></div>
  </div>

  <h1>Welcome to<br>Dynamic Fact Find</h1>

  <form #loginForm="ngForm" (ngSubmit)="logIn()" class="login-form" *ngIf="activeForm === Form.LoginForm">
    <div class="ff-login-pair">
      <label for="username" class="form-input-label">Username</label>
      <input id="username"
        [(ngModel)]="userName"
        class="can-validate form-input-text ff-login"
        type="text"
        placeholder="Username"
        autocomplete="off"
        name="username"
        #username="ngModel"
        required />
      <p class="input-error-message" *ngIf="username.invalid && (username.dirty || username.touched)">
        <span class="warning-message"></span>Username cannot be empty.      </p>
    </div>

    <div class="ff-login-pair">
      <label for="password" class="form-input-label ff-login">Password</label>
      <!-- eye -->
      <div class="password-input">
        <input id="password"
          name="password"
          [(ngModel)]="password"
          placeholder="Password"
          class="can-validate form-input-text ff-login"
          [type]="showPassword ? 'text' : 'password'"
          autocomplete="off"
          required/>
        <span for="password-toggle">
          <div
            [ngClass]="[showPassword ? 'show-password' : 'hide-password']"
            (click)="togglePWvisibility()"
            class="eye"
          ></div>
        </span>
      </div>
      <p class="input-error-message"
        *ngIf="loginForm.controls['password']?.invalid && (loginForm.controls['password']?.dirty || loginForm.controls['password']?.touched)">
        <span class="warning-message"></span>Password cannot be empty.
      </p>

      <div *ngIf="isIncorrectCredentials" class="login-error" style="margin-top: 20px;">
        <mw-icon name="warning" size="32px" color="danger"></mw-icon>
        <div>
          Username or password is incorrect.
        </div>
      </div>
      <div *ngIf="isAccountLocked" class="login-error">
        <mw-icon name="warning" size="32px" color="danger"></mw-icon>
        <div>
          Too many incorrect username/password attempts.<br />Account is locked for 15 minutes.
        </div>
      </div>
    </div>

    <p class="input-error-message" *ngIf="sessionTimedOut">
      <span class="warning-message"></span>Your session was terminated due to inactivity.
    </p>

    <div class="button-actions">
      <button class="login-button ff-button ff-button--primary" type="submit">Login</button>
      <button class="login-button ff-button" routerLink="/register" routerLinkActive="active">Register</button>
    </div>
  </form>

  <!-- MFA Registry with 3 options menu -->
  <form class="login-form" *ngIf="activeForm === Form.MfaRegistryFormThreeOptions">
    <h2>Multi Factor Authentication</h2>
    Please select an MFA method for your account.
    <button class="ff-button ff-login-pair" (click)="chooseMfaOption(MfaTypes.Authenticator)">Authenticator</button>
    <button class="ff-button ff-login-pair" (click)="chooseMfaOption(MfaTypes.Email)">Email</button>
    <button class="ff-button ff-login-pair" (click)="chooseMfaOption(MfaTypes.Sms)">SMS</button>

    <button class="ff-button" (click)="activeForm = Form.LoginForm">Back</button>
  </form>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-pulse-sync"
               [disableAnimation]="false">
    <p style="font-size: 1.25rem; color: white">Your MFA method is being created.</p>
  </ngx-spinner>

  <!-- MFA Registry -->
  <form class="login-form" *ngIf="activeForm === Form.MfaRegistryOptionSelected">
    <div *ngIf="mfaSelectionError !== null">
      {{mfaSelectionError}}
    </div>

    <div *ngIf="mfaSelectionError === null">
      <div *ngIf="selectedMfaType === MfaTypes.Authenticator">
        Scan the QR code below with your Authenticator app and enter One Time Password.<br />
        <img src="{{label}}" />
      </div>

      <div *ngIf="selectedMfaType === MfaTypes.Email">
        To verify and save your MFA selection, please enter One Time Password which was sent to your email.
      </div>

      <div *ngIf="selectedMfaType === MfaTypes.Sms">
        To verify and save your MFA selection, please enter One Time Password which was sent to your phone number.
      </div>
      <br />
      <input [(ngModel)]="oneTimePassword"
             class="can-validate form-input-text ff-login"
             type="text"
             placeholder="One Time Password"
             name="oneTimePassword"
             required />

      <div *ngIf="isMfaVerificationFailed" class="login-error" style="margin-top: 20px;">
        <mw-icon name="warning" size="32px" color="danger"></mw-icon>
        <div>
          One Time Password is incorrect. MFA could not be verified.
        </div>
      </div>
    </div>
    <div class="button-actions">
      <button class="login-button ff-button ff-button--primary" (click)="verifyMfaSelection()">Login</button>
      <button class="ff-button" (click)="activeForm = Form.MfaRegistryFormThreeOptions">Back</button>
    </div>
  </form>

  <!-- MFA Login -->
  <form class="login-form" *ngIf="activeForm === Form.MfaLoginForm">
    One Time Password is sent to {{mfaMedium}}.<br />
    Please use that code to login.
    <input [(ngModel)]="oneTimePassword"
           class="can-validate form-input-text ff-login"
           type="text"
           placeholder="One Time Password"
           name="oneTimePassword"
           required />
    <div *ngIf="isIncorrectOTP" class="login-error" style="margin-top: 20px;">
      <mw-icon name="warning" size="32px" color="danger"></mw-icon>
      <div>
        One Time Password is incorrect.
      </div>
    </div>

    <div *ngIf="isMfaLockout" class="login-error" style="margin-top: 20px;">
      <mw-icon name="warning" size="32px" color="danger"></mw-icon>
      <div>
        Too many failed One Time Password attempt. Account is locked for 15 minutes.
      </div>
    </div>
    <div class="button-actions">
      <button class="login-button ff-button ff-button--primary" (click)="loginWithMfa()">Login</button>
      <button class="ff-button" (click)="activeForm = Form.LoginForm">Back</button>
    </div>
  </form>
</div>
