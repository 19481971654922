export enum MfaTypes {
    /** MfaTypes.Authenticator = 1 */
    Authenticator = 1,

    /** MfaTypes.Email = 2 */
    Email = 2,

    /** MfaTypes.Sms = 3 */
    Sms = 3,

}
