/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseEvent, DialogSettings } from './dialog-types-and-interfaces';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { MwDialogComponent } from './mw-dialog/mw-dialog.component';
import { MwPromptComponent } from './mw-prompt/mw-prompt.component';

@Injectable({
  providedIn: 'root'
})
export class MwDialogService {
  constructor(private dialog: Dialog) {}

  confirm(message: string, saveButtonText = 'OK'): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const dialogRef = this.dialog.open<CloseEvent<boolean>, boolean, MwDialogComponent<boolean>>(MwDialogComponent);
      if (dialogRef.componentInstance) {
        dialogRef.componentInstance.title = message;
        dialogRef.componentInstance.saveButtonText = saveButtonText;
      }

      dialogRef.closed.subscribe((event) => {
        resolve(event?.type === 'save');
      });
    });
  }

  prompt(message: string, defaultMessage = '', saveButtonText = 'OK'): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
      const dialogRef = this.dialog.open<CloseEvent<Prompt>, Prompt, MwDialogComponent<Prompt>>(MwDialogComponent, {
        data: {
          text: defaultMessage
        }
      });

      if (dialogRef.componentInstance) {
        dialogRef.componentInstance.title = message;
        dialogRef.componentInstance.saveButtonText = saveButtonText;
        dialogRef.componentInstance.component = MwPromptComponent;

        dialogRef.closed.subscribe((result) => {
          if (!result || result.type !== 'save') {
            resolve(null);
            return;
          }

          resolve(result.data?.text ?? null);
        });
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this, max-statements
  open<T>(settings: DialogSettings<T>): DialogRef<CloseEvent<T>, MwDialogComponent<T>> {
    const dialogRef = this.dialog.open<CloseEvent<T>, any, MwDialogComponent<T>>(MwDialogComponent, {
      data: settings.data
    });
    if (!dialogRef.componentInstance) {
      throw new Error('The dialog component instance is not available.');
    }

    dialogRef.componentInstance.title = settings.title;
    if (settings.saveButtonText) dialogRef.componentInstance.saveButtonText = settings.saveButtonText;
    dialogRef.componentInstance.component = settings.component;
    dialogRef.componentInstance.extraData = settings.extraData;
    settings.cancelButtonVisible !== undefined &&
      (dialogRef.componentInstance.cancelButtonVisible = settings.cancelButtonVisible);

    settings.headerVisible !== undefined && (dialogRef.componentInstance.headerVisible = settings.headerVisible);

    if (settings.maxWidth) dialogRef.componentInstance.maxWidth = settings.maxWidth;

    return dialogRef;
  }
}

interface Prompt {
  text: string;
}
