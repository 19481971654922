<ng-container>
  <div class="image" [style.width.px]="size * 262" [style.height.px]="size * 262">
    <div class="blue-square" [style.width.px]="size * 26.552" [style.height.px]="size * 26.552"></div>
    <div class="navy-square" [style.width.px]="size * 8.93" [style.height.px]="size * 8.93"></div>
    <div class="four-square" [style.width.px]="size * 14" [style.height.px]="size * 14" [style.border]="(4 * size) + 'px dotted var(--Brand-Black, #0E0E1A)'"></div>
    <div class="blue-circle" [style.width.px]="size * 7" [style.height.px]="size * 7" [style.border-radius.px]="size * 50"></div>
    <div class="navy-circle" [style.width.px]="size * 14" [style.height.px]="size * 14" [style.border-radius.px]="size * 50"></div>
    <div class="outer-circle" [style.width.px]="size * 262" [style.height.px]="size * 262" [style.border-radius.px]="size * 200">
      <div class="middle-circle" [style.width.px]="size * 200" [style.height.px]="size * 200" [style.border-radius.px]="size * 200">
        <div class="inner-circle" [style.width.px]="size * 110" [style.height.px]="size * 110" [style.border-radius.px]="size * 200">
          <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="56*size" [attr.height]="41*size" viewBox="0 0 56 41" fill="none">
            <path d="M2.40723 21.3527L20.3954 37.659L53.593 2.34082" stroke="#FFFEFE" stroke-width="4" stroke-linecap="round" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-container>
