import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NEVER, Observable, catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router){}
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let localRequest = request

    if (!localRequest.headers.has('Content-Type')) {
      //https://angular.io/guide/http#setting-default-headers
      localRequest = localRequest.clone({ setHeaders: { 'Content-Type': 'application/json' } });
    }

    if (!localRequest.headers.has('Accept')) {
      //https://angular.io/guide/http#setting-default-headers
      localRequest = localRequest.clone({ setHeaders: { Accept: 'application/json' } });
    }

    return next.handle(localRequest).pipe(catchError((err: {status: number}) => {
      const unauthorizedStatus = 401;
      const forbiddenStatus = 403;
      if ([unauthorizedStatus, forbiddenStatus].includes(err.status) && !(this.router.url === "/login")) {
          void this.router.navigate(["login"]);

          return NEVER;
      }

      return throwError(() => err);
  }))
  }
}
