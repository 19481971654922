import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RegisterComponent } from './register/register.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'authenticate', loadChildren: () => import('./authenticate/authenticate.module').then((x) => x.AuthenticateModule) },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: '', loadChildren: () => import('./private/private.module').then((x) => x.PrivateModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
