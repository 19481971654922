/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BooleanGroupService } from './boolean-group.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush, selector: 'mw-boolean',
  templateUrl: './boolean.component.html',
  styleUrl: './boolean.component.scss'
})
export class BooleanComponent implements OnInit, OnDestroy{
  @Input()
  name: string;

  @Input()
  value: boolean | null;

  @Input()
  label: string;

  @Input()
  hasLabel = true;

  @Input()
  isLabelHtml: boolean | undefined = true;

  @Input()
  isReadonly: boolean | undefined = false;

  @Input()
  group: string | number;

  @Output()
  readonly valueChange = new EventEmitter();

  constructor(private booleanGroupService: BooleanGroupService) {}

  ngOnInit() {
    if (this.group) {
      this.booleanGroupService.register(this.group, this);
    }
  }

  ngOnDestroy() {
    if (this.group) {
      this.booleanGroupService.unregister(this.group, this);
    }
  }

  onClick = () => {
    if (this.isReadonly) return;
    this.value = !this.value;
    this.valueChange.emit(this.value);

    if (this.group) {
      this.booleanGroupService.updateGroup(this.group, this);
    }
  };
}
