import { AuthenticatedUserService } from '../authenticated-user.service';
import { Chat } from './chat';
import { FactFindService } from './factFind.service';
import { Group } from './Group';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from './Page';
import { Section } from './Section';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ChatService {
  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private factFindService: FactFindService,
    private httpClient: HttpClient
  ) {
    this.factFindService.factFind$.subscribe((x) => {
      if (!x) return;

      void this.loadUnreadChats(x.id);
    });
  }

  chats: Chat[] = [];
  element: string | null = null;

  public async load(group: Group) {
    const respones = (await firstValueFrom(
      this.httpClient.get(`api/factfinds/${this.factFindService.factFindId}/${group.name}/chats`)
    )) as { userId: number; created: string; value: string }[];

    this.chats = respones.map((x) => new Chat(x.userId, new Date(x.created), x.value));

    this.element = group.name;
  }

  elementsWithUnreadChats: string[] = [];

  async loadUnreadChats(factFindId: number) {
    this.elementsWithUnreadChats = await firstValueFrom(
      this.httpClient.get<string[]>(`api/factfinds/${factFindId}/chats/unread`)
    );
  }

  async add(value: string, group: Group) {
    if (this.authenticatedUserService.userId === null) throw Error('User is not authenticated.');

    this.chats.push(new Chat(this.authenticatedUserService.userId, new Date(), value));

    await firstValueFrom(
      this.httpClient.post(
        `api/factfinds/${this.factFindService.factFindId}/${group.name}/chats`,
        JSON.stringify(value)
      )
    );
  }

  public reset() {
    this.element = null;
    this.chats = [];
  }

  private doesGroupHaveUnreadChats(group: Group) {
    return this.elementsWithUnreadChats.includes(group.name);
  }

  doesPageHaveUnreadChats(page: Page) {
    if(!page.groups) return false;

    for (const group of page.groups) {
      if (this.doesGroupHaveUnreadChats(group)) return true;
    }

    return false;
  }

  doesSectionHaveUnreadChats(section: Section) {
    for (const page of section.pages) {
      if (this.doesPageHaveUnreadChats(page)) return true;
    }

    return false;
  }
}
