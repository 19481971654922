/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { CloseEvent, CloseType } from '../dialog-types-and-interfaces';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mw-dialog',
  templateUrl: './mw-dialog.component.html',
  styleUrl: './mw-dialog.component.scss'
})
export class MwDialogComponent<T> implements OnInit {
  @Input() title: string;
  @Input() saveButtonText = 'Save';
  cancelButtonText = 'Cancel';
  @Input() component: Type<unknown>;
  @Input() extraData: any;
  @Input() cancelButtonVisible = true;
  @Input() maxWidth = '40rem';
  @Input() headerVisible = true;
  inputs: any;

  constructor(
    private dialogRef: DialogRef<CloseEvent<T>>,
    @Inject(DIALOG_DATA) public data: any,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.inputs = {
      data: this.data,
      ...this.extraData
    };
  }

  close(type: CloseType) {
    this.dialogRef.close({
      type,
      data: null
    });
  }

  save() {
    this.dialogRef.close({
      type: 'save',
      data: this.data
    });
  }
}
