/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
/* eslint-disable @angular-eslint/no-async-lifecycle-method */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MwToastService } from "../shared/mw-toast/mw-toast.service";
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateUserComponent implements OnInit {
  constructor(private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private mwToastService: MwToastService,
  ) { }

  name: string;
  emailAddress: string;
  mobileNumber: number;
  password: string;
  parentOwner: Owner;
  owners: Owner[];

  async ngOnInit(): Promise<void> {
    this.owners = (await firstValueFrom(this.httpClient.get('api/hierarchyOwners', { withCredentials: true }))) as Owner[];

    this.changeDetectorRef.detectChanges();
  }

  async createUser() {
    await firstValueFrom(
      this.httpClient.post('api/users', {
        name: this.name,
        emailAddress: this.emailAddress,
        mobileNumber: this.mobileNumber.toString(),
        password: this.password,
        parentOwnerId: this.parentOwner.id
      })
    );

    this.mwToastService.show({
      type: "success",
      message: "User successfully created."
    });
  }
}

interface Owner {
  id: number;
  name: string;
  type: string;
}
