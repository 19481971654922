import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { EnterDetailsComponent } from "../enter-details/enter-details.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'private',
  templateUrl: './private.component.html'
})
export class PrivateComponent {
  enterDetailsRefInitialized = new EventEmitter<EnterDetailsComponent>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActivate(event: any) {
    if (!(event instanceof EnterDetailsComponent)) return;
    this.enterDetailsRefInitialized.emit(event);
  }
}
