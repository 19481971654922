
<div class="create-user-container">
  <div class="create-user-form-group">
    <br />
    <div class="create-user__pair">
      <label class="form-input-label" for="name">Name:</label>
      <input class="form-input-text w100" id="name" type="email" [(ngModel)]="name" />
    </div>
  
    <br />
    <div class="create-user__pair">
      <label class="form-input-label" for="email">Email Address:</label>
      <input class="form-input-text w100" id="email" type="email" [(ngModel)]="emailAddress" />
    </div>
  
    <br />
    <div class="create-user__pair">
      <label class="form-input-label" for="mobile">Mobile Number:</label>
      <input class="form-input-text w100" id="mobile" type="number" [(ngModel)]="mobileNumber" />
    </div>
  
    <br />
    <div class="create-user__pair">
      <label class="form-input-label" for="password">Password:</label>
      <input class="form-input-text w100" id="password" type="password" [(ngModel)]="password" />
    </div>
  
    <br />
    <div class="create-user__pair">
      <label class="form-input-label" for="parent-owner">Parent Owner:</label>
      <div class="ff-select">
        <select id="parent-owner" [(ngModel)]="parentOwner">
          <option *ngFor="let owner of owners" [ngValue]="owner">{{ owner.name }} ({{ owner.type }})</option>
        </select>
      </div>
    </div>
  </div>
  <br />
  
  <div class="cc-action-buttons">
    <button class="ff-button ff-button--primary" (click)="createUser()">Create User</button>
  </div>
</div>
