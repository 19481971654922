<div class="modal" *ngIf="isTimerEnabled">
  <div class="modal-overlay"></div>
  <div class="modal-box">
    <div class="modal-header">Session Timeout</div>
    <hr />
    <div class="modal-content">
      You are being timed out due to inactivity.
      <br />
      <br />
      <div style="padding-bottom: 2rem;">
        <span>Left: </span>
        <span style="color:red; font-size:x-large; font-weight:bolder" class="modal-content">{{countdown}}</span>
        <span> seconds</span>
      </div>
    </div>
  </div>
</div>
