/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { firstValueFrom, fromEvent } from 'rxjs';
import { AdviserInfo } from './AdviserInfo';
import { AnswerType } from '../types/answer.type';
import { AppService } from '../app.service';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { BrandingService } from '../branding.service';
import { ChatService } from './chat.services';
import { Element } from './Element';
import { FactFind } from './FactFind';
import { FactFindService } from './factFind.service';
import { FactFindStatuses } from '../enums/dbo.FactFindStatus';
import { Field } from './Field';
import { Group } from './Group';
import { HttpClient } from '@angular/common/http';
import { MwDialogService } from "../shared/mw-dialog/mw-dialog.service";
import { MwToastService } from "../shared/mw-toast/mw-toast.service";
import { NoteService } from './note.service';
import { Page } from './Page';
import { Section } from './Section';
import { TemplateService } from './template.service';
import { debounceTime } from 'rxjs/operators';
import { getInputType } from './list/list.component';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'enter-details',
  templateUrl: './enter-details.component.html',
  styleUrl: './enter-details.component.scss',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ top: '70px', opacity: 0 }),
        animate('140ms cubic-bezier(0.17, 0.85, 0.83, 1.05)', style({ top: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ top: 0, opacity: 1 }),
        animate('140ms cubic-bezier(0.17, 0.85, 0.83, 1.05)', style({ top: '70px', opacity: 0 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NoteService, ChatService]
})
export class EnterDetailsComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line max-params
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    public brandingService: BrandingService,
    public authenticatedUserService: AuthenticatedUserService,
    private factFindService: FactFindService,
    private noteService: NoteService,
    public templateService: TemplateService,
    public chatService: ChatService,
    public appService: AppService,
    private mwToastService: MwToastService,
    private mwDialogService: MwDialogService,
    private viewContainerRef: ViewContainerRef,
  ) {
    fromEvent(window, 'resize')
      .pipe(debounceTime(this.debounceResizeTime))
      // eslint-disable-next-line no-console
      .subscribe(() => { this.onResizeWindow(); this.changeDetectorRef.detectChanges(); });
  }

  readonly textAreaCutoffLength = 30;
  public getInputType = getInputType;
  readonly desktopWindowWidthInPixels = 992;
  readonly debounceResizeTime = 200;
  readonly privacyPolicy = "privacyPolicy";
  readonly electronicConsent = "electronicConsent";
  isReadOnlyFactFind = false;

  // eslint-disable-next-line @angular-eslint/no-async-lifecycle-method
  async ngOnInit(): Promise<void> {
    this.templateService.changes$.subscribe(() => { this.changeDetectorRef.detectChanges(); });

    this.appService.isEnterDetail = true;

    if (this.isDesktopSize()) {
      this.isNavigationVisible = true;
      this.appService.isEnterDetailMenuDisplay = true;
    } else {
      this.appService.isEnterDetailMenuDisplay$.subscribe(() => { this.changeDetectorRef.detectChanges(); });
    }

    this.brandingService.branding$.subscribe(() => { this.changeDetectorRef.detectChanges(); });

    await this.factFindService.loading;

    if (!this.factFindService.factFinds.length) {
      this.mwToastService.show({
        type: "info",
        message: 'There are no existing fact finds. Create one instead.'
      });

      await this.router.navigate(['create-fact-find']);

      return;
    }

    const factFindIdString = this.route.snapshot.queryParamMap.get('factFindId');

    let factFind: FactFind | null = null;

    if (factFindIdString) {
      const factFindId = parseInt(factFindIdString);

      factFind = this.factFindService.factFinds.find((x) => x.id === factFindId) ?? null;
    }

    if (!factFind) {
      if (!this.factFindService.factFind) throw Error("There is no Fact Find available.");

      factFind = this.factFindService.factFind;
    }

    await this.setFactFind(factFind);

    this.isReadOnlyFactFind = this.factFind === null || this.factFind.statusId === FactFindStatuses.Published || this.factFind.statusId === FactFindStatuses.Submitted;

    if (this.authenticatedUserService.isAdviser) {
      this.canUpdateAdviser = !this.shouldShowAdviserToggle();
      await this.getAdvisers();
    }

    this.changeDetectorRef.detectChanges();
  }

  isDesktopSize() {
    return window.innerWidth >= this.desktopWindowWidthInPixels;
  }

  onResizeWindow() {
    if ( !this.appService.isEnterDetailMenuDisplay && this.isDesktopSize()) {
      this.appService.isEnterDetailMenuDisplay = true;
    } else if (!this.isDesktopSize()) {
      this.appService.isEnterDetailMenuDisplay = false;
    }
  }

  ngOnDestroy(): void {
    this.appService.isEnterDetail = false;
  }

  get factFind(): FactFind | null {
    return this.factFindService.factFind;
  }

  set factFind(value: FactFind | null) {
    this.factFindService.factFind = value;
  }

  selectedPage: Page | null = null;

  get selectedSection(): Section | null {
    return this.sections.find((x) => x.pages.some((page) => page === this.selectedPage)) ?? null;
  }

  set selectedSection(value: Section | null) {
    this.selectedPage = value?.pages[0] ?? null;
  }

  get sections(): Section[] {
    const selectedPage = this.selectedPage;

    if (!selectedPage) return this.templateService.template.sections;

    const selectedSection = this.templateService.template.sections.find((x) => x.pages.includes(selectedPage));

    if (!selectedSection) throw Error('Selected section could not be determined.');

    return [selectedSection];
  }

  async setFactFind(factFind: FactFind) {
    this.selectedPage = null;

    this.factFindService.factFind = factFind;

    await this.templateService.loadTemplate(factFind);

    await this.loadAnswers();

    await this.saveAccessData();

    this.changeDetectorRef.detectChanges();
  }

  private async loadAnswers(shouldNavigateToFirstPage = true) {
    if (!this.factFind) throw Error('There is no fact find selected.');

    const answers = (await firstValueFrom(this.httpClient.get(`/api/factfinds/${this.factFind.id}/answers`))) as [
      string,
      AnswerType
    ][];

    for (const field of this.templateService.getFields().filter((x) => x.dataType === 'date')) {
      const answer = answers.find((x) => x[0] === field.name);

      if (!answer) continue;

      answer[1] = new Date(answer[1] as string);
    }

    for (const group of this.templateService.getGroups()) {
      const answer = answers.find((x) => x[0] === group.name);

      if (answer) continue;

      answers.push([group.name, group.display?.default ?? true]);
    }

    this.answers = new Map(answers);

    if (!shouldNavigateToFirstPage) return;

    this.firstPage();
  }

  getRowIds(group: Group): string[] {
    return this.getAnswer(EnterDetailsComponent.getRowsName(group)) ?? [];
  }

  private static getRowsName(group: Group): string | Field {
    return `${group.name}.rows`;
  }

  async setRowAnswer(value: AnswerType, rowId: string, field: Field) {
    await this.setAnswer(value, EnterDetailsComponent.getRowFieldName(rowId, field));
  }

  getRowAnswer(rowId: string, field: Field): AnswerType {
    return this.getAnswer(EnterDetailsComponent.getRowFieldName(rowId, field));
  }

  private static getRowFieldName(rowId: string, field: Field): string {
    return `${field.name}[${rowId}]`;
  }

  async addRow(group: Group) {
    const rows: string[] = this.getRowIds(group);

    rows.push(uuid());

    await this.setAnswer(rows, EnterDetailsComponent.getRowsName(group));
  }

  async removeRow(rowId: string, group: Group) {
    const rows = this.getRowIds(group).filter((x) => x !== rowId);

    await this.setAnswer(rows, EnterDetailsComponent.getRowsName(group));
  }

  getAnswer<T extends AnswerType>(field: Field | string, displayDefault?: boolean): T | null {
    if (displayDefault) return true as T;

    let fieldName: string;
    if (typeof field === 'string') {
      fieldName = field;
    } else {
      if (field.formula) {
        return this.templateService.evaluateExpression(this.answers, field.formula) as T;
      }

      fieldName = field.name;
    }

    const result = this.answers.get(fieldName);

    return result as T ?? null;
  }

  async setAnswer(value: AnswerType, field: Field | string, isDictionary = false) {
    let fieldName: string;

    let localValue = value

    if (typeof field === 'string') {
      fieldName = field;
    } else {
      fieldName = field.name;

      localValue = EnterDetailsComponent.parseFieldValue(localValue, field);
    }

    //Comparing two arrays for equality is a little involved and this is really just a slight performance optimization.
    if (!(localValue instanceof Array) && this.answers.get(fieldName) === localValue) return;

    this.answers.set(fieldName, localValue);

    //if (typeof field !== 'string' && field.validationErrors) {
    if (typeof field !== 'string' && field.validations) {
      field.validationErrors = [];

      for (const validation of field.validations) {
        if (this.templateService.evaluateExpression(this.answers, validation.expression)) continue;

        field.validationErrors.push(validation.message);
      }

      if (field.validationErrors.length) {
        return;
      }
    }

    let valueToSerialize: AnswerType | object;

    if (value instanceof Date || ( typeof field !== 'string' && field.dataType === 'date')) {
      //There is no way to reliably distinguish a serialized date from a string. Wrap dates in an object to allow the server to work it out.
      valueToSerialize = { dateValue: localValue };
    } else {
      valueToSerialize = localValue;
    }

    await firstValueFrom(
      this.httpClient.put(
        `api/factfinds/${this.factFindService.factFindId}/answers/${fieldName}/${isDictionary}`,
        JSON.stringify(valueToSerialize)
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this -- Angular templates require instance member.
  getDefault<T extends AnswerType>(field: Field): T {
    return field.default as T;
  }

  get areAnswersLoaded(): boolean {
    return Boolean(this.answers);
  }

  public answers: Map<string, AnswerType>;

  async loadHistory(element: Element) {
    this.history = (await firstValueFrom(
      this.httpClient.get(`api/factfinds/${this.factFindService.factFindId}/answers/${element.name}/history`)
    )) as string[];
    this.historyField = element;

    this.changeDetectorRef.detectChanges();
  }

  getMultipleChoice(choice: string, field: Field) {
    const choices = this.getAnswer(field);

    if (!(choices instanceof Array)) return false;

    return choices.includes(choice);
  }

  async setMultipleChoice(isSet: boolean, choice: string, field: Field) {
    let choices = this.getAnswer(field);

    if (!(choices instanceof Array)) choices = [];

    if (isSet && !choices.includes(choice)) {
      choices.push(choice);
    } else if (!isSet && choices.includes(choice)) {
      choices = choices.filter((x) => x !== choice);
    } else {
      return;
    }

    await this.setAnswer(choices, field);
  }

  getHistory(element: Element) {
    if (this.historyField !== element) return [];

    return this.history;
  }

  private historyField: Element;
  private history: string[];

  canUpdate(element: Element) {
    const access = this.templateService.getAccess(element);

    return access === 'Update';
  }

  get isFirstPage(): boolean {
    return this.templateService.getDisplayedPages(this.answers)[0] === this.selectedPage;
  }

  get isLastPage(): boolean {
    // eslint-disable-next-line no-magic-numbers
    return this.templateService.getDisplayedPages(this.answers).at(-1) === this.selectedPage;
  }

  isDisclosuresPage(fieldName: string): boolean {
    return fieldName === this.privacyPolicy || fieldName === this.electronicConsent;
  }

  previousPage() {
    const pages = this.templateService.getDisplayedPages(this.answers);

    const currentIndex = this.selectedPage ? pages.indexOf(this.selectedPage) : null;

    //0 is falsy so it will result in newIndex being 0.
    // eslint-disable-next-line no-magic-numbers
    const newIndex = currentIndex ? currentIndex - 1 : 0;

    this.selectedPage = pages[newIndex];

    EnterDetailsComponent.scrollToTop();
  }

  async nextPage() {
    const pages = this.templateService.getDisplayedPages(this.answers);

    const currentIndex = this.selectedPage ? pages.indexOf(this.selectedPage) : null;

    // eslint-disable-next-line no-magic-numbers
    const newIndex = currentIndex === null ? 0 : Math.min(pages.length - 1, currentIndex + 1);

    this.selectedPage = pages[newIndex];

    await this.saveAccessData();

    EnterDetailsComponent.scrollToTop();
  }

  async goToLastPage() {
    const pages = this.templateService.getDisplayedPages(this.answers);
    // eslint-disable-next-line no-magic-numbers
    this.selectedPage = pages[pages.length - 1];

    await this.saveAccessData();

    this.changeDetectorRef.detectChanges();
    EnterDetailsComponent.scrollToTop();
  }

  get canPublish() {
    if (!this.factFind) throw Error('No Fact Find.');

    return (
      this.isLastPage &&
      this.factFind.statusId !== FactFindStatuses.Published &&
      this.authenticatedUserService.isAdviser
    );
  }

  async publish() {
    if (!this.factFind) throw Error('There is no fact find available.');
    if (!this.selectedAdviser) throw Error('Invalid Adviser');

    this.templateService.makeReadonlyForRoleAndStatus(this.factFind);

    //#74114: Exposing final responses when publishing for automated regression testing
    await firstValueFrom(this.httpClient.get(`/api/factfinds/${this.factFind.id}/answers`));

    await firstValueFrom(this.httpClient.post(`api/factfinds/${this.factFindService.factFindId}/publish`, JSON.stringify(this.selectedAdviser)));
 
    this.factFind.statusId = FactFindStatuses.Published;

    this.changeDetectorRef.detectChanges();
  }

  get canSubmit() {
    return (
      this.isLastPage &&
      this.factFind?.statusId !== FactFindStatuses.Submitted &&
      this.authenticatedUserService.isClient
    );
  }

  async submit() {
    if (!this.factFind) throw Error('There is no fact find available.');

    this.templateService.makeReadonlyForRoleAndStatus(this.factFind);

    await firstValueFrom(this.httpClient.post(`api/factfinds/${this.factFindService.factFindId}/submit`, null));

    this.factFind.statusId = FactFindStatuses.Submitted;

    this.changeDetectorRef.detectChanges();

    await this.authenticatedUserService.logOut();
    await this.router.navigate(['thank-you']);
  }

  private static scrollToTop() {
    // eslint-disable-next-line no-magic-numbers
    window.scrollTo(0, 0);
  }

  firstPage() {
    this.selectedPage = this.templateService.getDisplayedPages(this.answers)[0];
  }

  showAllPages() {
    this.selectedPage = null;
    this.changeDetectorRef.detectChanges();
  }

  private static parseFieldValue(value: AnswerType, field: Field) {
    switch (field.dataType) {
      case 'number':
        return parseFloat(value as string);
      default:
        return value;
    }
  }

  getPrefix(field: Field): string {
    if (field.dataType !== 'number') return '';

    if (field.type === 'currency') return '$';

    if (field.type === 'currencyOrPercentage' && !this.isPercentage(field)) return '$';

    return '';
  }

  getSuffix(field: Field): string {
    if (field.dataType !== 'number') return '';

    if (field.type === 'percentage') return '%';

    if (field.type === 'currencyOrPercentage' && this.isPercentage(field)) return '%';

    return '';
  }

  private isPercentage(field: Field): boolean {
    if (field.dataType !== 'number' || field.type !== 'currencyOrPercentage') { throw Error('Only a currencyOrPercentage number field can be a percentage or currency.'); }

    return this.getAnswer(`${field.name}IsPercentage`) ?? false;
  }

  shouldShowAdviserToggle(): boolean {
    // show adviser toggle if there is no assigned advisernode
    return this.getAnswer("assignedAdviserNode") !== null;
  }

  isNavigationVisible = false;

  async editNote(group: Group) {
    // eslint-disable-next-line no-undefined, no-alert -- undefined is required if there is no value.
    const newValue = await this.mwDialogService.prompt('Leave a note for later. Only visible to you.', this.noteService.get(group) ?? undefined)

    //This happens if the user cancels out of the prompt.
    if (newValue === null) return;

    await this.noteService.set(newValue || null, group);
  }

  // eslint-disable-next-line class-methods-use-this -- Angular tempolates require instance method.
  setValidator(): string {
    return "";
  }

  toggleAdviser(): void {
    this.canUpdateAdviser= !this.canUpdateAdviser;
  }

  finalPage(fieldName: string): boolean {
    return this.authenticatedUserService.isAdviser && this.canPublish && this.isLastPage && !this.isDisclosuresPage(fieldName)
  }

  getCurrentAdviser(): string {
    const adviser = this.advisers.find(x => x.node === this.getAnswer("assignedAdviserNode"));
    this.selectedAdviser = adviser?.node ?? null;
    return `${adviser?.firstName} ${adviser?.lastName}`;
  }

  advisers: AdviserInfo[] = [];
  selectedAdviser: string | null = null;
  canUpdateAdviser = false;

  async getAdvisers() {
    this.advisers = await firstValueFrom(
      this.httpClient.get<AdviserInfo[]>('api/advisers/getadvisers', { withCredentials: true })
    );
  }

  async pageOnChange(page: Page) {
    this.selectedPage = page;
    await this.saveAccessData();
  }

  async saveAccessData() {
    const dataRequest: DataRequest = {
      factFindId: this.factFindService.factFindId,
      pageId: this.selectedPage?.name
    };

    await firstValueFrom(this.httpClient.put(`api/reports/factfind-access-data`, JSON.stringify(dataRequest)));
  }
}

interface DataRequest {
  factFindId?: number | null;
  pageId?: string | null;
}

