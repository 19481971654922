/* eslint-disable @angular-eslint/no-async-lifecycle-method */
/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OwnerTypes } from "../enums/dbo.OwnerType";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'manage-owner',
  templateUrl: './manage-owner.component.html',
  styleUrl: './manage-owner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageOwnerComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  owners: Owner[];

  async ngOnInit(): Promise<void> {
    this.owners = (await firstValueFrom(this.httpClient.get('api/hierarchyOwners'))) as Owner[];
    this.groups = this.owners.filter(x => x.typeId === OwnerTypes.Group as number)
    this.cdr.detectChanges();
  }
  OwnerTypes = OwnerTypes;

  selectedGroupId: number | null = null;
  selectedLicenseeId: number | null = null;
  selectedPracticeId: number | null = null;
  selectedOfficeId: number | null = null;

  groups: Owner[] = [];
  licensees: Owner[] = [];
  practices: Owner[] = [];
  offices: Owner[] = [];

  onGroupChange(id: number) {
    this.selectedGroupId = id;

    this.selectedLicenseeId = null;
    this.selectedPracticeId = null;
    this.selectedOfficeId = null;
    this.updateOwnerGroups();
  }

  onLicenseeChange(id: number) {
    this.selectedLicenseeId = id;

    this.selectedPracticeId = null;
    this.selectedOfficeId = null;
    this.updateOwnerGroups();
  }

  onPracticeChange(id: number) {
    this.selectedPracticeId = id;

    this.selectedOfficeId = null;
    this.updateOwnerGroups();
  }

  onOfficeChange(id: number) {
    this.selectedOfficeId = id;
  }

  async onOwnerCreation(owner: Owner) {
    const ownerId = await firstValueFrom(this.httpClient.put<number>('api/addOwner', JSON.stringify(owner)));
    owner.id = ownerId;
    this.owners.push(owner);
    this.groups = this.owners.filter(x => x.typeId === OwnerTypes.Group as number)
    this.setSelectedOwner(owner.id, owner.typeId)
    this.updateOwnerGroups();
  }

  async updateOwner(owner: Owner) {
    await firstValueFrom(this.httpClient.post('api/updateOwner', JSON.stringify(owner)));
  }

  private setSelectedOwner(ownerId: number, typeId: number) {
    switch (typeId) {
      case OwnerTypes.Group as number:
        this.selectedGroupId = ownerId;
        this.selectedLicenseeId = null;
        this.selectedPracticeId = null;
        this.selectedOfficeId = null;
        break;
      case OwnerTypes.Licensee as number:
        this.selectedLicenseeId = ownerId;
        this.selectedPracticeId = null;
        this.selectedOfficeId = null;
        break;
      case OwnerTypes.Practice as number:
        this.selectedPracticeId = ownerId;
        this.selectedOfficeId = null;
        break;
      case OwnerTypes.Office as number:
        this.selectedOfficeId = ownerId;
        break;
      default:
        throw new Error(`Unknown owner type: ${typeId}`);
    }
  }

  private updateOwnerGroups() {
    this.licensees = this.owners.filter(x =>
      x.parentId === Number(this.selectedGroupId) && x.typeId === OwnerTypes.Licensee as number
    );

    this.practices = this.owners.filter(x =>
      x.parentId === Number(this.selectedLicenseeId) && x.typeId === OwnerTypes.Practice as number
    );

    this.offices = this.owners.filter(x =>
      x.parentId === Number(this.selectedPracticeId) && x.typeId === OwnerTypes.Office as number
    );
    this.cdr.detectChanges();
  }
}

export interface Owner {
  id: number | null;
  name: string;
  parentId: number;
  typeId: number;
  externalReference: string | null;
  hasExternalSystem: boolean;
}
